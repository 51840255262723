import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createBadge = createAsyncThunk(
  "badge/createBadge",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.BadgeService.createBadge( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveBadges = createAsyncThunk(
  "badge/retrieveBadges",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.BadgeService.retrieveBadges(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveBadge = createAsyncThunk(
  "badge/retrieveBadge",
  async (badgeId, { rejectWithValue }) => {
    try {
      const response = await services.BadgeService.retrieveBadge(badgeId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBadge = createAsyncThunk(
  "badge/deleteBadge",
  async (badgeId, { rejectWithValue }) => {
    try {
      const response = await services.BadgeService.deleteBadge(badgeId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateBadge = createAsyncThunk(
  "badge/updateBadge",
  async (badgeId,payload,{ rejectWithValue }) => {
    try {
      const response = await services.BadgeService.updateBadge(badgeId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state:"",
  error: {},
};

const BadgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    ACTION(state,action) {
    },
  },
  extraReducers: {
    /* ---- CREATE_BADGE ---- */
    [String(createBadge.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createBadge.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createBadge.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_BADGES ---- */
    [String(retrieveBadges.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveBadges.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveBadges.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_BADGE ---- */
    [String(retrieveBadge.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveBadge.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveBadge.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_BADGE ---- */
    [String(updateBadge.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateBadge.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateBadge.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_BADGE---- */
    [String(deleteBadge.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteBadge.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteBadge.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION} = BadgeSlice.actions;

export default BadgeSlice.reducer;
