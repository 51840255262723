import swaggerProps from "../plugins/swagger-client";

export default class TransactionService {
  createTransaction(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Transaction.createTransaction(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveTransactions(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Transaction.retrieveTransactions(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveTransaction(transactionId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Transaction.retrieveTransaction(
          { transactionId: transactionId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteTransaction(transactionId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Transaction.deleteTransaction(
          { transactionId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateTransaction(transactionId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Transaction.updateTransaction(
          { transactionId: transactionId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
