import { useState, useEffect, useCallback } from "react";
import "./Tiles.scss";
import { ReactComponent as LikeSvg } from "../../assets/like.svg";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { retrievePhoto, SET_SPECIFIC_PHOTO } from "../../store/slices/PhotoSlice";
import like from "../../assets/like1.png"
import { getPhotoSrc } from "../../helpers/photo";

const styles={
  like: {
    position: "absolute",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 12,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
  },
}


const Tile = (props) => {
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  const [imageTile, setImageTile] = useState({ likes: [] });

  const { data } = props

  
  const fetchPhotos = useCallback((data) => {
    dispatch(retrievePhoto(data.photoId._id))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data)
        const photo = originalPromiseResult.body.data;
        dispatch(SET_SPECIFIC_PHOTO(photo));
        setImageTile(photo)
        setImage(getPhotoSrc(photo));
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  },[dispatch]);

  useEffect(() => {
    fetchPhotos(data);
  }, [fetchPhotos, data]);

  const [state, setState] = useState({
    open: false,
    mouseOver: false,
  });
  // Event handlers to modify state values
  const _mouseEnter = (e) => {
    e.preventDefault();
    if (state.mouseOver === false) {
      setState({
        ...state,
        mouseOver: true,
      });
    }
  };
  const _mouseLeave = (e) => {
    e.preventDefault();
    if (state.mouseOver === true) {
      setState({
        ...state,
        mouseOver: false,
      });
    }
  };
  const _clickHandler = (e) => {
    e.preventDefault();
    if (state.open === false) {
      setState({
        ...state,
        open: true,
      });

    } else {
      setState({
        ...state,
        open: false,
      });
    }
  };
  return (
    <div className='tile mb-4'>
      {state.open ? <Redirect push to={'/lightbox?photoId=' + imageTile._id} /> : <></>}
      <img
        onMouseEnter={_mouseEnter.bind(this)}
        onMouseLeave={_mouseLeave.bind(this)}
        onClick={_clickHandler.bind(this)}
        src={image}
        alt={data.title}
      />
       <div
          style={{
            ...styles.like,
            zIndex: 1,
          }}
          className="py-1 px-2 mb-3"
        >
          <img className="ms-0 mr-1 mx-2" src={like} />
          <div>{imageTile.likes.length}</div>
        </div>
    </div>
  );
};

export default Tile;
