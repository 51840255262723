import { CHANGE_ACTIVE_MAIN_TAB, CHANGE_ACTIVE_PROFILE_TAB } from '../actions/tabs'

const initialState = {
    profileTabName: "Galleries",
    mainTabName: "home"
}

const tabReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_ACTIVE_PROFILE_TAB:
            return {
                ...state,
                profileTabName: action.tabName
            }
        case CHANGE_ACTIVE_MAIN_TAB:
            return {
                ...state,
                mainTabName: action.tabName
            }
        default:
            break;
    }
    return state;
}
export default tabReducer;