import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createPhoto = createAsyncThunk(
  "photo/createPhoto",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.PhotoService.createPhoto( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrievePhotos = createAsyncThunk(
  "photo/retrievePhotos",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.PhotoService.retrievePhotos(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrievePhoto = createAsyncThunk(
  "photo/retrievePhoto",
  async (photoId, { rejectWithValue }) => {
    try {
      const response = await services.PhotoService.retrievePhoto(photoId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePhoto = createAsyncThunk(
  "photo/deletePhoto",
  async (photoId, { rejectWithValue }) => {
    try {
      const response = await services.PhotoService.deletePhoto(photoId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updatePhoto = createAsyncThunk(
  "photo/updatePhoto",
  async ({photoId,info},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.updatePhoto(photoId,info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createPhotoReport = createAsyncThunk(
  "photo/createPhotoReport",
  async ({photoId,info},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.createPhotoReport(photoId,info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePhotoReport = createAsyncThunk(
  "photo/deletePhotoReport",
  async ({photoId,reportId},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.deletePhotoReport(photoId,reportId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPhotoCommentsLikes = createAsyncThunk(
  "photo/createPhotoCommentsLikes",
  async ({photoId,payload},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.createPhotoCommentsLikes(photoId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePhotoCommentsLikes = createAsyncThunk(
  "photo/deletePhotoCommentsLikes",
  async ({photoId,commentsId,likesId},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.deletePhotoCommentsLikes(photoId,commentsId,likesId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPhotoComment = createAsyncThunk(
  "photos/createPhotoComment",
  async ({photoId,payload},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.createPhotoComment(photoId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updatePhotoComments = createAsyncThunk(
  "photo/updatePhotoComments",
  async ({photoId,commentsId,payload},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.updatePhotoComments(photoId,commentsId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePhotoComments = createAsyncThunk(
  "photo/deletePhotoComments",
  async ({photoId,commentsId},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.deletePhotoComments(photoId,commentsId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createPhotoLike = createAsyncThunk(
  "photo/createPhotoLike",
  async ({photoId,payload},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.createPhotoLike(photoId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePhotoLike = createAsyncThunk(
  "photo/deletePhotoLike",
  async ({photoId,likeId},{ rejectWithValue }) => {
    try {
      const response = await services.PhotoService.deletePhotoLike(photoId,likeId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


let initialState = {
  state: "",
  uploadedPhotos:[],
  specificPhoto:{},
  error: {},
};

const PhotoSlice = createSlice({
  name: "photo",
  initialState,
  reducers: {
    ACTION(state,action) {},
    SET_SPECIFIC_PHOTO(state,action){
      state.specificPhoto=action.payload;
    }
  },
  extraReducers: {
    /* ---- CREATE_PHOTO ---- */
    [String(createPhoto.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createPhoto.fulfilled)]: (state, action) => {
      state.uploadedPhotos.push(action.payload.body.data);
      state.state = "success";
    },
    [String(createPhoto.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_PHOTOS ---- */
    [String(retrievePhotos.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrievePhotos.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrievePhotos.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_PHOTO ---- */
    [String(retrievePhoto.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrievePhoto.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrievePhoto.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_PHOTT ---- */
    [String(updatePhoto.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updatePhoto.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updatePhoto.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_PHOTO---- */
    [String(deletePhoto.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deletePhoto.fulfilled)]: (state, action) => {
       const id =action.payload.url.substring(action.payload.url.indexOf("photos/")+7);
        state.uploadedPhotos = state.uploadedPhotos.filter(function(item) {
          return item._id !== id
       })
        state.state = "success";
      },
      [String(deletePhoto.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION,SET_SPECIFIC_PHOTO} = PhotoSlice.actions;

export default PhotoSlice.reducer;
