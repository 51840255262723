import swaggerProps from "../plugins/swagger-client";

export default class GalleryService {

  createGallery(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.createGallery(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveGalleries(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.retrieveGalleries(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveGallery(galleryId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.retrieveGallery(
           galleryId ,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteGallery(galleryId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.deleteGallery(
          { galleryId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateGallery(galleryId,info) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.updateGallery(
          {galleryId:galleryId},
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  
  createGalleryPhotos(galleryId,payload) {
    console.log(payload)
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.createGalleryPhotos(
          {galleryId:galleryId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteGalleryPhotos(galleryId,photosId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Gallery.deleteGalleryPhotos(
          {galleryId:galleryId,photosId:photosId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
