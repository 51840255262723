

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import profilePic from "./assets/ExamplePosts/profile.jpg"
import ForgotPassword from './pages/MobilePages/ForgotPassword';
import ResetPassword from './pages/MobilePages/ResetPassword';
import ChatPage from './pages/MobilePages/ChatPage';
import PersonalPage from './pages/PersonalPage/PersonalPage';
import LevelPage from './pages/LevelPages/LevelPage';
import PhoneVerification from './pages/LevelPages/PhoneVerification';
import AppMobile from './AppMobile';
import NewPhotoPage from './pages/NewPhotoPage/NewPhotoPage';
import NewGalleryPage from './pages/NewGalleryPage/NewGalleryPage';
import JobEditorPage from './pages/JobEditorPage/JobEditorPage';
import JobOverviewPage from './pages/JobOverviewPage/JobOverviewPage';
import MyGalleryPage from './pages/MobilePages/MyGalleryPage';
import LightBoxMobile from './pages/MobilePages/LightBoxMobile';
import RegisterPage from './pages/MobilePages/RegisterPage';
import LoginPage from './pages/MobilePages/LoginPage';
import NotificationPage from './pages/MobilePages/NotificationPage';
import JobPage from './pages/MobilePages/JobPage';


const person = {
  name: "Lorenzo Piccinato",
  image: profilePic,
  job: "Photographer",
  location: "Milano (Italy)",
  followers: 300,
  likes: 455,
  comments: 60
}

function App() {

  return (
    <Router>
      <Switch>
        <Route path="/jobOverview" exact component={() => <JobOverviewPage person={person} buttonLabel="Apply for this job" />} />
        <Route path="/register" exact component={RegisterPage} />
        <Route path="/login" exact component={LoginPage} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/reset-password" exact component={ResetPassword} />
        <Route path="/chat" exact component={ChatPage} />
        <Route path="/levels" exact component={LevelPage} />
        <Route path="/phone-verify" exact component={PhoneVerification} />
        <Route path="/notifications" exact component={NotificationPage} />
        <Route path="/lightbox" exact component={() => <LightBoxMobile person={person} />} />
        <Route path="/gallery/:id" exact component={() => <MyGalleryPage person={person} />} />
        {/* <Route path="/jobs" exact component={() => <ProjectSearchPage person={person} />} /> */}
        <Route path="/jobs/:id" exact component={() => <JobPage person={person} buttonLabel={"Edit"} />} />
        <Route path="/jobs/:id/edit" exact component={() => <JobEditorPage person={person} />} />
        <Route path="/newGallery" exact component={() => <NewGalleryPage person={person} />} />
        <Route path="/newGallery/newPhoto" exact component={() => <NewPhotoPage person={person} />} />
        <Route path="/newPhoto" exact component={() => <NewPhotoPage person={person} />} />
        <Route path="/users/:id" exact component={() => <PersonalPage person={person} />} />
        <Route path="/" exact component={() => <AppMobile add={true} person={person} />} />
      </Switch>
    </Router>
  );
}

export default App;
