import { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../../constants/Colors";
import { switchProfileTab } from "../../../store/actions/tabs";
import PersonInfo from "./PersonInfo";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
//import DefaultProfile from '../../../assets/DefaultProfile.png'
import DefaultProfile from "../../../assets/user.svg";
import BackGroundShapes from "../Login-Register/BackGround";
import { useTranslation } from "react-i18next";

const styles = {
  container: {
    position: "relative",
    height: 230,
  },
  backImage: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: -1
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  largeContainer:{
      position:"relative",
      top:"2.5rem"
  },
  tab: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    border: "none",
    boxShadow: "none",
  },
  tabContainer: {
    display: "flex",
    background: "#E8E9F3",
    borderRadius: "100px",
    width:'70%',
    margin:'auto'
    
  },
};

const Tabs = (props) => {
  let activeTab = useSelector((state) => state.tabState.profileTabName);
  const [photosTab, setPhotosTab] = useState(
    activeTab === "Photos" ? true : false
  );
  const [galleriesTab, setGalleriesTab] = useState(
    activeTab === "Galleries" ? true : false
  );
  const [bioTab, setBioTab] = useState(activeTab === "Bio" ? true : false);
  const [jobsTab, setJobsTab] = useState(
    activeTab === "Jobs and Projects" ? true : false
  );
  const { t }=useTranslation();

  const dispatch = useDispatch();

  const _tabHandler = (event) => {
    console.log(event)
    dispatch(switchProfileTab(event.target.id));

    switch (event.target.id) {
      case "Photos":
        setPhotosTab(true);
        setGalleriesTab(false);
        setBioTab(false);
        setJobsTab(false);
        break;
      case "Galleries":
        setPhotosTab(false);
        setGalleriesTab(true);
        setBioTab(false);
        setJobsTab(false);
        break;
      case "Bio":
        setPhotosTab(false);
        setGalleriesTab(false);
        setBioTab(true);
        setJobsTab(false);
        break;
      case "Jobs and Projects":
        setPhotosTab(false);
        setGalleriesTab(false);
        setBioTab(false);
        setJobsTab(true);
        break;

      default:
        break;
    }
  };


  return (
    <div className="p-0 mt-4 d-flex justify-content-around" style={{...styles.tabContainer,marginTop: props.largeStyles ? 20:5,maxWidth:"300px"}}>
      <div
        id="Photos"
        className="m-2 me-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: photosTab ? "#6F79D8" : "black",
          borderBottom: photosTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
        {t("Profile.TabBar.photos.title")}
      </div>
      <div
        id="Galleries"
        className="m-2 ms-0 me-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: galleriesTab ? "#6F79D8" : "black",
          borderBottom: galleriesTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
       {t("Profile.TabBar.galleries.title")}
      </div>
      <div
        id="Bio"
        className="m-2 ms-0 me-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: bioTab ? "#6F79D8" : "black",
          borderBottom: bioTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
       {t("Profile.TabBar.bio.title")}
      </div>
      <div
        id="Jobs and Projects"
        className="m-2 ms-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: jobsTab ? "#6F79D8" : "black",
          borderBottom: jobsTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
      {t("Profile.TabBar.jobs.title")}
      </div>
    </div>
  );
};

const ProfileMobile = ({ person, profileImage, comesFrom }) => {
  const history = useHistory();
  const largeStyle ={
      text:"black",
      name:"24px",
      role:"15px",
      info:"12px",
      buttons:"12px"
  }
  return (
    <>
      <div
        className="p-0 pt-4 mt-5 d-block d-sm-block d-md-block d-lg-none"
        style={styles.container}
      >
        {/* <img src={background} style={styles.backImage} alt="background" /> */}
        <BackGroundShapes style={styles.backImage} hideShapes />
       
        <div className="m-0" style={styles.infoContainer}>
          <PersonInfo
            comesFrom={comesFrom}
            person={person}
            textAlign="center"
            profileImage={profileImage}
          />
        </div>
       
        <Tabs />
      </div>
      <div
        className="p-0 m-0 d-none d-sm-none d-md-none d-lg-block"
        style={styles.container}
      >
        <BackGroundShapes style={styles.backImage} hideShapes />
        <div className="d-flex ms-5 pt-3 ps-5">
          <div style={styles.infoContainer} className="pt-3">
            <div style={{ position: "relative" }}>
              <img
                src={profileImage || DefaultProfile}
                width={225}
                height={225}
                className="circle__profile rounded-circle border border-3 border-white "
                alt="Profile"
              />
              {person.status === "active" ? (
                <div
                  style={{
                    height: 32,
                    width: 32,
                    top: 12,
                    right: 20,
                    position: "absolute",
                    backgroundColor: "black",
                  }}
                  className="rounded-circle border border-2 border-white "
                ></div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="d-block ms-3" style={styles.largeContainer}>
            <PersonInfo
              comesFrom={comesFrom}
              person={person}
              textAlign="left"
              largeStyles={largeStyle}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                fontSize: 15,
              }}
              className="mt-2 ms-2 mb-2"
            >
              Verification:&nbsp;
              <span
                style={{ color: Colors.primary, fontWeight: "bold" }}
                onClick={() => history.push("/levels")}
              >
                None <AiOutlineInfoCircle />
              </span>
            </div>
            <Tabs largeStyles="12"/>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileMobile;
