import { IoPersonOutline, IoPerson, IoBriefcaseOutline, IoBriefcase, IoHomeOutline, IoHome, IoSend, IoSendOutline } from 'react-icons/io5'
import { useCallback, useEffect, useState } from 'react'
import { RiSearchFill, RiSearchLine } from 'react-icons/ri'
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { switchMainTab } from '../../store/actions/tabs';
import { Redirect } from 'react-router-dom';
import Colors from '../../constants/Colors';
import { retrieveChats } from '../../store/slices/ChatSlice';
import logo from "../../assets/LogoFooter.png";
import jobs from "../../assets/jobs.png";
import messages from "../../assets/messages.png";
import userImage from "../../assets/user.png";
import search from "../../assets/Search.png";
import jobsBlue from "../../assets/jobsBlue.png";
import messagesBlue from "../../assets/messagesBlue.png";
import userBlue from "../../assets/userBlue.png";
import searchBlue from "../../assets/searchBlue.png";
import defaultUser from "../../assets/user.svg";

/* const styles = {
    bar: {
        display: 'flex',
        height: 50,
        position: 'sticky',
        bottom: 0,
        width: '100%',
        zIndex: 100,
        backgroundColor: 'white',
        justifyContent: 'space-around',
        alignItems: 'center',
        borderTop: '2px solid rgba(0, 0, 0, 0.1)'
    },
    tab: {
        width: 25,
        height: 25,
        color: Colors.primary 
    },
    button: {
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'transparent',
        outline: 'none',
        boxShadow: 'none',
        border: 'none'
    }

} */
const TabBar = ({ props = "sticky" }) => {

    const styles = {
        bar: {
            display: 'flex',
            height: 50,
            width: '100%',
            backgroundColor: 'white',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
        constainer: {
            display: 'flex',
            position: props,
            bottom: 0,
            minHeight:"75px",
            width: '100%',
            zIndex: 50,
            backgroundColor: 'white',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderTop: '2px solid rgba(0, 0, 0, 0.1)',
            flexDirection: 'column'
        },
        tab: {
            width: 25,
            height: 25,
            color: Colors.primary
        },
        button: {
            display: 'flex',
            width: '100%',
            textAlign: 'center',
            backgroundColor: 'transparent',
            outline: 'none',
            boxShadow: 'none',
            border:"none",
            justifyContent: 'center'
        },
        bottomBar: {
            width: '40%',
            height: 5,
            backgroundColor: 'black',
            borderRadius: 100
        },
        icons:{
            borderBottom: '2px solid #6F79D8',
            borderRadius:"10px",
            marginTop:"10px",
        }

    }

    let activeTab = useSelector(state => state.tabState.mainTabName)
    let user = useSelector(state => state.user.user)
    const [searchTab, setSearchTab] = useState(activeTab === "search" ? true : false);
    const [jobsTab, setJobsTab] = useState(activeTab === "jobs" ? true : false);
    const [homeTab, setHomeTab] = useState(activeTab === "home" ? true : false);
    const [sendTab, setSendTab] = useState(activeTab === "send" ? true : false);
    const [profileTab, setProfileTab] = useState(activeTab === "profile" ? true : false);
    const [clicked, setClicked] = useState(false);
    const [unreads, setUnreads] = useState(0);

    const dispatch = useDispatch();
    const userId = useSelector(state => state.user.userId)
    const fetchChats = useCallback(
        () => {
            dispatch(retrieveChats())
                .unwrap()
                .then((originalPromiseResult) => {
                    setUnreads(originalPromiseResult.body.data
                        .filter(chat => {
                            for (const index in chat.people) {
                                if (chat.people[index].userId === userId) return true;
                            }
                            return false;
                        })
                        .filter(chat => {
                            const lastMessage = chat.messages[chat.messages.length - 1];
                            return lastMessage && !lastMessage.read && lastMessage.ownerId._id !== userId
                        })
                        .length
                    );
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                })
        },
        [dispatch, userId]
    );
    useEffect(() => {
        if (userId) {
            fetchChats()
        }
    }, [fetchChats,userId])
    const _tabHandler = (event) => {
        console.log(event)
        if (!event.target.id) return;

        dispatch(switchMainTab(event.target.id))
        switch (event.target.id) {
            case "search":
                setSearchTab(true);
                setJobsTab(false);
                setHomeTab(false);
                setSendTab(false);
                setProfileTab(false);
                break;
            case "jobs":
                setSearchTab(false);
                setJobsTab(true);
                setHomeTab(false);
                setSendTab(false);
                setProfileTab(false);
                break;
            case "home":
                setSearchTab(false);
                setJobsTab(false);
                setHomeTab(true);
                setSendTab(false);
                setProfileTab(false);
                break;
            case "send":
                setSearchTab(false);
                setJobsTab(false);
                setHomeTab(false);
                setSendTab(true);
                setProfileTab(false);
                break;
            case "profile":
                setSearchTab(false);
                setJobsTab(false);
                setHomeTab(false);
                setSendTab(false);
                setProfileTab(true);
                break;

            default:
                break;
        }

        setClicked(true);
    }

    return (
        <div style={styles.constainer} className="px-3 mt-3 d-flex d-sm-flex d-md-flex d-lg-none">
            {clicked ? <Redirect to="/" /> : <></>}
            <div style={styles.bar}  >

                <Button style={styles.button} className="p-2" id="search" onClick={_tabHandler}>
                    {
                        searchTab ?
                            <div id="search" onClick={_tabHandler}> <img style={{ marginTop:"10px",}} src={searchBlue} id="search" alt="search"/> <div style={styles.icons}></div></div>
                            :
                            <div id="search" onClick={_tabHandler}> <img src={search} id="search" alt="search"/> </div>

                    }

                </Button>
                <Button style={styles.button} className="p-2" id="jobs" onClick={_tabHandler}>
                    {
                        jobsTab ?
                            <div id="jobs" onClick={_tabHandler}> <img style={{ marginTop:"10px",}} src={jobsBlue} id="jobs" alt="jobs"/> <div style={styles.icons}></div></div>
                            :
                            <div id="jobs" onClick={_tabHandler}> <img src={jobs} id="jobs" alt="jobs"/> </div>


                    }
                </Button>
                <Button style={styles.button} className="p-2" id="home" onClick={_tabHandler}>
                    {
                        homeTab ?
                            <div id="home" onClick={_tabHandler}> <img style={{ marginTop:"10px",}} src={logo} id="home" alt="home"/> <div style={styles.icons}></div></div>
                            :
                            <div id="home" onClick={_tabHandler} > <img src={logo} id="home" alt="home" /> </div>

                    }
                </Button>
                <Button style={styles.button} className="p-2" id="send" onClick={_tabHandler}>
                    <div style={{ position: 'relative', display: 'flex', flexShrink: 1, width: 25 }}>
                        {unreads > 0 && <div style={{ height: 20, width: 20, color: 'white', backgroundColor: 'red', position: 'absolute', top: -5, left: -10, fontSize: 12 }} className="rounded-circle border border-2 border-white" id="send" onClick={_tabHandler}>{unreads}</div>}
                        {

                            sendTab ?
                                <div id="send" onClick={_tabHandler} > <img style={{ marginTop:"10px",}} src={messagesBlue} id="send" alt="send" /> <div style={styles.icons}></div></div>
                                :
                                <div id="send" onClick={_tabHandler} > <img src={messages} id="send" alt="send" /> </div>

                        }
                    </div>
                </Button>
                <Button style={styles.button} className="p-2" id="profile" onClick={_tabHandler}>
                    {
                        !profileTab ? <div id="profile" onClick={_tabHandler} > <img className="mb-1" src={userImage} id="profile" alt="profile" /> </div> :
                        <div className="me-2 mb-1" style={{border:'none',borderRadius:"50%",width:34,height:34}} id="profile" onClick={_tabHandler} > <img src={defaultUser} id="profile" alt="profile" /> <div style={styles.icons}></div></div>

                    }
                </Button>
            </div>
        </div>
    )
};

export default TabBar;