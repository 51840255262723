const getDaysDifference = (date1, date2) => {
    return date2.getDate() - date1.getDate()
}
const getHoursDifference = (date1, date2) => {
    return Math.abs(date2 - date1) / 36e5
}

export const relativeTimeTranform = date => {
    const now = new Date()
    const diff = getDaysDifference(date, now);
    if (diff > 7) {
        const dayName = date.toLocaleString('default', { weekday: 'long' })
        const monthName = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate()
        return `${dayName.substring(0,3)} ${monthName.substring(0,3)} ${day}`
    } else if (diff > 1) {
        return `${diff} days ago`
    } else if (diff === 1) return "Yesterday"
    else return "Today"
}