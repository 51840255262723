import swaggerProps from "../plugins/swagger-client";

export default class BadgeService {
  createBadge(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.createBadge(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveBadges(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.retrieveBadges(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveBadge(badgeId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.retrieveBadge(
          { badgeId: badgeId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteBadge(badgeId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.deleteBadge(
          { badgeId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateBadge(badgeId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.updateBadge(
          { badgeId: badgeId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
