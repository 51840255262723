import { useHistory } from "react-router-dom";
import TitleBar from "../../components/Mobile/TitleBar";
import { Button } from "react-bootstrap";
import Colors from "../../constants/Colors";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import NotificationCard from "../../components/Mobile/Notifications/NotificationCard";
import { useEffect, useState } from "react";
import {
  retrieveNotifications,
  updateNotification,
} from "../../store/slices/NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import location from "../../assets/Map pin IC.png";
import TextField from "../../components/Mobile/TextField/TextField";
import TabBar from "../../components/Mobile/TabBar";
import moment from "moment";
import { createChat, retrieveChats } from "../../store/slices/ChatSlice";
import { useTranslation } from "react-i18next";

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    maxWidth: 500,
    alignSelf: "center",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 24,
  },
  text: {
    fontSize: 15,
    fontWeight: 400,
  },
};

let bioFields = [
  {
    info: "Age",
    value: "25-50",
  },
  {
    info: "Gender",
    value: "Female",
  },
  {
    info: "Remuneration",
    value: "TFP work",
  },
  {
    info: "Deadline",
    value: "26/01/2022",
  },
];

const JobPage = () => {
  const [data, sestData] = useState([]);

  const dispatch = useDispatch();
  const specificJob = useSelector((state) => state.job.specificJob);
  console.log(specificJob);

  const applyJob = () => {
    const data = {
      title: specificJob.title,
      description: specificJob.description,
      messages: [],
      people: [
        {
          userId: specificJob.ownerId,
        },
      ],
      status: "active",
    };

    dispatch(retrieveChats())
      .unwrap()
      .then((res) => {
        console.log(res.body.data);
        const data = res.body.data.filter((chat) => {
          return chat.people[1].userId === specificJob.ownerId;
        });
        console.log(data)
        if (data.length === 0) {
          dispatch(createChat(data))
            .unwrap()
            .then((res) => {
              console.log(res.body.data);
            })
            .catch((rejectedValueOrSerializedError) => {
              console.log(rejectedValueOrSerializedError);
            });
        }
        history.push("/chat?userId=" + specificJob.ownerId);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  moment().format();

  const history = useHistory();
  const { t } =useTranslation();
  return (
    <div
      className="m-0 p-0"
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
      }}
    >
      <TitleBar comesFrom="specific" title="jobs" />
      <div
        style={{
          backgroundColor: "white",
          width: "90%",
          maxWidth: "700px",
          margin: "auto",
        }}
        className="searchCard mb-4 mt-4"
      >
        <div className="ms-2 d-flex justify-content-between mb-2">
          <div className="d-flex align-items-center">
            <div>
              <img
                width={45}
                height={45}
                className="rounded-circle circle__profile border border-3 border-white"
                src={specificJob.userImg}
                alt="user"
              />
            </div>
            <div style={{ lineHeight: 1 }} className="ms-2">
              <h6 style={{ fontSize: 14 }}>
                {specificJob.firstName} {specificJob.lastName}
              </h6>
            </div>
          </div>
          <div className="me-3 mt-4 align-self-end">
            <p style={{ fontSize: 9 }} className="text-muted mb-0 text-end">
              Posted
            </p>
            <p style={{ fontSize: 9 }} className="text-muted">
              {moment(specificJob.createdAt).fromNow()}
            </p>
          </div>
        </div>
        <div className="mb-4">
          <img
            style={{ width: "100%" }}
            src={specificJob.img}
            alt="Job"
            //onClick={() => history.push("/lightbox?photoId=" + props._id)}
          />
        </div>
        <h3 className="align-start ms-3 primaryColor fw-bold">
          {specificJob.title}
        </h3>
        <div className="ms-3 mb-4 d-flex">
          <img className="align-self-center me-2" src={location} />
          <div className="">
            {specificJob.city},{specificJob.province},{specificJob.country}{" "}
            {/* {specificUser.city},{specificUser.province},{specificUser.country}*/}
          </div>
        </div>
        <p className="ms-3">{specificJob.description}</p>

        <TextField comesFrom="Jobs and projects.specificJob.fields" fieldsArray={bioFields} styles="mb-5" font="12" />

        <div className="text-center">
          <Button
            onClick={applyJob}
            className="buttonLogin pt-2 pb-2 ps-4 pe-4 mb-4"
          >
            {t("Jobs and projects.specificJob.Apply")}
          </Button>
        </div>
      </div>
      <TabBar />
    </div>
  );
};

export default JobPage;
