import AzureGrid from "../../AzureGrid/AzureGrid"

const styles = {
    field__text: {
        color: "#1D0D3F",
        fontSize: 14,
    }
};

const fieldData = [
    { field: "Validation", data: "Email" },
    { field: "Field 02", data: "Data for this field" },
    { field: "Field 03", data: "Data for this field" }
];

const Minimum = props => {
    return (
        <AzureGrid>
            <div className="p-3">
                This is the basic level of all profiles upon registration. No phone verification is required.<br></br>
                As a basic status, it is advised to proceed to phone number verification to be seen as more trustful from other users and avoid scams and frauds.
                {fieldData.map((field, index) => {
                    return (
                        <div
                            key={index}
                            className='d-flex justify-content-between my-4 border-bottom border-primary mx-3'
                            style={styles.field__text}
                        >
                            <div>{field.field}</div>
                            <div style={{fontWeight:'bold'}}>{field.data}</div>
                        </div>
                    );
                })}
            </div>

        </AzureGrid>
    )
}

export default Minimum;