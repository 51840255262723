import { Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import Colors from "../../../constants/Colors";
import { FaRegEye } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER } from "../../../store/slices/UserSlice";
import passwordCreation from "../../../assets/passwordCreation.png";
import password from "../../../assets/password.png"

const styles = {
  container: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: 600,
    fontSize: 21,
    justifyContent: "center",
  },
  text: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: "center",
  },
  mainButton: {
    color: "white",
    borderRadius: 25,
    backgroundColor: Colors.primary,
    fontSize: 14,
    width: 165,
    height: 50,
    margin: 10,
  },
  input: {
    background: "#FFFFFF",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "3px",
    width: "90%",
    opacity: 0.4,
    fontSize: 14,
    backgroundImage: `url(${password})`,
    backgroundPosition: "13px center",
    backgroundRepeat: "no-repeat",
  },
};

const PasswordCreate = (props) => {
  const user = useSelector((state) => state.user.user);

  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [wrong, setWrong] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      SET_USER({
        password,
      })
    );
  }, [dispatch, password]);

  const validate = () => {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/i.test(
      password
    );
  };

  return (
    <div
      className="mt-5 pt-2"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxWidth: 500,
      }}
    >
      <div style={{ color: "#6F79D8" }} className="text-center">
        <h3>One last step!</h3>
      </div>
      <div className="text-center mt-5">
        <img src={passwordCreation} alt="password" />
      </div>

      <div className="text-center mt-5">
        <p style={{ fontSize: 14, fontWeight: 300 }}>
          Set your password using the field below.
        </p>
      </div>
      <div className="text-center mt-2">
        <input
          className="p-2 ps-4"
          style={styles.input}
          type="password"
          placeholder="Password"
        />
      </div>
      <div style={{margin:"auto"}} className="mt-5 pt-5">
        <Button
          style={{ width: "100%" }}
          onClick={() => {
            props.buttonHandler("pass", user);
          }}
          className="buttonLogin text-center p-2 ps-4 pe-4"
        >
          Complete
        </Button>
      </div>

      {/** <div style={styles.title} className="mt-4 mx-4">
                Your email has been verified
            </div>
            <div className="m-0 px-4" style={styles.container}>
                <div style={styles.text} className="my-4">
                    You can create your first password in the fields below.
                    Password must contain at least 8 characters, one letter, one number and a special character.
                    <br></br>
                    <br></br>
                    You will be redirected to login after tapping the confirm button.
                </div>
                {wrong &&
                    <div style={{ fontSize: 12, color: "red" }}>
                        Password does not fill the requirements!
                    </div>

                }
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', position: 'relative' }}>
                    <input type={showPass ? "text" : "password"} placeholder="Your password" style={styles.input} className="p-0 px-3 mb-2 mt-1" value={password} onChange={event => setPassword(event.target.value)} />
                    <Button className="p-3 mt-1" style={{ backgroundColor: 'transparent', outline: 'none', border: 'none', boxShadow: 'none', position: 'absolute', right: 5 }} onClick={() => setShowPass(!showPass)}>
                        <FaRegEye style={{ height: 30, width: 30, color: Colors.primary }} />
                    </Button>
                </div>
                <Button as="input" type="button" className="mt-4" value="Confirm" style={styles.mainButton}
                    onClick={() => {
                        if (validate()) props.buttonHandler("pass", user)
                        else setWrong(true)
                    }} />

                </div>*/}
    </div>
  );
};

export default PasswordCreate;
