import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createTransaction = createAsyncThunk(
  "transaction/createTransaction",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.TransactionService.createTransaction( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveTransactions = createAsyncThunk(
  "transaction/retrieveTransactions",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.TransactionService.retrieveTransactions(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveTransaction = createAsyncThunk(
  "transaction/retrieveTransaction",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await services.TransactionService.retrieveTransaction(transactionId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  "transaction/deleteTransaction",
  async (transactionId, { rejectWithValue }) => {
    try {
      const response = await services.TransactionService.deleteTransaction(transactionId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateTransaction = createAsyncThunk(
  "transaction/updateTransaction",
  async (transactionId,payload,{ rejectWithValue }) => {
    try {
      const response = await services.AccountService.updateTransaction(transactionId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state:"",
  error: {},
};

const TransactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    ACTION(state,action) {
    },
  },
  extraReducers: {
    /* ---- CREATE_TRANSACTION ---- */
    [String(createTransaction.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createTransaction.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createTransaction.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_TRANSACTIONS ---- */
    [String(retrieveTransactions.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveTransactions.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveTransactions.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_TRANSACTION ---- */
    [String(retrieveTransaction.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveTransaction.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveTransaction.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_TRANSACTION ---- */
    [String(updateTransaction.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateTransaction.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateTransaction.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_TRANSACTION---- */
    [String(deleteTransaction.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteTransaction.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteTransaction.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION} = TransactionSlice.actions;

export default TransactionSlice.reducer;
