import swaggerProps from "../plugins/swagger-client";

export default class CommentService {

  createComment(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Comment.createComment(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveComments(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Comment.retrieveComments(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveComment(commentId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Comment.retrieveComment(
          { commentId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteComment(commentId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Comment.deleteComment(
          { commentId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateComment(commentId,info) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Comment.updateComment(
          {commentId:commentId},
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
