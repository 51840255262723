const styles = {
    main__grid: {
      borderRadius: 10,
      backgroundColor: "#F2ECFF",
      width: "95%",
      boxShadow:"0px 2px 12px rgba(157, 118, 240, 0.1)"
    }
}

const AzureGrid = ({ children,style }) => {
  return (
    <div xs={12} className='m-2 main__grid mt-4 ' style={{...styles.main__grid,...style}}>
      {children}
    </div>
  );
};

export default AzureGrid;
