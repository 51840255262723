import TitleBar from "../../components/Mobile/TitleBar";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import { Button, Col } from "react-bootstrap";
import TabBar from "../../components/Mobile/TabBar";
import { useDispatch, useSelector } from "react-redux";
import { createJob, retrieveJob, updateJob } from "../../store/slices/JobSlice";
import { useEffect, useState, useCallback, useMemo } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import FileInputButton from "../../components/Mobile/Login-Register/FileInputButton";
import Logo from "../../assets/Rectangle 28.png";
import { useHistory, useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navbar";
import defaultPic from "../../assets/user.svg";
import uploadIcon from "../../assets/Upload IC.png";
import locationImg from "../../assets/Map pin IC.png";
import Select from "react-dropdown-select";
import countryList from "react-select-country-list";
import MoreButton from "../../assets/Freccina.png";
import fieldIcon from "../../assets/fieldIcon.png";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { useTranslation } from "react-i18next";
import { getJobImage } from "../../helpers/job";

const styles = {
  main__grid: {
    borderRadius: 10,
    backgroundColor: "#F2ECFF",
    width: "95%",
  },
  location__text: {
    color: "rgba(29, 13, 63, 0.6)",
    fontSize: 9,
  },
  field__text: {
    color: "#1D0D3F",
    fontSize: 12,
  },
  edit__button: {
    color: "white",
    borderRadius: "100px",
    fontSize: 12,
  },
  change__image: {
    borderRadius: 8,
    fontSize: 15,
    position: "absolute",
    bottom: 40,
    left: 40,
  },
  image: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  input: {
    background: "#FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "3px",
    opacity: 0.4,
    width: "90%",
  },
  dropdown:{
  background: " #FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    bordeRadius: "2px",
    zIndex: 102,
    width: "100%",
    opacity:0.4
  }
};
const options = ["Monza", "Padova", "Rome", "Milano"];

const options1 = [
  "Model",
  "Photographer",
  "MUA",
  "Stylist",
  "Hair stylist",
  "Observer",
];

/* const data = {
  location: "Monza",
  title: "Shooting at twilight on a building rooftop",
  posted: "22/06/2021",
  looking: "model",
  description:
    "The description for this job will be written here, in detail, as inputted by the owner.This job will entail many thing and they will be explained in this section t is required to have personal equipment to participate to this job or the application will be discarded.It is required to have personal equipment to participate to this job or the application will be discarded.",
}; */

const JobEditorPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  /* const inputFile = useRef(null); */
  const [image, setImage] = useState("");
  const [location, setLocation] = useState("");
  const [lookingFor, setLookingFor] = useState("");
  const [fieldsMissing, setFieldsMissing] = useState(false);
  const [jobData, setJobData] = useState({});
  const [flag, setFlag] = useState(true);
  const user = useSelector((state) => state.user.user);
  const [country, setCountry] = useState("Any");
  const [city,setCity] =useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { t } =useTranslation();

  const CountryOptions = useMemo(() => countryList().getData(), []);

  const fieldsArray = ["Age", "Gender", "Remuneration", "Deadline"];

  /* const onButtonClick = () => {
    inputFile.current.click();
  }; */

  /* const handleChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  }; */

  const onChange = (e) => {
    switch (e.target.name) {
      case "title":
        setTitle(e.target.value);
        break;
      case "description":
        setDescription(e.target.value);
        break;
      default:
        break;
    }
  };

  const sumbitChange = (event) => {
    const data = {
      title,
      description,
      file:image,
      city: city,
      country: country,
    };

    if (params.id === "new") {
      console.log(data);
      dispatch(
        createJob(data)
      )
        .unwrap()
        .then((originalPromiseResult) => {
          console.log(originalPromiseResult);
          history.goBack();
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    } else {
      dispatch(updateJob({ data: data, jobId: params.id }))
        .unwrap()
        .then((originalPromiseResult) => {
          console.log(originalPromiseResult);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    }
    /* if (
      form.title !== fetchedData.title ||
      form.description !== fetchedData.description
    ) {
    } */
  };

  const fetchJob = useCallback(
    (jobId) => {
      dispatch(retrieveJob(jobId))
        .unwrap()
        .then((originalPromiseResult) => {
          const job = originalPromiseResult.body.data;
          setJobData(job);
          setImage(getJobImage(job)); 
          setTitle(job.title);
          setDescription(job.description);
          setLocation(job.city);
          setLookingFor("model");
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    if (params.id !== "new") fetchJob(params.id);
  }, [fetchJob, params.id]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#FAFAFA",
      }}
    >
      <TitleBar title="Profile.TabBar.jobs.New job posting" comesFrom="specific" />
      <div style={{ width: "90%", margin: "auto" }} className="searchCard mt-3">
        <div className="d-flex align-items-center mt-2 mb-3">
          <div>
            <img
              width={45}
              height={45}
              className="rounded-circle circle__profile border border-3 border-white ms-2"
              src={defaultPic}
              alt="user"
            />
          </div>
          <div style={{ lineHeight: 1 }} className="ms-2">
            <h6 style={{ fontSize: 14, fontWeight: 400, color: "#101935" }}>
              {user.firstName} {user.lastName}
            </h6>
          </div>
        </div>
        <FileInputButton flag={true} styles={styles.image} 
        handleFile={(file) => {
          setImage(file)
        }}/>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <input
            className="p-2 ps-3"
            style={styles.input}
            type="text"
            placeholder={t("Profile.TabBar.jobs.New job posting.jobTitle")}
            name="title"
            onChange={onChange}
          />
        </div>
        <div className="d-flex mb-3 ms-3 ps-1">
          <div>
            <img src={locationImg} alt="location" />
          </div>
          <div
            className="align-self-center ms-2"
            style={{ fontSize: "14px", fontWeight: 300, color: "#101935" }}
          >
            {t("Profile.TabBar.jobs.New job posting.location")}
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div style={{ width: "42%" }} className="me-3 ">
            <CountryDropdown
              value={country}
              defaultOptionLabel={t("Profile.TabBar.jobs.New job posting.country")}
              className="p-1"
              id="country"
              onChange={(val) => setCountry(val)}
              style={styles.dropdown}
            />
          </div>
          <div style={{ width: "42%" }} className="">
            <RegionDropdown
              country={country}
              className="p-1"
              id="city"
              value={city}
              onChange={(val) => setCity(val)}
              style={styles.dropdown}
              defaultOptionLabel={t("Profile.TabBar.jobs.New job posting.city")}
            />
          </div>
        </div>
        <div className="ms-3 ps-1 mt-3 w-100">
          <textarea
            className="p-2 ps-3"
            style={styles.input}
            placeholder={t("Profile.TabBar.jobs.New job posting.jobDesc")}
            rows={10}
            name="description"
            onChange={onChange}
          />
        </div>
        <div className="d-flex ms-3 ps-1 mt-2">
          <div className="">
            <img src={fieldIcon} alt="field" />
          </div>
          <div
            className="align-self-center ms-2"
            style={{ fontSize: "14px", fontWeight: 300, color: "#101935" }}
          >
            {t("Profile.TabBar.jobs.New job posting.optionalFields.title")}
          </div>
        </div>

        <div
          style={{ margin: "auto", width: "80%", fontSize: 12 }}
          className={`mt-1 px-2 pt-3 text-start`}
        >
          {fieldsArray.map((field) => (
            <div
              style={{ borderBottom: "1px solid #E8E9F3" }}
              className="d-flex justify-content-between"
            >
              <div
                style={{ fontSize: "12px", fontWeight: 300, color: "#1D0D3F" }}
                className="col-7 mb-1 mt-1"
              >
                {" "}
                {t(`Profile.TabBar.jobs.New job posting.optionalFields.${field}`)}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#1D0D3F",
                  opacity: 0.2,
                }}
                className="col-5 mb-1 mt-1"
              >
                <input
                  style={{ border: "none", outline: "none" }}
                  type="text"
                  placeholder={t("Profile.TabBar.jobs.New job posting.optionalFields.insert")}
                />
              </div>
            </div>
          ))}
        </div>
        <div className="text-center">
          <Button className="buttonLogin mt-5 pt-2 pb-2 ps-4 pe-4 mb-4"
          disabled={title!="" && description!="" && image!="" ? false : true}
          onClick={sumbitChange}>
            {t("Profile.TabBar.jobs.New job posting.postJob")}
          </Button>
        </div>
      </div>
      {/*<div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 500, alignSelf: 'center' }}>
        <Col className='mt-4'>
          <div className='ms-4 ps-2 mb-1' style={{ fontSize: 18 }}>Title</div>
          <input
            type='text'
            xs={10}
            multiple
            value={title}
            onChange={onChange}
            name='title'
            style={{ outline: 'none', fontSize: 18, display: 'flex' }}
            className='border__card title__input ms-3 p-3 pt-2 pb-2'
          />
        </Col>
        <Col className='mt-4'>
          <div className='ms-4 ps-2 mb-1' style={{ fontSize: 18 }}>Detailed description</div>
          <textarea
            rows='8'
            cols='50'
            xs={10}
            value={description}
            onChange={onChange}
            name='description'
            style={{ outline: 'none', fontSize: 14 }}
            className='border__card description__input ms-3 p-3'
          />
        </Col>

        <Dropdown text='Posting in' current={location} options={options} passToParent={option => setLocation(option)} />
        <Dropdown
          text='Looking for a '
          current={lookingFor}
          options={options1}
          passToParent={option => setLookingFor(option)}
        />
        <div className='text-center mt-4 p-4' style={{ display: 'flex', flexDirection: 'column', width: '100%', alignContent: 'center', position: 'relative' }}>
          <img src={image ? URL.createObjectURL(image) : Logo} alt='main' width='100%' />
          <FileInputButton
            title='Change image'
            style={
              styles.change__image}
            class="text-white"
            handleFile={(file) => {
              setImage(file);
              setFlag(false);
            }}
          />
        </div>
        {fieldsMissing &&
          <div style={{ fontSize: 11, color: "red", width: '100%', textAlign: 'center' }}>
            You need to fill all the fields and select an picture!
          </div>
        }
        <Col className='d-flex justify-content-center mt-4 pb-4'>
          <Button
            style={styles.edit__button}
            variant='primary'
            className='w-50 pt-3 pb-3 fw-light'
            onClick={sumbitChange}
          >
            Confirm
          </Button>
          {/* <input
          type='file'
          id='file'
          ref={inputFile}
          onChange={handleChange}
          style={{ display: "none" }}
        /> 
        </Col>
      </div>
    */}

      <TabBar />
    </div>
  );
};

export default JobEditorPage;
