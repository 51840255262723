import swaggerProps from "../plugins/swagger-client";

export default class NotificationService {
  createNotification(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.createNotification(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveNotifications(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.retrieveNotifications(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveNotification(notificationId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.retrieveNotification(
          { notificationId: notificationId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteNotifications(notificationId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.deleteNotifications(
          { notificationId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateNotification(notificationId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Notification.updateNotification(
          { notificationId: notificationId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
