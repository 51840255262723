import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";

import UserSearchGrid from "../../components/Mobile/Users/UserSearchGrid";
import { retrieveUsers } from "../../store/slices/UserSlice";
import Dropdown from "../../components/Dropdown/Dropdown";
import ClipLoader from "react-spinners/ClipLoader";

const styles = {
  search: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    alignItems: "center",
    minheight: 210,
    zIndex: 2,
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    color: "white",
  },
  commentArea: {
    resize: "none",
    borderRadius: 10,
    outline: "none",
    height: 40,
    backgroundColor: "white",
    display: "flex",
  },
  backImage: {
    position: "absolute",
    top: -230,
    left: -150,
    zIndex: -1,
  },
};

const options = ["Rome", "Milano", "Padova", "Chania"];
const options1 = [
  "Model",
  "Photographer",
  "MUA",
  "Stylist",
  "Hair stylist",
  "Observer",
];

const UserSearchPage = (person) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const mountedRef = useRef(true);
  const [occupation, setOccupation] = useState("");
  const [city, setCity] = useState("");
  const dispatch = useDispatch();

  const myId = useSelector((state) => state.user.userId);
  const dropdown = useSelector((state) => state.user.dropdown);

  const fetchUsers = useCallback(
    (city, occupation) => {
      if (mountedRef.current) setLoading(true);
      dispatch(retrieveUsers({ city: city, occupation: occupation }))
        .unwrap()
        .then((originalPromiseResult) => {
          if (!mountedRef.current) return null;
          /*
          .filter((user)=>{
            return user.city == city
          })
          (FILTER WHEN USERS HAVE CITY AND COUNTRY)
          */
          setUsers(
            originalPromiseResult.body.data.filter((user) => user._id !== myId)
          );
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        })
        .finally(() => {
          if (mountedRef.current) setLoading(false);
        });
    },
    [dispatch, myId]
  );

  useEffect(() => {
    fetchUsers(city, occupation);
    return () => {
      mountedRef.current = false;
    };
  }, [fetchUsers, city, occupation]);

  return (
    <div className='w-100 m-0 p-0'>
      
        {dropdown && (
          <div className='p-1 m-2 ms-0 mt-0 pt-4 pb-4 dropdowns' style={{ width: "100%", maxWidth: 400 }}>
          <Dropdown
              text='lookingFilter'
              current={occupation}
              options={options1}
              translate={true}
              comesFrom="User search"
              passToParent={(value) => {
                setOccupation(value);
              }}
            />
            <Dropdown
              text='workingFilter'
              current={city}
              options={options}
              translate={false}
              comesFrom="User search"
              passToParent={(value) => {
                setCity(value);
              }}
            />
            <Dropdown
              text='provinceFilter'
              current={city}
              options={options}
              translate={false}
              comesFrom="User search"
              passToParent={(value) => {
                setCity(value);
              }}
            />

            
          </div>
        )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ClipLoader
          color={"white"}
          loading={loading}
          size={50}
          css={{ marginTop: 20 }}
        />
      </div>
      {!loading && users.length !== 0 && <UserSearchGrid data={users} />}
    </div>
  );
};

export default UserSearchPage;
