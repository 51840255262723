import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FileInputButton from "../../../components/Mobile/Login-Register/FileInputButton";
import Colors from "../../../constants/Colors";
import { SET_USER } from "../../../store/slices/UserSlice";
import InputMask from "react-input-mask";
import DefaultProfile from "../../../assets/user.svg";
import NoUpload from "../../../assets/noUploadImage.jpg";
import location from "../../../assets/Map pin IC.png";
import uploadIcon from "../../../assets/Upload IC white.png";
import personIcon from "../../../assets/userBlue.png";

let styles = {
  image: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto",
  },
  userNameText: {
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
    color: Colors.primary,
  },
  locationText: {
    textAlign: "center",
    fontSize: 14,
  },
  smallTextInfo: {
    fontSize: 9,
    marginTop: 10,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-2.5em",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "80%",
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
    overflow: "hidden",
    marginBottom: 10,
  },
  labels: {
    fontSize: 12,
    fontWeight: 300,
    textAlign: "left",
    width: "80%",
    padding: "5px 10px",
  },
  input: {
    border: "none",
    borderBottom: "none",
    fontSize: 24,
    outline: "none",
    width: "80%",
    margin: "auto",
    opacity: 0.4,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainButton: {
    color: "white",
    borderRadius: 15,
    backgroundColor: Colors.primary,
    fontSize: 12,
    minWidth: 135,
    margin: 10,
    height: 70,
  },
  commonFlexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
};

const PersonalInfo = (props) => {
  const user = useSelector((state) => state.user.user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [TFP, setTFP] = useState("");
  const [specialities, setSpecialities] = useState("");
  const [travel, setTravel] = useState("");
  const [image, setImage] = useState(null);
  const [noImage, setNoImage] = useState(false);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef();
  const _handleChange = (event) => {
    switch (event.target.id) {
      case "firstName":
        setFirstName(event.target.value);
      case "lastName":
        setLastName(event.target.value);
        break;
      case "Age":
        setAge(event.target.value);
        break;
      case "Pronouns":
        setPronouns(event.target.value);
        break;
      case "TFP Work":
        setTFP(event.target.value);
        break;
      case "Specialities":
        setSpecialities(event.target.value);
        break;
      case "Travel availability":
        setTravel(event.target.value);
        break;
      default:
        break;
    }
  };

  const fieldsArray = [
    "Age",
    "Gender",
    "Pronouns",
    "TFP Work",
    "Specialities",
    "Travel availability",
  ];

  const validate = () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      age: age,
      gender: gender,
      pronouns: pronouns,
      TFP: TFP,
      specialities: specialities,
      travel: travel,
      imageUrl: image ? URL.createObjectURL(image) : null,
      imageName: image ? image.name : "",
    };
    console.log(data);
    dispatch(SET_USER(data));

    if (!firstName || !lastName || !image) {
      return false;
    }

    return true;
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChangeImage = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  return (
    <div
      className="mt-5 pt-4"
      style={{ ...styles.commonFlexContainer, maxWidth: 500 }}
    >
      <div style={{ width: "92%" }} className="searchCard">
        <div style={styles.infoContainer} className="">
          <div
            style={{
              position: "relative",
              background: "#101935",
              borderRadius: "50%",
              width: 75,
              height: 75,
            }}
            onClick={handleClick}
          >
            <img
              style={{
                ...styles.image,
                width: !image ? 21 : 75,
                height: !image ? 24 : 75,
              }}
              src={(image && URL.createObjectURL(image)) || uploadIcon}
              className={
                image &&
                `circle__profil rounded-circle border border-3 border-white`
              }
              alt="Profile"
            />
            <input
              accept="image/*"
              type="file"
              ref={hiddenFileInput}
              onChange={handleChangeImage}
              onClick={(event) => {
                event.target.value = null;
              }}
              style={{ display: "none" }}
              multiple={props?.multiple ?? false}
            />
            {user.status === "active" ? (
              <div
                style={{
                  height: 20,
                  width: 20,
                  top: 3,
                  right: 1,
                  position: "absolute",
                  backgroundColor: "black",
                }}
                className="rounded-circle border border-2 border-white "
              ></div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="text-center me-4 mt-3 d-flex">
          <input
            className="text-end pe-2"
            style={styles.input}
            type="text"
            id="firstName"
            placeholder="Name"
            onChange={_handleChange}
          />
          <span
            style={{
              width: "20%",
              fontSize: 24,
              opacity: 0.4,
              display: (firstName !== "" && lastName !== "") && "none",
            }}
          >
            and
          </span>
          <input
            className="text-start ps-2"
            style={styles.input}
            type="text"
            id="lastName"
            placeholder="Surname"
            onChange={_handleChange}
          />
        </div>

        <div
          style={{ ...styles.locationText, color: "#101935", fontWeight: 300 }}
          className="px-2"
        >
          {user.occupation}{" "}
          <span className="ms-1 me-1">
            {" "}
            <img src={location} alt="location" />{" "}
          </span>
          <span>
            Monza, Lombardia, Italy{/*{details.city},{details.country}*/}
          </span>{" "}
        </div>
        <hr
          className="col-12 mb-2"
          style={{ backgroundColor: "#E8E9F3", opacity: 1 }}
        />
        <div className="d-flex w-100 p-2 ms-2 mt-2">
          <img
            width={11}
            height={11}
            className="align-self-center"
            src={personIcon}
            alt="fieldIcon"
          />
          <div className="align-self-center" style={styles.labels}>
            Additional information
          </div>
        </div>
        <div
          style={{ margin: "auto", width: "80%", fontSize: 12 }}
          className={`ps-1 pe-1 text-start mb-4 pb-1`}
        >
          {fieldsArray.map((field) => (
            <div
              style={{ borderBottom: "1px solid #E8E9F3" }}
              className="d-flex justify-content-between"
            >
              <div className="col-7 mb-1 mt-1"> {field}</div>
              <div className="col-5 mb-1 mt-1">
                <input
                  style={{ border: "none", outline: "none" }}
                  type="text"
                  id={`${field}`}
                  placeholder="Insert"
                  onChange={_handleChange}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/**
            <div style={styles.infoContainer} className='pt-3 mb-3'>
                <img
                    src={image ? URL.createObjectURL(image) : NoUpload}
                    width={125}
                    height={125}
                    className='circle__profil rounded-circle border border-3 border-white '
                    alt='Profile'
                />
            </div>
            <FileInputButton
                title='Change'
                style={{
                    ...styles.mainButton,
                    minWidth: 100,
                    borderRadius: 15,
                    height: "auto",
                }}
                className='px-1 py-2'
                handleFile={(file) => {
                    setImage(file);
                }}
            />
            <div style={styles.labels}>Name</div>
            <div style={styles.form}>
                <input
                    value={firstName}
                    type='text'
                    id='name'
                    placeholder='Lorenzo'
                    style={styles.input}
                    className='p-2'
                    onChange={_handleChange}
                    required
                />
            </div>
            <div style={styles.labels}>Surname</div>
            <div style={styles.form}>
                <input
                value={lastName}
                    type='text'
                    id='surname'
                    placeholder='Piccinato'
                    style={styles.input}
                    className='p-2'
                    onChange={_handleChange}
                    required
                />
            </div>
            <div style={styles.labels}>Phone Number</div>
            <div style={styles.form}>
                <InputMask
                    mask='(+99) 999 999 9999'
                    value={phone}
                    placeholder="(+99) 999 999 9999"
                    onChange={_handleChange}
                    style={styles.input}
                    className="p-2"
                    id="phone"
                />
            </div>
            {validated ? (
                <></>
            ) : (
                <div style={{ fontSize: 9, color: "red" }}>
                    You need to fill all the fields!
                </div>
            )}
            {noImage ? (
                <div style={{ fontSize: 9, color: "red" }}>
                    You need to select a profile image!
                </div>
            ) : (
                <></>
            )}
            <Button
                type='button'
                id='confirm'
                style={{
                    ...styles.mainButton,
                    minWidth: 100,
                    borderRadius: 15,
                    height: "auto",
                }}
                className='px-1 py-2 my-4'
                onClick={() => {
                    if (validate()) props.buttonHandler("", user);
                }}
            >
               {firstName || lastName || phone ? "Confirm":"Skip"}
            </Button>
             */}
      <div className="text-center mt-4 w-100">
        <Button
          type="button"
          id="confirm"
          variant="primary"
          style={{ background: "#e8e9f3", width: "30%" }}
          disabled={firstName !== "" ? false : true}
          className="buttonLogin text-center p-2"
          onClick={() => {
            if (validate()) props.buttonHandler("", user);
          }}
        >
          {firstName !== "" ? "Confirm" : "Skip"}
        </Button>
      </div>
    </div>
  );
};

export default PersonalInfo;
