import { useHistory } from "react-router-dom"
import TitleBar from "../../components/Mobile/TitleBar"
import Colors from "../../constants/Colors"
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { useEffect, useRef, useState } from "react"
import TabBar from "../../components/Mobile/TabBar"
import { useSelector } from "react-redux"
import Minimum from "../../components/Mobile/Levels/Minimum"
import Bronze from "../../components/Mobile/Levels/Bronze"
import Navigation from "../../components/Navbar/Navbar"
import { getUserAvatar } from "../../helpers/user"


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        height: '100%'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 24,
        backgroundColor: 'white'

    },
    text: {
        fontSize: 15,
        fontWeight: 400
    },
    messageContainer: {
        display: 'flex',
        maxWidth: '70%',
        width: 'auto',
        flexDirection: 'column',
    },
    date: {
        display: 'flex',
        fontSize: 12,
    },
    message: {
        display: 'flex',
        borderRadius: 15,
        backgroundColor: Colors.primary,
        fontSize: 15
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        minHeight: 60,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    searchBox: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        minHeight: 36,
        alignItems: 'center',
        borderRadius: 22
    },
    input: {
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        paddingLeft: 8,
        borderLeft: `2px solid ${Colors.primary}`,
        width: '100%'
    }
}


const Levels = props => {
    const levelSelected = props.active;

    const styles = {
        levelContainer: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            flexShrink: 1,
            width: 90,
            cursor:'pointer'
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="mt-3">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} className="mx-5 mb-3">
                <div style={{ ...styles.levelContainer, opacity: levelSelected === "minimum" || levelSelected === "" ? 1 : 0.5 }} className="mx-4" onClick={props.onClick.bind(this, "minimum")}>
                    <img src={props.image} width={90} height={90} className="circle__profile rounded-circle" alt="Profile" />
                    <div style={{ height: 20, width: 20, top: 3, right: 3, position: 'absolute', backgroundColor: 'black' }} className="rounded-circle border border-2 border-white " ></div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Minimum</div>
                </div>
                <div style={{ ...styles.levelContainer, opacity: levelSelected === "bronze" || levelSelected === "" ? 1 : 0.5 }} className="mx-4" onClick={props.onClick.bind(this, "bronze")}>
                    <img src={props.image} width={90} height={90} className="circle__profile rounded-circle" alt="Profile" />
                    <div style={{ height: 20, width: 20, top: 3, right: 3, position: 'absolute', backgroundColor: 'brown' }} className="rounded-circle border border-2 border-white "></div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Bronze</div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} className="mx-5 mb-3">
                <div style={{ ...styles.levelContainer, opacity: levelSelected === "silver" || levelSelected === "" ? 1 : 0.5 }} className="mx-3" onClick={props.onClick.bind(this, "silver")}>
                    <img src={props.image} width={90} height={90} className="circle__profile rounded-circle" alt="Profile" />
                    <div style={{ height: 20, width: 20, top: 3, right: 3, position: 'absolute', backgroundColor: 'silver' }} className="rounded-circle border border-2 border-white " ></div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Silver</div>
                </div>
                <div style={{ ...styles.levelContainer, opacity: levelSelected === "gold" || levelSelected === "" ? 1 : 0.5 }} className="mx-3" onClick={props.onClick.bind(this, "gold")}>
                    <img src={props.image} width={90} height={90} className="circle__profile rounded-circle" alt="Profile" />
                    <div style={{ height: 20, width: 20, top: 3, right: 3, position: 'absolute', backgroundColor: 'gold' }} className="rounded-circle border border-2 border-white "></div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Gold</div>
                </div>
                <div style={{ ...styles.levelContainer, opacity: levelSelected === "diamond" || levelSelected === "" ? 1 : 0.5 }} className="mx-3" onClick={props.onClick.bind(this, "diamond")}>
                    <img src={props.image} width={90} height={90} className="circle__profile rounded-circle" alt="Profile" />
                    <div style={{ height: 20, width: 20, top: 3, right: 3, position: 'absolute', backgroundColor: 'cyan' }} className="rounded-circle border border-2 border-white "></div>
                    <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 15 }}>Diamond</div>
                </div>
            </div>
        </div >
    )
}

const levelInfo = {
    minimum: <Minimum />,
    bronze: <Bronze />,
    silver: <div style={{ textAlign: 'center', color: Colors.primary, fontWeight: 'bold', fontSize: 15 }} className="mx-5 mt-3">(Under Construction)</div>,
    gold: <div style={{ textAlign: 'center', color: Colors.primary, fontWeight: 'bold', fontSize: 15 }} className="mx-5 mt-3">(Under Construction)</div>,
    diamond: <div style={{ textAlign: 'center', color: Colors.primary, fontWeight: 'bold', fontSize: 15 }} className="mx-5 mt-3">(Under Construction)</div>,
    "": <div style={{ textAlign: 'center', color: Colors.primary, fontWeight: 'bold', fontSize: 15 }} className="mx-5 mt-3">Simply tap on one of the icons to learn more.</div>
}


const LevelPage = () => {

    const user = useSelector(state => state.user.user);
    const [levelSelected, setLevelSelected] = useState("");
    const bottomRef = useRef(null)

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [levelSelected])

    const _buttonHander = level => {

        switch (level) {
            case "minimum":
                setLevelSelected("minimum")
                break;
            case "bronze":
                setLevelSelected("bronze")
                break;
            case "silver":
                setLevelSelected("silver")
                break;
            case "gold":
                setLevelSelected("gold")
                break;
            case "diamond":
                setLevelSelected("diamond")
                break;

            default:
                break;
        }
    }

    const history = useHistory();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh' }} className='m-0 p-0'>
           
                    <TitleBar />
            
            <div style={styles.title} className="p-3">
                <IoArrowBackCircleOutline style={{ height: 30, width: 30, color: Colors.primary, marginRight: 10,cursor:'pointer' }} onClick={() => history.goBack()} />
                Verification levels
            </div>
            <div style={styles.text} className="px-4 py-2">
                Membership levels are linked to the level of verification you have provided to our platform.
                {/* <br></br>
                Right now, two levels are available: */}
            </div>
            <Levels image={getUserAvatar(user)} onClick={_buttonHander} active={levelSelected} />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 500, alignSelf: 'center' }}>
                {levelInfo[levelSelected]}
            </div>
            <div style={{ marginBottom: 90 }} ref={bottomRef}></div>
           
                <TabBar props="fixed" />
            
        </div>
    )
}

export default LevelPage;