import { Col, Container, Row } from "react-bootstrap";
import "../Photos/gridStyle.scss";
import Masonry from "react-masonry-css";
/* import Post1 from "../../../assets/ExamplePosts/post1.jpg";
import Post2 from "../../../assets/ExamplePosts/post2.jpg";
import Post3 from "../../../assets/ExamplePosts/post3.jpg";
import Post4 from "../../../assets/ExamplePosts/post4.jpg";
import Post5 from "../../../assets/ExamplePosts/post5.jpg";
import Post6 from "../../../assets/ExamplePosts/post6.jpg";
import Post7 from "../../../assets/ExamplePosts/post7.jpg";
import Post8 from "../../../assets/ExamplePosts/post8.jpg";
import Post9 from "../../../assets/ExamplePosts/post9.jpg";
import Post10 from "../../../assets/ExamplePosts/post10.jpg"; */
import ExplorePhotoCard from "./ExplorePhotoCard";

/* const breakpointColumnsObj = {
  default: 2,
  1100: 2,
  700: 1,
  500: 1,
}; */
const breakpointColumnsObj = {
  default: 3,
  1024:2,
  700: 1,
  500: 1
};

/* const example_data = [
  {
    id: 1,
    name: "Portrait of",
    image: Post1,
    albumName: "Male Portrait",
  },
  {
    id: 2,
    name: "Portrait of",
    image: Post2,
    albumName: "Male Portrait",
  },
  {
    id: 3,
    name: "Portrait of",
    image: Post3,
    albumName: "Male Portrait",
  },
  {
    id: 4,
    name: "Portrait of",
    image: Post4,
    albumName: "Male Portrait",
  },
  {
    id: 5,
    name: "Portrait of",
    image: Post5,
    albumName: "Male Portrait",
  },
  {
    id: 6,
    name: "Portrait of",
    image: Post6,
    albumName: "Male Portrait",
  },
  {
    id: 7,
    name: "Portrait of",
    image: Post7,
    albumName: "Male Portrait",
  },
  {
    id: 8,
    name: "Portrait of",
    image: Post8,
    albumName: "Male Portrait",
  },
  {
    id: 9,
    name: "Portrait of",
    image: Post9,
    albumName: "Male Portrait",
  },
  {
    id: 10,
    name: "Portrait of",
    image: Post10,
    albumName: "Male Portrait",
  },
]; */

const ExploreGrid = ({data}) => {
  return (
    <Container className='m-0 p-0' fluid>
      <div className='justify-content-center mt-0 m-0' >
        <Col md='auto' className='justify-content-center'>
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className='my-masonry-grid2'
            columnClassName='my-masonry-grid_column2'
          >
            {data.map((item) => (
                <ExplorePhotoCard key={item._id} data={item} />
            ))}
          </Masonry>
        </Col>
      </div>
    </Container>
  );
};

export default ExploreGrid;
