import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import "@fontsource/montserrat";
import App from './App';
import ReduxProvider from './providers/redux';
import "./assets/styles/main.css";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <App />
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

