import {Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import MoreButton from "../../assets/Freccia.png";
import Colors from "../../constants/Colors";
import { useTranslation } from "react-i18next";

const Dropdown = ({ text, current, options,style,passToParent,translate,comesFrom}) => {
  const [isListOpen, setIsListOpen] = useState(false);
  const [currentOption, setCurrentOption] = useState(current);
  const {t} =useTranslation();

  const handleList = (e) =>{
    isListOpen ? setIsListOpen(false) : setIsListOpen(true);
  }

  const handleClick = (e) =>{
    setCurrentOption(e.target.id);
    if(passToParent!==""){
      passToParent(e.target.id);
    }
   
    handleList();
  }

  useEffect(()=>{
    setCurrentOption(current)
  },[current])

  return (
    <Col className='d-flex justify-content-center' style={{cursor:'pointer'}}>
      <div className='w-75 m-2 dropdown__box p-1' style={{position:"relative",maxWidth:"400px"}} onClick={handleList}>
        <div className='d-flex justify-content-between p-1 pb-1'>
          <span style={{fontSize:"13px"}} className='pt-1 ps-1'>
            {t(`${comesFrom}.${text}.title`)}
            <span className='fw-bold' style={{color:Colors.primary}}> {currentOption!="" && (translate ? t(`${comesFrom}.lookingFilter.options.${currentOption}`) : currentOption)}</span>
          </span>
          <span>
            <img src={MoreButton} alt='more' />{" "}
          </span>
        </div>
        {isListOpen && (
          <div
            className=""
            
          >
            <div className='p-0 m-0'>
              {options.map((option,index) => {
                return option === currentOption ? (
                  <div key={index} className='text-left modal__options p-1 pb-2 active__option' style={{color:Colors.primary}} id={option} onClick={handleClick}>
                   { text==="lookingFilter" ? t(`${comesFrom}.lookingFilter.options.${option}`) : option }
                  </div>
                ) : (
                  <div key={index} className='text-left modal__options p-1 pb-2' id={option} onClick={handleClick}> { text==="lookingFilter" ? t(`${comesFrom}.lookingFilter.options.${option}`) : option }</div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Col>
  );
};

export default Dropdown;
