import TabBar from "../../components/Mobile/TabBar";
import TitleBar from "../../components/Mobile/TitleBar";
import Masonry from "react-masonry-css";
import "../../components/Mobile/Photos/gridStyle.scss";
import { Button, Row } from "react-bootstrap";
/* import Post1 from '../../assets/ExamplePosts/post1.jpg'
import Post2 from '../../assets/ExamplePosts/post2.jpg'
import Post3 from '../../assets/ExamplePosts/post3.jpg'
import Post4 from '../../assets/ExamplePosts/post4.jpg'
import Post5 from '../../assets/ExamplePosts/post5.jpg'
import Post6 from '../../assets/ExamplePosts/post6.jpg'
import Post7 from '../../assets/ExamplePosts/post7.jpg'
import Post8 from '../../assets/ExamplePosts/post8.jpg'
import Post9 from '../../assets/ExamplePosts/post9.jpg'
import Post10 from '../../assets/ExamplePosts/post10.jpg' */
import Tile from "../../components/Gallery/Tile";
import OutCircledSvg from "../../components/OutcCrcledSvg";
import { ReactComponent as PrevSvg } from "../../assets/navigationSvg/prev.svg";
import ProfileMobile from "../../components/Mobile/Profile/Profile";
import Colors from "../../constants/Colors";
import { useHistory, useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import { retrieveGallery } from "../../store/slices/GallerySlice";
import { useDispatch, useSelector } from "react-redux";
import Navigation from "../../components/Navbar/Navbar";
import backButton from "../../assets/backButton.png";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    //backgroundImage: `url(${background})`,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    overflow: "hidden",
  },
  backImage: {
    position: "absolute",
    top: -200,
    left: -150,
    zIndex: -1,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tab: {
    color: "black",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    border: "none",
    fontSize: 9,
    boxShadow: "none",
    marginRight: 5,
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginTop: 31,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
  },
  mainButton: {
    color: "white",
    borderRadius: 10,
    backgroundColor: Colors.primary,
    fontSize: 18,
  },
};
/* const example_data = [{
    id: 1,
    name: "Post1",
    image: Post1
}, {
    id: 2,
    name: "Post2",
    image: Post2
}, {
    id: 3,
    name: "Post3",
    image: Post3
}, {
    id: 4,
    name: "Post4",
    image: Post4
}, {
    id: 5,
    name: "Post5",
    image: Post5
}, {
    id: 6,
    name: "Post6",
    image: Post6
}, {
    id: 7,
    name: "Post7",
    image: Post7
}, {
    id: 8,
    name: "Post8",
    image: Post8
}, {
    id: 9,
    name: "Post9",
    image: Post9
}, {
    id: 10,
    name: "Post10",
    image: Post10
}
]; */

const breakpointColumnsObj = {
  default: 3,
  1024: 2,
  700: 1,
  500: 1,
};

const ActionButtons = (props) => {
  let history = useHistory();
  return (
    <div style={styles.actionButtons} className="mt-5 ms-2 align-items-center">
      <div
        className="px-3 align-self-center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={backButton} alt="back" />
      </div>
      <div
        className="d-flex searchCard ps-3 pe-3 pb-2 pt-3 justify-content-center mainColor"
        style={{ width: "67%" }}
      >
        <div className="align-self-center">
          <h3>{props.name} </h3>
        </div>
      </div>
    </div>
  );
};

const MyGalleryPage = ({ person }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [fetchedData, setFetchedData] = useState({ photos: [] });
  const personState = useSelector((state) => state.user.user);

  const fetchGallery = useCallback(() => {
    dispatch(retrieveGallery({ galleryId: id }))
      .unwrap()
      .then((originalPromiseResult) => {
        setFetchedData(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, [dispatch, id]);

  useEffect(() => {
    fetchGallery();
  }, [fetchGallery]);

  return (
    <div style={{ width: "100%", backgroundColor: "#FAFAFA" }}>
      <TitleBar title="profile" />
      <ProfileMobile person={personState} />

      <ActionButtons name={fetchedData.title} />
      <div className="justify-content-center mt-5 m-0">
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid2"
          columnClassName="my-masonry-grid_column2"
        >
          {fetchedData.photos.map((item, index) => (
            <div key={index}>
              <Tile gallery={fetchedData} data={item} />
            </div>
          ))}
        </Masonry>
      </div>

      <TabBar />
    </div>
  );
};

export default MyGalleryPage;
