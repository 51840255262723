const styles={
    secondary__grid: {
        borderRadius: 10,
        background: 'white',
        width:'95%',
        fontSize:12,
        boxShadow:"0px 6px 12px rgba(157, 118, 240, 0.1)"
    },  
}

const Card = ({data}) => {

    return (
      <div xs={10} className='m-2 secondary__grid mt-4 p-3' style={styles.secondary__grid}>
        {data}
      </div>
    );
  };

export default Card;