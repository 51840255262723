import { useHistory } from "react-router-dom";
import moment from 'moment';
import { getUserAvatar } from "../../../helpers/user";

const UserPhotoInfo = ({ user, title, place = "end",date,data,comesFrom }) => {
  moment().format();
  const history = useHistory();


  const clickAction = () =>{
    
    data?.click_action && history.push(`${data.click_action}`)

  }

  return (
    <div className="details ms-2 d-flex justify-content-between mb-2" onClick={comesFrom!=="" && clickAction}>
      <div className="d-flex w-75 mt-3">
        <div>
          <img
            width={50}
            height={50}
            className="rounded-circle circle__profile border border-3 border-white"
            src={getUserAvatar(user)}
            alt="user"
          />
        </div>
        <div style={{ lineHeight: 1 }} className="ms-2 mt-1">
          <h6 className="" style={{ fontSize: 14, fontWeight: 400 }}>
            {user.firstName} {user.lastName}
          </h6>
          <p style={{ fontSize: 12, fontWeight: 300}}>{title}</p>
        </div>
      </div>
      <div className={moment(date).fromNow()!=="a few seconds ago"?`me-3 d-flex align-items-${place}`:`me-2 d-flex align-items-${place}`}>
        <p style={{ fontSize: 9,color:'#101935',opacity:0.5,width:"100%"}} >
          {moment(date).fromNow()}
        </p>
      </div>
    </div>
  );
};

export default UserPhotoInfo;
