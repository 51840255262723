
export const CHANGE_ACTIVE_PROFILE_TAB ="CHANGE_ACTIVE_PROFILE_TAB"
export const CHANGE_ACTIVE_MAIN_TAB ="CHANGE_ACTIVE_MAIN_TAB"

export const switchProfileTab = tabName =>{
    return {type:CHANGE_ACTIVE_PROFILE_TAB, tabName};
};
export const switchMainTab = tabName =>{
    return {type:CHANGE_ACTIVE_MAIN_TAB, tabName};
};

