import { useHistory } from "react-router-dom"
import TitleBar from "../../components/Mobile/TitleBar"
import Colors from "../../constants/Colors"
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { useCallback, useEffect, useRef, useState } from "react"
import { MdSend } from 'react-icons/md'
import { TiCamera } from 'react-icons/ti'
import { retrieveUser } from "../../store/slices/UserSlice"
import { useDispatch, useSelector } from "react-redux"
import ClipLoader from "react-spinners/ClipLoader";
import { retrieveChats, createChat, createChatMessages, updateChatMessages } from "../../store/slices/ChatSlice"
import Navigation from "../../components/Navbar/Navbar"
import { NotificationBody, sendNotification } from "../../helpers/NotificationUtils"


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        height: '100%'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 24,
        backgroundColor: 'white'

    },
    text: {
        fontSize: 15,
        fontWeight: 400
    },
    messageContainer: {
        display: 'flex',
        maxWidth: '70%',
        width: 'auto',
        flexDirection: 'column',
    },
    date: {
        display: 'flex',
        fontSize: 12,
    },
    message: {
        display: 'flex',
        borderRadius: 15,
        backgroundColor: Colors.primary,
        fontSize: window.innerWidth > 600 ? 18 : 15
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        minHeight: 60,
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white'
    },
    searchBox: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        minHeight: 36,
        alignItems: 'center',
        borderRadius: 22
    },
    input: {
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        paddingLeft: 8,
        borderLeft: `2px solid ${Colors.primary}`,
        width: '100%'
    }
}



//var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const ChatPage = props => {

    const [chat, setChat] = useState([])
    const [text, setText] = useState("")
    const [chatInfo, setChatInfo] = useState({})
    const [otherUserId,] = useState(new URLSearchParams(window.location.search).get("userId"))
    const [user, setUser] = useState({})//new URLSearchParams(window.location.search).get("userId")
    const [loading, setLoading] = useState(true)
    const bottomRef = useRef(null)
    const mountedRef = useRef(true)

    const currentUserId = useSelector(state => state.user.userId)

    const dispatch = useDispatch();
    const history = useHistory()

    useEffect(() => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" })
    }, [chat.length])

    useEffect(
        () => {
            const updateRead = chat => {
                const lastMsg = chat.messages[chat.messages.length - 1];

                if (lastMsg.ownerId._id === currentUserId) return;

                let data = {
                    chatId: chat._id,
                    messagesId: lastMsg._id,
                    payload: {
                        ...lastMsg,
                        read: true
                    }
                }
                dispatch(updateChatMessages(data))
                    .unwrap()
                    .then((originalPromiseResult) => {

                        console.log(originalPromiseResult);

                    })
                    .catch((rejectedValueOrSerializedError) => {
                        console.log(rejectedValueOrSerializedError);
                    })
            }
            const fetchChatIfExists = (userId) => {
                dispatch(retrieveChats())
                    .unwrap()
                    .then((originalPromiseResult) => {

                        if (!mountedRef.current) return null;

                        let currentChat = originalPromiseResult.body.data.find(chat => {
                            let me = false;
                            let other = false;
                            for (const index in chat.people) {
                                if (chat.people[index].userId === currentUserId)
                                    me = true;
                                else if (chat.people[index].userId === userId)
                                    other = true;

                            }
                            if (me && other && chat.people.length === 2) return true

                            return false;
                        })
                        if (currentChat) {
                            setChatInfo(currentChat);
                            setChat(currentChat.messages)
                            updateRead(currentChat)
                        }
                    })
                    .catch((rejectedValueOrSerializedError) => {
                        console.log(rejectedValueOrSerializedError);
                    })
                    .finally(() => {
                        if (mountedRef.current)
                            setLoading(false);
                    });
            }

            const fetchUser = (userId) => {
                dispatch(retrieveUser(userId))
                    .unwrap()
                    .then((originalPromiseResult) => {

                        if (!mountedRef.current) return null;
                        setUser(originalPromiseResult.body.data)
                    })
                    .catch((rejectedValueOrSerializedError) => {
                        console.log(rejectedValueOrSerializedError);
                    })
                    .finally(() => {
                        if (mountedRef.current)
                            setLoading(false);
                    });
            }

            if (otherUserId) {
                fetchUser(otherUserId);
                fetchChatIfExists(otherUserId)
            }

            return () => {
                mountedRef.current = false;
            }
        }, [dispatch, otherUserId, currentUserId]
    )

    const fetchCreateChat = useCallback((data) => {
        dispatch(createChat(data))
            .unwrap()
            .then((originalPromiseResult) => {
                setChatInfo(originalPromiseResult.body.data)
                sendNotification({
                    title: 'Chat',
                    body: NotificationBody.MESSAGE,
                    click_action: "/chat?userId=" + currentUserId,
                    type: 'chat',
                    status: 'new'
                })
            })
            .catch((rejectedValueOrSerializedError) => {
                console.log(rejectedValueOrSerializedError);
            })
    }, [dispatch,currentUserId])
    const fetchSendMessage = useCallback((data) => {
        dispatch(createChatMessages(data))
            .unwrap()
            .then((originalPromiseResult) => {

                console.log(originalPromiseResult.body.data)
            })
            .catch((rejectedValueOrSerializedError) => {
                console.log(rejectedValueOrSerializedError);
            })
    }, [dispatch])

    if (!otherUserId)
        return (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>User not found!</div>
        )

    if (loading)
        return (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <ClipLoader color={Colors.primary} loading={true} size={50} css={{ marginTop: 20 }} />
            </div>
        )




    const send = () => {


        if (!text) return;

        let date = new Date();
        /* let dd = date.getDate()
        let mm = date.getMonth()
        let yy = date.getFullYear()
        let time = date.getHours() + ':' + date.getMinutes()
        let dayname = days[date.getDay()].substring(0, 3); */



        let array = chat
        array.push({
            body: text,
            updatedAt: 'now',
            ownerId: { _id: currentUserId }
        })
        setChat(array)
        setText("")

        if (!chatInfo?._id) {
            let data = {
                title: "",
                description: "",
                messages: [
                    {
                        body: text,
                        updatedAt: date,
                        ownerId: currentUserId,
                        read: false
                    }
                ],
                people: [
                    {
                        userId: currentUserId
                    },
                    {
                        userId: otherUserId
                    }
                ],
                status: "active"
            }
            fetchCreateChat(data)
        }
        else
            fetchSendMessage({
                chatId: chatInfo._id,
                payload: {
                    body: text,
                    updatedAt: date
                }
            })
    }

    const _enterHandler = event => {
        if ((event.code === "Enter" || event.code === "NumpadEnter")) {

            event.preventDefault();
            send();
        }
    };

    const formatDate = (string) => {
        const match = string.match(/(\d{4})-(\d{2})-(\d{1,2})T(\d{2}:\d{2}).*/)
        if (match)
            return `${match[3]}/${match[2]}/${match[1]} at ${match[4]}`
        else return string
    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}>
            <div style={{
                position: 'sticky',
                top: 0,
                zIndex: 1000
            }}>
                <TitleBar />

                <div style={styles.title} className="px-3 py-2 border-bottom">
                    <IoArrowBackCircleOutline style={{ height: 30, width: 30, color: Colors.primary, marginRight: 10, cursor: 'pointer' }} onClick={() => history.goBack()} />
                    {user.firstName}
                </div>
            </div>
            {
                chat.length === 0 ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 15 }}>
                        Send a message to start a conversation
                    </div>
                    :
                    <div className="m-0 px-4" style={styles.container}>
                        {
                            chat.map((item, index) =>
                                <div key={index} style={{ ...styles.messageContainer, alignSelf: item.ownerId._id === currentUserId ? 'flex-end' : 'flex-start' }} className="mb-2">
                                    <div style={{ ...styles.date, alignSelf: item.ownerId._id === currentUserId ? 'flex-end' : 'flex-start' }} className="text-muted mb-1 mt-1 mx-2">{formatDate(item.updatedAt)}</div>
                                    <div
                                        style={{
                                            ...styles.message,
                                            alignSelf: item.ownerId._id === currentUserId ? 'flex-end' : 'flex-start',
                                            backgroundColor: item.ownerId._id === currentUserId ? Colors.primary : Colors.secondary,
                                            color: item.ownerId._id === currentUserId ? 'white' : 'rgba(29, 13, 63, 0.6)'
                                        }}
                                        className="py-1 px-3">
                                        {item.body}
                                    </div>
                                </div>
                            )
                        }
                    </div>
            }
            <div ref={bottomRef} />
            <div style={styles.headerContainer} className="mt-2 px-4">
                <TiCamera style={{ height: 30, width: 30 }} className="mx-2" />
                <div style={styles.searchBox} className="px-3 border">
                    <input placeholder="Type something" value={text} onChange={event => setText(event.target.value)} onKeyUp={_enterHandler} style={styles.input} autoFocus />
                    <MdSend style={{ height: 25, width: 25, color: Colors.primary }} className="mx-2" onClick={send} />
                </div>
            </div>
        </div>
    )
}

export default ChatPage;