import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import noPictureUser from "../../../assets/user.svg"
import Colors from "../../../constants/Colors"
import { retrieveUser } from "../../../store/slices/UserSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { relativeTimeTranform } from "../../../helpers/time";
import { useHistory } from "react-router-dom";
import UserPhotoInfo from "../Photos/UserPhotoInfo";

const styles = {
    card: {
        display: 'flex',
        flexDirection: 'row',
        width: '85%',
        boxShadow: '0px 6px 12px rgba(157, 118, 240, 0.1)',
        borderRadius: 10,

    },
    text: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        fontSize: 13
    }
}



const NotificationCard = ({ data }) => {
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(true)
    const mountedRef = useRef(true);

    const dispatch = useDispatch()
    const history=useHistory()

    const fetchUser = useCallback(() => {

        if (mountedRef.current)
            setLoading(true)

        dispatch(retrieveUser(data.ownerId?._id))
            .unwrap()
            .then((originalPromiseResult) => {

                if (!mountedRef.current) return null;

                setUser(originalPromiseResult.body.data)
            })
            .catch((rejectedValueOrSerializedError) => {
                console.log(rejectedValueOrSerializedError);
            })
            .finally(() => {
                if (mountedRef.current)
                    setLoading(false)
            });
    }, [dispatch, data.ownerId]);

    useEffect(() => {
        fetchUser()
        return () => {
            mountedRef.current = false;
        }
    }, [fetchUser])

    return (

        <UserPhotoInfo place="end" user={user} title={data.body} data={data} comesFrom="notification"/>
        
    )
}

export default NotificationCard