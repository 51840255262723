import { Button } from "react-bootstrap";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Colors from "../../constants/Colors";
import { switchProfileTab } from "../../store/actions/tabs";
import PersonInfo from "../../components/Mobile/Profile/PersonInfo.js";
import { AiOutlineInfoCircle } from "react-icons/ai";
//import DefaultProfile from '../../../assets/DefaultProfile.png'
import DefaultProfile from "../../assets/user.svg";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";
import TitleBar from "../../components/Mobile/TitleBar";
import TabBar from "../../components/Mobile/TabBar";
import ProfilePhotosGrid from "../../components/Mobile/Photos/ProfilePhotosGrid";
import AlbumsGrid from "../../components/Mobile/Album/AlbumsGrid";
import ProjectJobGrid from "../../components/Mobile/Jobs/ProjectJobGrid";
import Bio from "../../components/Mobile/Bio/BioPage";
import { retrieveUser } from "../../store/slices/UserSlice";
import Navigation from "../../components/Navbar/Navbar";
import { useTranslation } from "react-i18next";

const styles = {
  container: {
    position: "relative",
    height: 230,
  },
  backImage: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: -1
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  largeContainer:{
      position:"relative",
      top:"2.5rem"
  },
  tab: {
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    border: "none",
    boxShadow: "none",
  },
  tabContainer: {
    display: "flex",
    background: "#E8E9F3",
    borderRadius: "100px",
    width:'70%',
    margin:'auto'
    
  },
};

const Tabs = (props) => {
  let activeTab = useSelector((state) => state.tabState.profileTabName);
  const [photosTab, setPhotosTab] = useState(
    activeTab === "Photos" ? true : false
  );
  const [galleriesTab, setGalleriesTab] = useState(
    activeTab === "Galleries" ? true : false
  );
  const [bioTab, setBioTab] = useState(activeTab === "Bio" ? true : false);
  const [jobsTab, setJobsTab] = useState(
    activeTab === "Jobs and Projects" ? true : false
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const _tabHandler = (event) => {
    console.log(event)
    dispatch(switchProfileTab(event.target.id));

    switch (event.target.id) {
      case "Photos":
        setPhotosTab(true);
        setGalleriesTab(false);
        setBioTab(false);
        setJobsTab(false);
        break;
      case "Galleries":
        setPhotosTab(false);
        setGalleriesTab(true);
        setBioTab(false);
        setJobsTab(false);
        break;
      case "Bio":
        setPhotosTab(false);
        setGalleriesTab(false);
        setBioTab(true);
        setJobsTab(false);
        break;
      case "Jobs and Projects":
        setPhotosTab(false);
        setGalleriesTab(false);
        setBioTab(false);
        setJobsTab(true);
        break;

      default:
        break;
    }
  };


  return (
    <div className="p-0 mt-4 d-flex justify-content-around" style={{...styles.tabContainer,marginTop: props.largeStyles ? 20:5,maxWidth:"300px"}}>
      <div
        id="Photos"
        className="m-2 me-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: photosTab ? "#6F79D8" : "black",
          borderBottom: photosTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
        {t("Profile.TabBar.photos.title")}
      </div>
      <div
        id="Galleries"
        className="m-2 ms-0 me-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: galleriesTab ? "#6F79D8" : "black",
          borderBottom: galleriesTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
        {t("Profile.TabBar.galleries.title")}
      </div>
      <div
        id="Bio"
        className="m-2 ms-0 me-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: bioTab ? "#6F79D8" : "black",
          borderBottom: bioTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
        {t("Profile.TabBar.bio.title")}
      </div>
      <div
        id="Jobs and Projects"
        className="m-2 ms-0"
        style={{
          backgroundColor:"transparent",
          fontSize:12,
          color: jobsTab ? "#6F79D8" : "black",
          borderBottom: jobsTab ? "1px solid #6F79D8" : "none",
          fontWeight:600
        }}
        onClick={_tabHandler}
      >
      {t("Profile.TabBar.jobs.title")}
      </div>
    </div>
  );
};

const PersonalPage = ({ person, profileImage, comesFrom }) => {
  const tabActive1 = useSelector((state) => state.tabState.profileTabName);
  const currentId = useParams();
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();

  const fetchUser = useCallback(() => {
    dispatch(retrieveUser(selectedUserId))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data)
        setUser(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
  }, [dispatch, selectedUserId]);

  useEffect(() => {
    if (currentId.id === selectedUserId) {
      fetchUser();
    }

  }, [fetchUser, currentId.id, selectedUserId]);

  const _contentSwitch1 = (tabName) => {
    switch (tabName) {
      case "Photos":
        return (
          <ProfilePhotosGrid
          person={person}
          add={comesFrom}
          comesFrom='selectedUser'
          />
        );
      case "Galleries":
        return (
          <AlbumsGrid
          person={person}
          add={comesFrom}
          comesFrom='selectedUser'
          />
        );
      case "Bio":
        return <Bio person={person}
        add={comesFrom}
        comesFrom='selectedUser'
        />;
      case "Jobs and Projects":
        return (
          <ProjectJobGrid
          person={person}
          add={comesFrom}
          comesFrom='selectedUser'
            private
          />
        );
      default:
        return <></>;
    }
  };

  const history = useHistory();
  return (
    <div
      className='m-0 p-0'
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        backgroundColor:"#FAFAFA"
      }}
    >
      
          <TitleBar />
      
      <div style={{ height: "100%", width: "100%" }}>
        <div className='pt-3 mt-5' style={styles.container}>
          {/* <img src={background} style={styles.backImage} alt="background" /> */}
          <div className='m-0' style={styles.infoContainer}>
            <PersonInfo
             comesFrom="personal"
             person={user ? user : person}
             textAlign='center'
            />
          </div>
          <Tabs />
        </div>
        {_contentSwitch1(tabActive1)}
      </div>
      
        <TabBar />
      
    </div>
  );
};

export default PersonalPage;
