import { ReactComponent as FollowersSvg } from "../../../assets/followers.svg";
import { ReactComponent as LikeSvg } from "../../../assets/like.svg";
import { ReactComponent as CommentSvg } from "../../../assets/comments.svg";
import { Col } from "react-bootstrap";
import Colors from "../../../constants/Colors";
import { useSelector } from "react-redux";
import location from "../../../assets/Map pin IC.png";
import followers from "../../../assets/followers.png";
import likes from "../../../assets/Likes.png";
import comments from "../../../assets/Comments.png";
import DefaultProfile from "../../../assets/user.svg";
import { useTranslation } from "react-i18next";

const PersonInfo = ({ person, textAlign, comesFrom, largeStyles,profileImage }) => {
  console.log(largeStyles);
  const { t }=useTranslation();

  const styles = {
    userNameText: {
      fontSize: largeStyles ? largeStyles.name : 15,
      fontWeight: "bold",
      textAlign: "center",
      color: largeStyles ? largeStyles.text : Colors.primary,
    },
    locationText: {
      textAlign: "center",
      fontSize: largeStyles ? largeStyles.role : 14,
    },
    smallTextInfo: {
      fontSize: largeStyles ? largeStyles.info : 9,
      marginTop: 10,
    },
    infoContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop:"-2.5em"
    },
  };

  const personDetails = useSelector((state) => state.user.user);
  const details =
    comesFrom === "card" || comesFrom === "personal" ? person : personDetails;
  return (
    <div style={{width:"90%"}} className='searchCard'>
      <div style={styles.infoContainer} className=''>
        <div style={{ position: "relative" }}>
          <img
            src={profileImage || DefaultProfile}
            width={75}
            height={75}
            className='circle__profile rounded-circle border border-3 border-white '
            alt='Profile'
          />
          {person.status === "active" ? (
            <div
              style={{
                height: 20,
                width: 20,
                top: 3,
                right: 1,
                position: "absolute",
                backgroundColor: "black",
              }}
              className='rounded-circle border border-2 border-white '
            ></div>
          ) : (
            <></>
          )}
        </div>
      </div>

      <h3 style={{fontWeight:500}} className='pt-3 p-4 pb-0 text-center'>
        {details.firstName} {details.lastName}
      </h3>
      <div
        style={{ ...styles.locationText, textAlign: textAlign,fontWeight:300 }}
        className='px-2'
      >
        {t(`Profile.jobs.${details.occupation}`)}{" "}
        <span className='ms-1 me-1'>
          {" "}
          <img src={location} alt='location' />{" "}
        </span>
        <span style={{fontWeight:300}}>
          Monza, Lombardia, Italy{/*{details.city},{details.country}*/}
        </span>{" "}
      </div>
      <hr
        className='col-12 mb-2'
        style={{ backgroundColor: "#E8E9F3",opacity:1}}
      />
      <div style={{fontWeight:300}} className='w-100 d-flex mb-2 ps-2 pe-2 justify-content-center'>
        <div className='me-1'>
          <img src={followers} />
        </div>
        <div
          style={{ fontSize: "0.6875em" }}
          className='align-self-center me-2'
        >
          30 {t("Profile.followers")}{/*{props.userInfo.followers.length}*/}
        </div>
        <div className='me-1'>
          <img src={likes} />
        </div>
        <div
          style={{ fontSize: "0.6875em" }}
          className='align-self-center me-2'
        >
          455 {t("Profile.likes")}{/*{props.userInfo.followers.length}*/}
        </div>
        <div className='me-1'>
          <img src={comments} />
        </div>
        <div style={{ fontSize: "0.6875em" }} className='align-self-center'>
          60 {t("Profile.comments")}{/*{props.userInfo.followers.length}*/}
        </div>
      </div>

      {/*  <div style={{ ...styles.infoContainer, alignItems: textAlign==="left" ? "start" : "center",justifyContent: textAlign==="left" ? "start" : "center" }}>
                <Col md="auto" className="p-0" style={styles.smallTextInfo}>
                    <FollowersSvg className="mb-1 mx-2" />
                    {details?.followers?.length ?? 0} Followers</Col>
                <Col md="auto" className="p-0" style={styles.smallTextInfo}>
                    <LikeSvg className="mb-1 mx-2" />
                    {details?.likes?.length ?? 0} Likes
                </Col>
                <Col md="auto" className="p-0" style={styles.smallTextInfo}>
                    <CommentSvg className="mb-1 mx-2" />
                    {details?.comments?.length ?? 0} Comments
                </Col>
            </div>*/}
    </div>
  );
};

export default PersonInfo;
