import md5 from "js-md5";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import FileInputButton from "../../../components/Mobile/Login-Register/FileInputButton";
import UploadPhotoTile from "../../../components/Mobile/Photos/UploadPhoto";
import Colors from "../../../constants/Colors";
import completeImage from "../../../assets/registrationComplete.png";
import { RiErrorWarningLine } from "react-icons/ri";

let styles = {
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainButton: {
    color: "white",
    borderRadius: 15,
    backgroundColor: Colors.primary,
    fontSize: 12,
    minWidth: 135,
    margin: 10,
    height: 70,
  },
  instructions: {
    width: "80%",
    borderRadius: 10,
    backgroundColor: "white",
    fontSize: 12,
  },
  warning: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    height: 125,
    width: 255,
    border: `2px solid ${Colors.primary}`,
    borderRadius: 10,
    fontSize: 12,
  },
  commonFlexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
};

const PhotosUpload = (props) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageAmount, setImageAmount] = useState(0);
  const [titleMissing, setTitleMissing] = useState(false);

  const addPhoto = (files) => {
    setTitleMissing(false);
    for (let i = 0; i < files.length; i++) {
      const image = {
        id: md5(files[i].name + Date().toLocaleString()),
        image: files[i],
        title: "",
      };
      let array = uploadedImages;
      array.push(image);
      setUploadedImages(array);
      setImageAmount(imageAmount + 1);
    }
  };

  const removeImage = (id) => {
    setTitleMissing(false);
    let array = uploadedImages.filter((image) => image.id !== id);
    setUploadedImages(array);
    setImageAmount(imageAmount - 1);
  };

  let data = {
    "photos[]": uploadedImages,
  };

  useEffect(() => {
    setImageAmount(uploadedImages.length);
  }, [imageAmount, uploadedImages]);

  const validated = () => {
    for (let i = 0; i < uploadedImages.length; i++) {
      if (uploadedImages[i].title === "") {
        setTitleMissing(true);
        return false;
      }
    }
    return true;
  };

  return (
    <div
      className="mt-5 pt-2"
      style={{ ...styles.commonFlexContainer, maxWidth: 500 }}
    >
      <div style={{ margin: "auto", color: "#6F79D8" }}>
        <h3>Registration Complete!</h3>
      </div>
      <div className="mt-4" style={{ margin: "auto" }}>
        <img src={completeImage} alt="complete" />
      </div>
      <div
        className="mt-4 pt-2 text-center"
        style={{ margin: "auto", fontWeight: 300, fontSize: 14, width: "75%" }}
      >
        <p>
          An email containing details on how to proceed has been sent to your
          inbox.
        </p>
      </div>
      <div
        className="mt-1 text-center"
        style={{ margin: "auto", fontSize: 14, fontWeight: 300, width: "70%" }}
      >
        <p>If you are having trouble use the button below</p>
      </div>
      <Button
        onClick={() => {
          props.buttonHandler("photos", data);
        }}
        className="buttonLogin text-center mt-3 p-2 ps-4 pe-4"
      >
        Try again
      </Button>

      {/** <div style={styles.instructions} className="p-3 mb-3">
                This section will contain advice on what photos to upload as the role
                chosen by the user. There is going to be a minimum of photos to be able
                to proceed with the registration.
            </div>
            <FileInputButton
                title="Upload photos"
                multiple
                style={{
                    ...styles.mainButton,
                    minWidth: 150,
                    borderRadius: 100,
                    height: "auto",
                    padding: 12,
                    marginBottom: 25,
                }}
                handleFile={(files) => {
                    addPhoto(files)
                }}
            />
            {imageAmount === 0 ? (
                <div style={styles.warning} className="p-4">
                    <RiErrorWarningLine
                        style={{ height: 35, width: 35, color: Colors.primary }}
                        className="mb-1"
                    />
                    You need at least three photos to proceed as a model.
                </div>
            ) : (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                        height: "100%",
                        width: "100%",
                        alignItems: "center",
                    }}
                >
                    {uploadedImages.map((obj) => (
                        <UploadPhotoTile
                            key={obj.id}
                            data={obj}
                            onCloseHandler={(id) => {
                                removeImage(id);
                            }}
                            onTitleChange={(title) => {
                                uploadedImages.find((image) => image.id === obj.id).title =
                                    title;
                            }}
                        />
                    ))}
                </div>
            )}
            {/* <UploadPhotoTile data={{ id: 1, image: profile }} onCloseHandler={() => { console.log("close") }} />
            <UploadPhotoTile data={{ id: 2, image: profile }} onCloseHandler={() => { console.log("close") }} /> 
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                }}
                className="my-4"
            >
                {uploadedImages.length > 0 && uploadedImages.length < 3 ? (
                    <div style={{ fontSize: 9, color: "red" }}>
                        You need at least 3 images
                    </div>
                ) : (
                    <></>
                )}
                {!titleMissing ? (
                    <></>
                ) : (
                    <div style={{ fontSize: 9, color: "red" }}>
                        Photos need to have a title!
                    </div>
                )}
                <Button
                    style={{
                        ...styles.mainButton,
                        width: 150,
                        borderRadius: 100,
                        height: "auto",
                        padding: 12,
                    }}
                    disabled={uploadedImages.length < 3 ? true : false}
                    onClick={() => {
                        if (validated()) props.buttonHandler("photos", data);
                    }}
                >
                    Confirm
                </Button>
            </div>
             */}
    </div>
  );
};

export default PhotosUpload;
