import { useHistory } from "react-router-dom"
import TitleBar from "../../components/Mobile/TitleBar"
import Colors from "../../constants/Colors"
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import NotificationCard from "../../components/Mobile/Notifications/NotificationCard"
import { useEffect, useState } from "react"
import { retrieveNotifications, updateNotification } from "../../store/slices/NotificationSlice"
import { useDispatch } from "react-redux"
import UserPhotoInfo from "../../components/Mobile/Photos/UserPhotoInfo"
import TabBar from "../../components/Mobile/TabBar"

const styles = {
    container: {
        position: 'relative',
        height: '100%',
        width: '100%',
        maxWidth: 500,
        alignSelf: 'center'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 24
    },
    text: {
        fontSize: 15,
        fontWeight: 400,
    }
}


const NotificationPage = () => {
    const [data, sestData] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {

        const updateToRead = (notificationId,type) => {
            const payload = {
                notificationId,
                payload: {
                    type,
                    status: 'read'
                }
            }
            dispatch(updateNotification(payload))
                .unwrap()
                .then((originalPromiseResult) => {
                    console.log(originalPromiseResult.body.data)
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                })
        }

        const fetchNotifications = () => {
            dispatch(retrieveNotifications({ type: 'user' }))
                .unwrap()
                .then((originalPromiseResult) => {
                    sestData(originalPromiseResult.body.data)
                    originalPromiseResult.body.data
                        .filter(item => item.status === "new")
                        .forEach(item => updateToRead(item._id,item.type))
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                })
        }
        fetchNotifications()
    }, [dispatch])

    console.log(data)
    const history = useHistory()
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <TitleBar comesFrom="specific" title="Notifications" unread ={0}/>
            <div style={styles.container} className="mt-3 ms-1">
                {data.length !== 0 && data.sort((a,b)=>Date.parse(b.createdAt) - Date.parse(a.createdAt)).map((item, index) =>
                    <NotificationCard key={index} data={item} />
                )}
            </div>
            <TabBar/>
        </div>
    )
}

export default NotificationPage;