import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER } from "../../../store/slices/UserSlice";
import { GiHanger, GiHairStrands } from "react-icons/gi";
import { BsEye } from "react-icons/bs";
import { BsCamera } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { ReactComponent as MUA } from "../../../assets/MUA.svg";
import Colors from "../../../constants/Colors";
import countryList from "react-select-country-list";
import Select from "react-dropdown-select";
import MoreButton from "../../../assets/Freccia.png";
import fieldIcon from "../../../assets/fieldIcon.png";
import locationIcon from "../../../assets/Map pin IC.png";
import alert from "../../../assets/Alert 2.png";
import photographer from "../../../assets/photographer.png";
import model from "../../../assets/model.png";
import hairStylist from "../../../assets/HairStylist.png";
import makeupArtist from "../../../assets/MakeupArtist.png";
import stylist from "../../../assets/stylist.png";
import photographerWhite from "../../../assets/photographerWhite.png";
import modelWhite from "../../../assets/modelWhite.png";
import hairStylistWhite from "../../../assets/HairStylistWhite.png";
import makeupArtistWhite from "../../../assets/MakeupArtistWhite.png";
import stylistWhite from "../../../assets/stylistWhite.png";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';


let styles = {
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "90%",
    backgroundColor: "white",
    overflow: "hidden",
  },
  labels: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: "left",
    width: "80%",
  },
  input: {
    background: "#FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "3px",
    opacity: 0.4,
    width: "95%",
    margin: "auto",
  },
  mainButton: {
    color: "white",
    borderRadius: 15,
    backgroundColor: Colors.primary,
    fontSize: 12,
    minWidth: 135,
    margin: 10,
    height: 70,
  },
  buttonContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    justifyContent: "center",
  },
  locationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "85%",
  },
  dropdown: {
    background: " #FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    bordeRadius: "2px",
    zIndex: 102,
    width: "100%",
    opacity:0.4
  },
  dropdownContainer: {
    width: "50%",
  },
  commonFlexContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
    background: "#FFFFFF",
    border: "1px solid #E8E9F3",
    boxSizing: "border-box",
    borderRadius: "2px",
    margin: "auto",
  },
};

const AccountInfo = (props) => {
  const user = useSelector((state) => state.user.user);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [username, setUsername] = useState(user.username);
  //const [country, setCountry] = useState(user.country);
  const [city, setCity] = useState(user.city);
  const [occupation, setOccupation] = useState("");
  const [validated, setValidated] = useState(true);
  const dispatch = useDispatch();
  const [active, setActive] = useState("");
  const [country,setCountry]=useState("");

  const _handleChange = (event) => {
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        break;
      case "pass":
        setPassword(event.target.value);
        break;
      case "username":
        setUsername(event.target.value);
        break;
      case "country":
        setCountry(event.target.value);
        break;
      case "city":
        setCity(event.target.value);
        break;
      default:
        break;
    }
  };

  const buttonAction = (e) => {
    setActive(e.target.id);
    setOccupation(e.target.id);
  };

  const professions = [
    {
      title: "Photographer",
      image: active === "Photographer" ? photographerWhite : photographer,
    },
    { title: "Stylist", image: active === "Stylist" ? stylistWhite : stylist },
    {
      title: "Hair Stylist",
      image: active === "Hair Stylist" ? hairStylistWhite : hairStylist,
    },
    { title: "Model", image: active === "Model" ? modelWhite : model },
    {
      title: "Makeup Artist",
      image: active === "Makeup Artist" ? makeupArtistWhite : makeupArtist,
    },
  ];

  useEffect(() => {
    dispatch(
      SET_USER({
        email,
        password,
        username,
        country,
        city,
        occupation,
      })
    );
  }, [email, password, username, country, city, occupation, dispatch]);

  const CountryOptions = useMemo(() => countryList().getData(), []);

  const validate = () => {
    console.log({
      email,
      username,
      country,
      city,
      occupation,
    })
    if (!email || !username || !country || !city) {
      setValidated(false);
      return false;
    }
    return true;
  };

  const onSumbit = (e) => {
    e.preventDefault();
    if (validate()) props.buttonHandler("jobs", user);
  };

  return (
    <div style={{ width: "100%" }} id="none" onClick={buttonAction}>
      <div
        className="mt-4"
        style={{
          ...styles.commonFlexContainer,
          maxWidth: 500,
        }} /* onSubmit={onSumbit} */
      >
        {validated ? (
          <></>
        ) : (
          <div style={{ fontSize: 9, color: "red" }}>
            You need to fill all the fields!
          </div>
        )}
        <div className="d-flex w-100 p-2 ps-3 ms-4 mt-2">
          <img
            className="align-self-center me-2"
            src={fieldIcon}
            alt="fieldIcon"
          />
          <div style={styles.labels}>User information</div>
        </div>

        <div className="mb-3" style={styles.form}>
          <input
            type="text"
            id="username"
            placeholder="Username"
            style={styles.input}
            className="p-2 ps-3"
            onChange={_handleChange}
            required
          />
        </div>
        <div style={styles.form}>
          <input
            type="email"
            id="email"
            placeholder="Email"
            style={styles.input}
            className="p-2 ps-3"
            onChange={_handleChange}
            required
          />
        </div>
        {/* <div style={styles.labels}>Password</div>
            <div style={styles.form}>
                <input
                    type='password'
                    pattern='^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$'
                    id='pass'
                    placeholder='password'
                    style={styles.input}
                    className='p-2'
                    onKeyUp={_handleChange}
                    required
                />
            </div> */}

        <div className="d-flex w-100 p-2 ps-3 ms-4 mt-2">
          <img
            className="align-self-center me-2"
            src={locationIcon}
            alt="fieldIcon"
          />
          <div style={styles.labels}>Location</div>
        </div>

        <div className="pt-1 me-2 mb-3" style={styles.locationContainer}>
          <div className="me-3" style={styles.dropdownContainer}>
            {/** 
            <Select
              options={CountryOptions}
              className="p-1"
              dropdownHeight="200px"
              dropdownGap={1}
              clearOnBlur
              style={styles.dropdown}
              placeholder="Country"
              onChange={(value) => setCountry(value[0]?.label ?? "")}
            />*/}
            <CountryDropdown
              value={country}
              defaultOptionLabel="Country"
              className="p-1"
              id="country"
              onChange={(val)=>(setCountry(val))}
              style={styles.dropdown}
            />
           
          </div>
          <div style={styles.dropdownContainer}>
            {/** 
            <Select
              options={CountryOptions}
              className="p-1"
              dropdownHeight="200px"
              dropdownGap={1}
              clearOnBlur
              style={styles.dropdown}
              placeholder="City"
              onChange={(value) => setCountry(value[0]?.label ?? "")}
            />*/}
             <RegionDropdown
              country={country}
              className="p-1"
              id="city"
              value={city}
              onChange={(val)=>(setCity(val))}
              style={styles.dropdown}
              defaultOptionLabel="City"
            />
          </div>
        </div>
      </div>

      <div style={{ margin: "auto" }}>
        <div className="d-flex mt-3">
          <div className="ms-4 me-2">
            <img src={alert} alt="alert" />
          </div>
          <div className="align-self-center" style={{ fontSize: "14px" }}>
            Select your profession below.
          </div>
        </div>
        <div className="ms-4 ps-4" style={{ fontSize: "12px", width: "90%" }}>
          If you are not a professional, you can skip this process and be
          assigned the “Observer” status.
        </div>
      </div>
      <div className="mt-4" style={styles.buttonContainer}>
        <div style={{ width: "50%" }} className="text-center ps-3">
          {professions.slice(0, 3).map((profession) => (
            <Button
              onClick={buttonAction}
              variant="secondary"
              id={profession.title}
              style={{ color: "#101935", fontWeight: 300, fontSize: 15 }}
              className="role buttonLogin ps-3 pe-3 mb-3 ms-1"
            >
              <img
                className="align-self-center me-2"
                src={`${profession.image}`}
                alt={`profession.title`}
              />
              {profession.title}
            </Button>
          ))}
        </div>

        <div style={{ width: "50%" }} className="text-center pe-3">
          {professions.slice(3).map((profession) => (
            <Button
              variant="secondary"
              onClick={buttonAction}
              id={profession.title}
              style={{ color: "#101935", fontWeight: 300, fontSize: 15 }}
              className="role buttonLogin me-1 ps-3 pe-3 mb-3"
            >
              <img
                className="align-self-center me-2"
                src={`${profession.image}`}
                alt={`profession.title`}
              />
              {profession.title}
            </Button>
          ))}
        </div>
        {/** 
        <Button
          type="button"
          style={{
            ...styles.mainButton,
            opacity: occupation === "Model" ? 1 : 0.5,
          }}
          className="px-1 py-3"
          onClick={() => {
            setOccupation("Model");
          }}
        >
          <IoPersonOutline style={{ height: 20, width: 20 }} />
          <br></br>
          Model
        </Button>
        <Button
          type="button"
          style={{
            ...styles.mainButton,
            opacity: occupation === "Photographer" ? 1 : 0.5,
          }}
          className="px-1 py-3"
          onClick={() => {
            setOccupation("Photographer");
          }}
        >
          <BsCamera style={{ height: 20, width: 20 }} />
          <br></br>
          Photographer
        </Button>
      </div>
      <div style={styles.buttonContainer}>
        <Button
          type="button"
          style={{
            ...styles.mainButton,
            opacity: occupation === "MUA" ? 1 : 0.5,
          }}
          className="px-1 py-3"
          onClick={() => {
            setOccupation("MUA");
          }}
        >
          <MUA style={{ height: 20, width: 20 }} />
          <br></br>
          MUA
        </Button>
        <Button
          type="button"
          style={{
            ...styles.mainButton,
            opacity: occupation === "Stylist" ? 1 : 0.5,
          }}
          className="px-1 py-3"
          onClick={() => {
            setOccupation("Stylist");
          }}
        >
          <GiHanger style={{ height: 20, width: 20 }} />
          <br></br>
          Stylist
        </Button>
        */}
      </div>
      {/** 
      <div style={styles.buttonContainer}>
        <Button
          type="button"
          style={{
            ...styles.mainButton,
            opacity: occupation === "Hair stylist" ? 1 : 0.5,
          }}
          className="px-1 py-3"
          onClick={() => {
            setOccupation("Hair stylist");
          }}
        >
          <GiHairStrands style={{ height: 20, width: 20 }} />
          <br></br>
          Hair stylist
        </Button>
        <Button
          type="button"
          style={{
            ...styles.mainButton,
            opacity: occupation === "Observer" ? 1 : 0.5,
          }}
          className="px-1 py-3"
          onClick={() => {
            setOccupation("Observer");
          }}
        >
          <BsEye style={{ height: 20, width: 20 }} />
          <br></br>
          Observer
        </Button>
      </div>
      */}
      <div className="text-center mt-4">
        <Button
          type="button"
          id="proceed"
          variant="primary"
          style={{ background: "#e8e9f3", width: "30%" }}
          disabled={active !== "" ? false : true}
          className="buttonLogin text-center p-2"
          onClick={onSumbit}
        >
          {active !== "" ? "Next" : "Skip"}
        </Button>
      </div>
    </div>
  );
};

export default AccountInfo;
