import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import TitleBar from "../../components/Mobile/TitleBar"
import Colors from "../../constants/Colors"
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { useState } from "react"
import Navigation from "../../components/Navbar/Navbar"



const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        maxWidth: 500,
        alignSelf: 'center'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 24
    },
    text: {
        fontSize: 15,
        fontWeight: 400,
    }, mainButton: {
        color: 'white',
        borderRadius: 25,
        backgroundColor: Colors.primary,
        fontSize: 14,
        width: 165,
        height: 50,
        margin: 10,
    },
    input: {
        border: "2px solid #F2ECFF",
        fontSize: 18,
        outline: 'none',
        width: '100%',
        height: 60,
        boxShadow: '0px 3px 12px rgba(157, 118, 240, 0.1)',
        borderRadius: 10
    },
}


const ForgotPassword = () => {
    const [email, setEmail] = useState("")


    const history = useHistory()
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>


          
                    <TitleBar />
            
            <div style={styles.title} className="mt-4 mx-3">
                <IoArrowBackCircleOutline style={{ height: 30, width: 30, color: Colors.primary, marginRight: 10,cursor:'pointer' }} onClick={() => history.goBack()} />
                Password Recovery
            </div>
            <div className="m-0 px-4 mt-4" style={styles.container}>
                <div style={styles.text} className="mt-4">
                    Enter the email associated to your account to start the procedure.
                </div>
                <input type="email" value={email} onChange={event => setEmail(event.target.value)} placeholder="example@example.com" style={styles.input} className="p-3 mt-5" />

                <Button as="input" type="button" className="mt-5" value="Reset password" style={styles.mainButton} onClick={() => history.replace("/reset-password")} />
            </div>
        </div>
    )
}

export default ForgotPassword;