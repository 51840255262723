import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createEvent = createAsyncThunk(
  "event/createEvent",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.EventService.createEvent( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveEvents = createAsyncThunk(
  "event/retrieveEvents",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.EventService.retrieveEvents(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveEvent = createAsyncThunk(
  "event/retrieveEvent",
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await services.EventService.retrieveEvent(eventId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "event/deleteEvent",
  async (eventId, { rejectWithValue }) => {
    try {
      const response = await services.EventService.deleteEvent(eventId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateEvent = createAsyncThunk(
  "event/updateEvent",
  async (eventId,payload,{ rejectWithValue }) => {
    try {
      const response = await services.BadgeService.updateEvent(eventId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state:"",
  error: {},
};

const EventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    ACTION(state,action) {
    },
  },
  extraReducers: {
    /* ---- CREATE_EVENT ---- */
    [String(createEvent.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createEvent.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createEvent.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_EVENTS ---- */
    [String(retrieveEvents.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveEvents.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveEvents.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_EVENT ---- */
    [String(retrieveEvent.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveEvent.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveEvent.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_EVENT ---- */
    [String(updateEvent.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateEvent.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateEvent.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_EVENT---- */
    [String(deleteEvent.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteEvent.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteEvent.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION} = EventSlice.actions;

export default EventSlice.reducer;
