import TitleBar from "../../components/Mobile/TitleBar";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import TabBar from "../../components/Mobile/TabBar";
import { Col, Button } from "react-bootstrap";
import { useState, useEffect,useRef } from "react";
import Alert from "../../assets/Alert.png";
import GalleryCard from "../../components/GalleryCard/GalleryCard";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultPic from "../../assets/user.svg";
import galleryIcon from "../../assets/Gallery icon 2.png";
import uploadIcon from "../../assets/Upload IC.png";

import {
  createGallery,
  SET_COMESFROM,
  SET_GALLERY_NAME,
  DELETE_PHOTOS,
} from "../../store/slices/GallerySlice";
import { createPhoto } from "../../store/slices/PhotoSlice";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";
import Navigation from "../../components/Navbar/Navbar";
import FileInputButton from "../../components/Mobile/Login-Register/FileInputButton";
import { useTranslation } from "react-i18next";

const styles = {
  main__grid: {
    borderRadius: 10,
    backgroundColor: "#F2ECFF",
    width: "95%",
  },
  location__text: {
    color: "rgba(29, 13, 63, 0.6)",
    fontSize: 9,
  },
  field__text: {
    fontSize: 12,
  },
  edit__button: {
    color: "white",
    borderRadius: "100px",
    fontSize: 12,
    maxWidth: "10rem",
  },
  change__image: {
    borderRadius: 8,
    fontSize: 12,
    position: "relative",
    top: -40,
    left: -55,
  },
  backImage: {
    position: "absolute",
    top: -100,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  input: {
    background: "#FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "3px",
    opacity: 0.4,
    width: "90%",
  },
  image: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const NewGalleryPage = () => {
  const selectedPhotos = useSelector((state) => state.gallery.photos);
  const galleryName = useSelector((state) => state.gallery.galleryName);
  const user = useSelector((state) => state.user.user);
  const [images, setImages] = useState(selectedPhotos);
  const [name, setName] = useState(galleryName);
  const [description,setDescription] = useState("");
  const [title,setTitle] = useState("");
  const dispatch = useDispatch();
  const [counter,setCounter] = useState(0);
  const hiddenFileInput = useRef(null);
  const [files,setFiles] = useState([]);
  const {t} = useTranslation();
  //const [props,setProps] = useState("sticky");

  const history = useHistory();

  const handleChangeFile = (event) =>{
    const file = event.target.files[0];
    const temp = counter + 1;
    files.push({file:file,index:setCounter(temp),data:{
      image:file,
      title:"",
      description:"",
    }})
  }

  const uploadPhotoGallery = () =>{
    hiddenFileInput.current.click();
  }

  const changeTitle = (e) => {
    setName(e.target.value);
    //dispatch(SET_GALLERY_NAME(e.target.value));
  };

  const changeDescription = (e) => {
    setDescription(e.target.value);
    //dispatch(SET_GALLERY_NAME(e.target.value));
  };


  const handleChange = (e) =>{
    console.log(files)
    switch(e.target.name){
      case "text":
        files[e.target.id].data.title=e.target.value;
        break;
      case "textarea":
        files[e.target.id].data.description=e.target.value;
        break;
      default:
        break;
    }
  } 

  const editArray = (value) => {
    const array = images.filter(function (image) {
      return image.photoId !== value;
    });
    dispatch(DELETE_PHOTOS(array));
  };

  const newGalleryRequest = (e) => {
    e.preventDefault();
    const imageIds = [];
    //let galleryData = {};

    Promise.all(
      files.map((photo) => {
        const data = {
          file: photo.file,
          title: photo.data.title,
          description: photo.data.description
        };
        return dispatch(createPhoto(data))
          .unwrap()
          .then((originalPromiseResult) => {
            console.log(originalPromiseResult.body.data);
            imageIds.push({
              photoId: originalPromiseResult.body.data._id,
              order: 0,
            });
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });
      })
    ).then(() => {
      const galleryData = {
        title: name,
        description: description,
        photos: imageIds,
      };
      dispatch(createGallery(galleryData))
        .unwrap()
        .then((originalPromiseResult) => {
          console.log(originalPromiseResult.body.data);
          dispatch(DELETE_PHOTOS([]));
          dispatch(SET_COMESFROM(""));
          dispatch(SET_GALLERY_NAME(""));
          history.push("/");
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    });
  };

  useEffect(() => {
    setImages(selectedPhotos);
  }, [selectedPhotos]);

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        backgroundColor: "#FAFAFA",
      }}
    >
      <TitleBar title="Profile.TabBar.galleries.New gallery" comesFrom="specific" />

      <div
        style={{ width: "90%", margin: "auto" }}
        className="searchCard mt-3 pt-1"
      >
        <div className="d-flex align-items-center mt-2 mb-3">
          <div>
            <img
              width={45}
              height={45}
              className="rounded-circle circle__profile border border-3 border-white ms-2"
              src={defaultPic}
              alt="user"
            />
          </div>
          <div style={{ lineHeight: 1 }} className="ms-2">
            <h6 style={{ fontSize: 14,fontWeight:400 }}>
              {user.firstName} {user.lastName}
            </h6>
          </div>
        </div>
      </div>
      <div style={{ width: "90%", margin: "auto" }} className="searchCard mt-0">
        <div className="d-flex mb-3 ms-3 ps-1 mt-3">
          <div>
            <img src={galleryIcon} alt="location" />
          </div>
          <div className="align-self-center ms-2" style={{ fontSize: "14px",fontWeight:300}}>
          {t("Profile.TabBar.galleries.New gallery.galleryInfo")}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <input
            className="p-2 ps-3"
            style={styles.input}
            type="text"
            placeholder={t("Profile.TabBar.galleries.New gallery.galleryTitle")}
            onChange={changeTitle}
          />
        </div>
        <div className="ms-3 ps-1 mt-3 mb-4 w-100">
          <textarea
            className="p-2 ps-3"
            style={styles.input}
            placeholder={t("Profile.TabBar.galleries.New gallery.galleryDesc")}
            rows={10}
            onChange={changeDescription}
          />
        </div>
      </div>
      {files.map((file,index)=>
      <div
      style={{ width: "90%", margin: "auto" }}
      className="searchCard mt-4"
    >
        <FileInputButton flag={false} initial={file} styles={styles.image} 
        handleFile={(file) => {
          files[index].file=file;
          console.log(files[index].file)
        }}/>
        <div className="d-flex justify-content-center mt-3 mb-3">
          <input
            className="p-2 ps-3"
            style={styles.input}
            type="text"
            name="text"
            id={index}
            placeholder={t("Profile.TabBar.galleries.New gallery.photoTitle")}
            onChange={handleChange}
          />
        </div>
        <div className="ms-3 ps-1 mt-3 mb-4 w-100">
          <textarea
            className="p-2 ps-3"
            style={styles.input}
            name="textarea"
            onChange={handleChange}
            id={index}
            placeholder={t("Profile.TabBar.galleries.New gallery.photoDesc")}
            rows={10}
          />
        </div>
      </div>)}
      {files.length !=0 && <div className='text-center'>
          <Button className='buttonLogin mt-5 pt-2 pb-2 ps-4 pe-4 mb-4'
          disable={title!="" && description!="" ? false :true}
          onClick={newGalleryRequest}>
            {t("Profile.TabBar.galleries.New gallery.confirm")}
          </Button>
        </div>}

      <div className="w-100 text-center mt-3 m-auto">
        <div
          style={{
            background: "#E8E9F3",
            borderRadius: "100px",
            width: "65px",
            height: "65px",
            position: "relative",
            margin:"auto"
          }}
          onClick={ uploadPhotoGallery}
        >
          <img
            width={22}
            style={{
              height: "auto",
              position: "absolute",
              top: 0,
              bottom: 0,
              left:0,
              right:0,
              margin: "auto",
            }}
            src={uploadIcon}
            alt="upload"
          />
        </div>
        <input
        accept="image/*"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChangeFile}
        onClick={(event) => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
      />
      </div>

      {/*<div style={{ position: 'relative' }}>

        <ProfileHeader />
        <BackGroundShapes
          className='d-none d-md-block d-lg-block'
          style={styles.backImage}
          hideShapes
        />
      </div>
      <h2 className='text-center fw-bold mt-3'>New Gallery</h2>
      <Col className='mt-4'>
        <div style={{ display: "block", margin: "auto", width: "87%" }}>
          <h6>Title</h6>
        </div>
        <input
          type='text'
          xs={10}
          placeholder='Gallery name'
          id='galleryName'
          name='title'
          value={galleryName}
          onChange={handleChange}
          className='border__card title__input p-3 pt-3 pb-3 '
        />
      </Col>
      <Col className='mt-4 text-center'>
        <Link to='newGallery/newPhoto'>
          <Button
            style={styles.edit__button}
            variant='primary'
            className='w-50 pt-3 pb-3 fw-light'
            onClick={() => {
              dispatch(SET_COMESFROM("gallery"));
            }}
          >
            Upload photos
          </Button>
        </Link>
      </Col>

      <Col>
        {images && images.length !== 0 ? (
          <div
            className='text-center mt-4 p-4'
          >
            {images.map((image) => {
              return (
                <GalleryCard
                  setParentValue={(value) => {
                    editArray(value);
                  }}
                  image={image}
                />
              );
            })}
            <div className='text-center'>
              {images.length >= 2 && galleryName !== "" ? (
                <Button
                  style={styles.edit__button}
                  variant='primary'
                  className='w-50 pt-3 pb-3 mt-5 fw-light'
                  onClick={newGalleryRequest}
                >
                  Confirm
                </Button>
              ) : (
                <Button
                  style={styles.edit__button}
                  variant='primary'
                  className='w-50 pt-3 pb-3 mt-5 fw-light'
                  onClick={newGalleryRequest}
                  disabled
                >
                  Confirm
                </Button>
              )}
            </div>
          </div>
        ) : (
          <Col className='text-center mt-5'>
            <img src={Alert} alt='exclamation' />
            <div
              style={styles.field__text}
              className='d-flex justify-content-center mt-2'
            >
              <div className='w-50'>
                Upload at least two photos to create a new gallery
              </div>
            </div>

            <Button
              style={styles.edit__button}
              variant='primary'
              className='w-50 pt-3 pb-3 mt-5 fw-light'
              disabled
            >
              Confirm
            </Button>

          </Col>
        )}
      </Col>
      
        <TabBar props={images.length!=0 ?"sticky" : "absolute" }/>
      */}
      <TabBar />
    </div>
  );
};

export default NewGalleryPage;
