import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../../constants/Colors";
import { retrieveUser, updateUser } from "../../../store/slices/UserSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { Button } from "react-bootstrap";
import TextField from "../TextField/TextField";

const styles = {
  description: {
    backgroundColor: Colors.primary,
    color: Colors.secondary,
    borderRadius: 10,
    fontSize: 15,
    maxWidth: 500,
    minHeight: 100,
    fontWeight: "lighter",
    width: "100%",
  },
  bioFieldContainer: {
    height: 40,
    borderBottom: `2px solid ${Colors.secondary}`,
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 500,
    width: "100%",
  },
  mainButton: {
    color: "white",
    borderRadius: 15,
    backgroundColor: Colors.primary,
    fontSize: 12,
    minWidth: 135,
    margin: 10,
    height: 70,
  },
  input: {
    border: "2px solid #F2ECFF",
    outline: "none",
    boxShadow: "0px 3px 12px rgba(157, 118, 240, 0.1)",
    borderRadius: 10,
    textAlign: "right",
    width: 150,
  },
};

const Bio = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [occupation, setOccupation] = useState("");
  const mountedRef = useRef(true);

  const userId = useSelector((state) => state.user.userId);
  const user = useSelector((state) => state.user.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);

  //const conditionalData = !props.add ? props.person : data;

  const dispatch = useDispatch();

  const fetchUser = useCallback(() => {
    if (mountedRef.current) setLoading(true);

    dispatch(
      retrieveUser(props.comesFrom === "selectedUser" ? selectedUserId : userId)
    )
      .unwrap()
      .then((originalPromiseResult) => {
        if (!mountedRef.current) return null;
        setData(originalPromiseResult.body.data);
        setFirstName(originalPromiseResult.body.data?.firstName ?? "");
        setLastName(originalPromiseResult.body.data?.lastName ?? "");
        setAge(originalPromiseResult.body.data?.age ?? "");
        setCity(originalPromiseResult.body.data?.city ?? "");
        setCountry(originalPromiseResult.body.data?.country ?? "");
        setEmail(originalPromiseResult.body.data?.email ?? "");
        setOccupation(originalPromiseResult.body.data?.occupation ?? "");
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {
        if (mountedRef.current) setLoading(false);
      });
  }, [dispatch, userId, props.comesFrom, selectedUserId]);

  useEffect(() => {
    fetchUser();
    return () => {
      mountedRef.current = false;
    };
  }, [fetchUser]);

  let bioFields = [
    {
      info: "Age",
      value: "25",
    },
    {
      info: "Gender",
      value: "Male",
    },
    {
      info: "Pronouns",
      value: "He/Him",
    },
    {
      info: "TFP work",
      value: "No",
    },
    {
      info: "Specialities",
      value: ["Fashion shows", "Catwalks", "Advertising"],
    },
    {
      info: "Travel availability",
      value: "Yes",
    },
  ];

  const _handleChange = (name, value) => {
    switch (name) {
      case "First Name":
        setFirstName(value);
        break;
      case "Last Name":
        setLastName(value);
        break;
      case "Age":
        setAge(value);
        break;
      case "Country":
        setCountry(value);
        break;
      case "City":
        setCity(value);
        break;
      case "Occupation":
        setOccupation(value);
        break;
      case "Email":
        setEmail(value);
        break;
      default:
        break;
    }
  };

  const updateInfo = () => {
    let payload = {
      userId,
      info: {
        ...user,
        firstName,
        lastName,
        occupation,
        email,
        city,
        country,
      },
    };
    dispatch(updateUser(payload))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  return (
    <TextField comesFrom="Profile.TabBar.bio.fields" fieldsArray={bioFields}/>
  );
};

{
  /*
        <div className="mt-5 px-4 pt-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ClipLoader color={Colors.primary} loading={loading} size={50} css={{ marginTop: 20 }} />
            </div>
            {
                bioFields.map((item, index) =>
                    <div key={index} style={styles.bioFieldContainer} className=" mx-5">
                        <div>{item.info}</div>
                        
                        {
                            editMode ?
                                <input type="text" className="px-2" style={styles.input} value={item.value} onChange={(event) => _handleChange(item.info, event.target.value)} />
                                :
                                item.info === "Specialities" ? item.value.map((speciality)=>  <div className="d-block"><div style={{ fontWeight: 'bold' }}>{speciality ? speciality : "Unknown"}</div></div>) : 
                                <div style={{ fontWeight: 'bold' }}>{item.value ? item.value : "Unknown"}</div>
                        }
                    </div>
                )
            }
            <Button
                type='button'
                style={{
                    ...styles.mainButton,
                    minWidth: 165,
                    borderRadius: 25,
                    height: 45,
                }}
                className='px-1 py-2 my-4'
                onClick={() => {
                    if (editMode) updateInfo()

                    setEditMode(!editMode)
                }}
            >
                {editMode ? "Save" : "Edit"}
            </Button>
            <div className="mt-4">
            </div>
        </div>
            */
}

export default Bio;
