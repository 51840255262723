import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import TitleBar from "../../components/Mobile/TitleBar"
import Colors from "../../constants/Colors"
import { useState } from "react"
import { IoArrowBackCircleOutline } from 'react-icons/io5'
import { FiPhoneCall } from 'react-icons/fi'
import InputMask from "react-input-mask";
import CongratulationPage from "./CongratulationPage"
import TabBar from "../../components/Mobile/TabBar"
import Navigation from "../../components/Navbar/Navbar"

const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        maxWidth: 500,
        alignSelf: 'center'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 24
    },
    text: {
        fontSize: 15,
        fontWeight: 400,
    }, mainButton: {
        color: 'white',
        borderRadius: 25,
        backgroundColor: Colors.primary,
        fontSize: 14,
        width: 165,
        height: 50,
        margin: 10,
    },
    input: {
        border: "2px solid #F2ECFF",
        fontSize: 18,
        outline: 'none',
        width: '100%',
        height: 60,
        boxShadow: '0px 3px 12px rgba(157, 118, 240, 0.1)',
        borderRadius: 10
    }
}


const PhoneVerification = () => {

    const [phone, setPhone] = useState("");
    const [send, setSend] = useState(false);
    const [pin, setPin] = useState("");
    const [done, setDone] = useState(false);
    const history = useHistory()

    const sendPin = () => {
        setSend(true)
    }
    const submit = () => {
        setDone(true)
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            
                    <TitleBar />
            
            {
                !done ?
                    <>
                        <div style={styles.title} className="mt-4 mx-4">
                            <IoArrowBackCircleOutline style={{ height: 30, width: 30, color: Colors.primary, marginRight: 10,cursor:'pointer' }} onClick={() => history.goBack()} />
                            Phone Verification
                        </div>
                        <div className="m-0 px-4" style={styles.container}>

                            <div style={styles.text} className="my-4">
                                Insert your phone number in the box below. You will receive a message with a code.<br></br>
                                Placeholder text for some other information about this form.
                            </div>
                            <div style={{ ...styles.input, display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', alignItems: 'center' }}>
                                <FiPhoneCall style={{ height: 30, width: 30, color: Colors.primary }} className="mx-2" />
                                {/*<Select
                                    options={PHONE_CODES.map((code,index)=>{return {...code,key:index}})}
                                    dropdownHeight='250px'
                                    dropdownGap={1}
                                    clearOnBlur
                                    style={{display:'flex',alignItems:'center',width:'auto',fontSize:14,height:60,border:'none',outline:'none',boxShadow:'none'}}
                                    placeholder='CC'
                                    onChange={(value) => console.log(value[0]?.label ?? "")}
                                    dropdownHandleRenderer={() => (
                                        <img src={MoreButton} height={20} width={20} alt='more' />
                                    )}
                                /> 
                                */}
                                <InputMask
                                    mask='(+99) 999 999 9999'
                                    /* mask='999 999 9999' */
                                    value={phone}
                                    placeholder="Phone number"
                                    onChange={event => setPhone(event.target.value)}
                                    style={{ width: '100%', backgroundColor: 'transparent', outline: 'none', border: 'none', boxShadow: 'none' }}
                                    className="p-2"
                                    id="phone"
                                    maskChar=" "
                                />
                            </div>
                            {
                                send &&
                                <div style={{ ...styles.input, display: 'flex', flexDirection: 'row', width: '100%', position: 'relative', alignItems: 'center' }} className="mt-4">
                                    <InputMask
                                        mask='9 9 9 9'
                                        value={pin}
                                        placeholder="Insert Pin"
                                        onChange={event => setPin(event.target.value)}
                                        style={{ letterSpacing: pin ? 15 : 1, fontSize: pin ? 24 : 18, width: '100%', backgroundColor: 'transparent', outline: 'none', border: 'none', boxShadow: 'none', textAlign: pin ? 'center' : 'left' }}
                                        className="p-2"
                                        id="phone"
                                        maskChar="_"
                                    />
                                </div>
                            }
                            <Button as="input" type="button" className="mt-4" value={send ? "Confirm" : "Send"} style={styles.mainButton} onClick={send ? submit : sendPin} />
                            <div className="mt-3" style={{ fontSize: 14, display: 'flex', height: '100%', justifySelf: 'flex-end' }}>Didn’t receive message? <span style={{ color: Colors.primary }}>Try again</span></div>
                        </div>
                    </>
                    :
                    <CongratulationPage level={"bronze"} />
            }
            
                <TabBar props="fixed" />
            
        </div>
    )
}

export default PhoneVerification;