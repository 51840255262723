import swaggerProps from "../plugins/swagger-client";

export default class ProfileService {
  createProfile(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.createProfile(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveProfiles(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.retrieveProfiles(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveProfile(profileId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.retrieveProfile(
          { profileId: profileId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteProfile(profileId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.deleteProfile(
          { profileId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateProfile(profileId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Profile.updateProfile(
          { profileId: profileId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
