import { Col, Container, Row } from "react-bootstrap";
import AlbumCard from "../../Albums/AlbumCard";
import Masonry from "react-masonry-css";
import "./masonry.scss";

/* import Dummy1 from "../../../assets/ExamplePosts/post1.jpg";
import Dummy2 from "../../../assets/ExamplePosts/female.jpg";
import Dummy3 from "../../../assets/ExamplePosts/styles.jpg"; */
import { IoIosAddCircle } from "react-icons/io";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveGalleries } from "../../../store/slices/GallerySlice";
import { Link } from "react-router-dom";
import Colors from "../../../constants/Colors";
import ClipLoader from "react-spinners/ClipLoader";
import addCircle from "../../../assets/addCircle.png";

/* const example_data = [
  {
    id: 1,
    name: "Male",
    image: Dummy1,
  },
  {
    id: 2,
    name: "Female",
    image: Dummy2,
  },
  {
    id: 3,
    name: "Costumes & styling",
    image: Dummy3,
  } , {
    id: 4,
    name: "Costumes & styling",
    image: Dummy3
}, {
    id: 5,
    name: "Costumes & styling",
    image: Dummy3
},
]; */

const breakpointColumnsObj = {
  default: 2,
  1100: 2,
  700: 2,
  500: 2,
};

const AlbumsGrid = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const mountedRef = useRef(true);
  const userId = useSelector((state) => state.user.userId);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);

  const dispatch = useDispatch();

  const fetchGalleries = useCallback(() => {
    if (mountedRef.current) setLoading(true);
    const data = {
      userId: "",
    };

    if (props.comesFrom === "selectedUser") {
      data.userId = selectedUserId;
    } else if (!props.add) {
      data.userId = props.person._id;
    } else {
      data.userId = userId;
    }

    dispatch(retrieveGalleries(data))
      .unwrap()
      .then((originalPromiseResult) => {
        if (!mountedRef.current) return null;
        setData(
          originalPromiseResult.body.data.filter(
            (gallery) => gallery.ownerId === data.userId
          )
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {
        if (mountedRef.current) setLoading(false);
      });
  }, [
    dispatch,
    userId,
    props.add,
    props.person._id,
    props.comesFrom,
    selectedUserId,
  ]);

  useEffect(() => {
    fetchGalleries();
    return () => {
      mountedRef.current = false;
    };
  }, [fetchGalleries]);

  return (
    <Container className="m-0 p-0" fluid>
      <div style={{ justifyContent: "center" }}>
        <ClipLoader
          color={Colors.primary}
          loading={loading}
          size={50}
          css={{ marginTop: 20 }}
        />
      </div>
      <Row className="justify-content-center mt-5 m-0">
        <Col md="auto" className="justify-content-center">
          
            {data
              .filter((gallery) => gallery.ownerId === userId)
              .map((item) => (
                <AlbumCard
                  data={item}
                  height={"auto"}
                  width={"100%"}
                  fontSize={12}
                />
              ))}
         
        </Col>
      </Row>
      {props.add && (
        <Link to="/newGallery">
          <img
              src={addCircle}
              style={{
                color: Colors.primary,
                zIndex: 102,
                position: "fixed",
                bottom: window.innerWidth > 800 ? 60 : 80,
                right: window.innerWidth > 800 ? 60 : 20,
                backgroundColor: "white",
                borderRadius: 25,
                boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
              }}
              alt="AddCircle"
            />
        </Link>
      )}
    </Container>
  );
};

export default AlbumsGrid;
