import { useCallback, useEffect, useRef, useState } from 'react';
import { RiSearchLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import DirectMessage from '../../components/Mobile/DirectMessages/DirectMessage';
import Colors from '../../constants/Colors';
import { retrieveChats } from '../../store/slices/ChatSlice';
import { retrieveUsers } from '../../store/slices/UserSlice';
import ClipLoader from "react-spinners/ClipLoader";


const styles = {
    title: {
        fontSize: 24,
        fontWeight: 600,
        color: Colors.primary
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 60
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    messagesContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    searchBox: {
        display: 'flex',
        width: '100%',
        backgroundColor: Colors.secondary,
        height: 36,
        alignItems: 'center',
        borderRadius: 22
    },
    input: {
        backgroundColor: 'transparent',
        border: 'none',
        outline: 'none',
        paddingLeft: 8,
        borderLeft: `2px solid ${Colors.primary}`,
        width: '100%'
    }
}

const DirectMessegesPage = props => {

    /* const [searchText, setSearchText] = useState("") */
    const userId = useSelector(state => state.user.userId)
    const [searchOn, setSearchOn] = useState(false)
    const [searchText, setSearchText] = useState("")
    const [loading, setLoading] = useState(true)
    const [chats, setChats] = useState([])

    const [users, setUsers] = useState([])
    const mountedRef = useRef(true);

    const dispatch = useDispatch()

    const fetchUsers = useCallback(
        () => {
            dispatch(retrieveUsers())
                .unwrap()
                .then((originalPromiseResult) => {
                    //if (!mountedRef.current) return null;
                    setUsers(originalPromiseResult.body.data.filter(user => user._id !== userId));
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                })
        },
        [dispatch, userId]
    );
    const fetchChats = useCallback(
        () => {
            if (!mountedRef.current)
                setLoading(true)

            dispatch(retrieveChats())
                .unwrap()
                .then((originalPromiseResult) => {
                    if (!mountedRef.current) return null;
                    setChats(originalPromiseResult.body.data.filter(chat => {
                        for (const index in chat.people) {
                            if (chat.people[index].userId === userId) return true;
                        }
                        return false;
                    }));
                    setLoading(false)
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                    setLoading(false)
                })
        },
        [dispatch, userId]
    );

    useEffect(() => {
        fetchChats();
        fetchUsers();
        return () => {
            mountedRef.current = false;
        }
    }, [fetchUsers, fetchChats]);

    return (
        <div style={styles.container} className="px-4 mt-1">
            {
                searchOn ?
                    <div style={styles.headerContainer} className="my-2 mt-3">
                        <div style={styles.searchBox} className="px-2">
                            <RiSearchLine style={{ height: 20, width: 20 }} className="mx-2" />
                            <input placeholder="Search" value={searchText} onChange={event => setSearchText(event.target.value)} style={styles.input} autoFocus />
                        </div>
                        <div className="mx-2 text-muted" style={{cursor:'pointer'}} onClick={() => setSearchOn(false)}>Cancel</div>
                    </div>
                    : <></>  }
                    {/*
                    <div style={styles.headerContainer} className="my-2 mt-0">
                        <div onClick={() => setSearchOn(true)}>
                            <RiSearchLine style={{ height: 25, width: 25,cursor:'pointer' }} onClick={() => setSearchOn(true)} />
                        </div>
                    </div>*/}

           
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader color={Colors.primary} loading={loading} size={50} css={{ marginTop: 20 }} />
            </div>
            {
                !loading && searchOn ?
                    <div style={styles.messagesContainer}>
                        <div style={{ fontSize: 18, fontWeight: 'bold' }} className="mx-2">All</div>
                        {
                            users.filter(user => (!searchText || (user.firstName.toLowerCase().startsWith(searchText) || user.lastName.toLowerCase().startsWith(searchText))))
                                .map((user, index) =>
                                    <DirectMessage key={index} noInfo user={user} />
                                )
                        }
                    </div>
                    :
                    <div style={styles.messagesContainer}>
                        {chats.length !== 0 ? chats.sort((a,b)=>Date.parse(b.updatedAt) - Date.parse(a.updatedAt)).map((chat, index) =>
                            <DirectMessage key={index} lastMessage={chat.messages[chat.messages.length - 1]} user={{ _id: chat.people.find(person => person.userId !== userId)?.userId }} />
                        )
                            :
                            <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-3">You have no active chats yet.</div>
                        }
                    </div>
            }
        </div>

    )
}

export default DirectMessegesPage;