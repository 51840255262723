import avatarDefaultImage from "../assets/user.svg";

export const getUserAvatar = (
  user,
  profileIndex = 0,
  avatarSize = "150",
  useDefaultImage = true
) =>
  user?.profiles?.[profileIndex]?.avatar?.sizes?.[avatarSize]?.url ||
  (useDefaultImage ? avatarDefaultImage : "");
