import TitleBar from "../../components/Mobile/TitleBar";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import TabBar from "../../components/Mobile/TabBar";
import { Col, Button } from "react-bootstrap";
import { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createPhoto } from "../../store/slices/PhotoSlice";
import FileInputButton from "../../components/Mobile/Login-Register/FileInputButton";
import { useHistory } from "react-router";
import { SET_PHOTOS } from "../../store/slices/GallerySlice";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";
import Navigation from "../../components/Navbar/Navbar";
import defaultPic from "../../assets/user.svg";
import galleryIcon from "../../assets/Gallery icon 2.png";
import Select from "react-dropdown-select";
import {
  retrieveGalleries,
  createGalleryPhotos,
} from "../../store/slices/GallerySlice";
import retrieveUsers from "../../store/slices/UserSlice";
import { useTranslation } from "react-i18next";

const styles = {
  main__grid: {
    borderRadius: 10,
    backgroundColor: "#F2ECFF",
    width: "95%",
  },
  location__text: {
    color: "rgba(29, 13, 63, 0.6)",
    fontSize: 9,
  },
  field__text: {
    fontSize: 12,
  },
  edit__button: {
    color: "white",
    borderRadius: "100px",
    fontSize: 12,
    maxWidth: "10rem",
  },
  change__image: {
    borderRadius: 8,
    fontSize: 15,
    position: "absolute",
    bottom: 150,
    left: 40,
  },
  backImage: {
    position: "absolute",
    top: -100,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  image: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  input: {
    background: "#FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "3px",
    opacity: 0.4,
    width: "90%",
  },
  dropdown: {
    background: " #FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    bordeRadius: "2px",
    zIndex: 1000,
    color: "#101935",
    opacity: 0.4,
  },
};

const NewPhotoPage = ({ person }) => {
  const [image, setImage] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Select a gallery");
  const inputFile = useRef(null);
  const [description, setDescription] = useState("");
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const history = useHistory();
  const comesFrom = useSelector((state) => state.gallery.comesFrom);
  const user = useSelector((state) => state.user.user);
  const [gallery, setGallery] = useState("");
  const [galleries, setGalleries] = useState([]);
  const [options, setOptions] = useState([]);
  const { t } =useTranslation();

  const handleChange = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleNameChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const setGalleryValue = (e) => {
    console.log(galleries);
    const temp = galleries.filter((single) => single.title === e[0].value);
    console.log(temp[0]);
    setGallery(temp[0]);
  };

  const fetchGalleries = () => {
    dispatch(retrieveGalleries({}))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data);
        setGalleries(originalPromiseResult.body.data);
        setOptions(
          originalPromiseResult.body.data.map((gallery) => {
            return { value: gallery.title, label: gallery.title };
          })
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const sumbitPhoto = () => {
    const data = {
      file: image,
      title: title,
      description: description,
    };

    dispatch(createPhoto(data))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data);
        console.log(gallery);
        const payload = {
          photoId: originalPromiseResult.body.data._id,
          order: 0,
        };

        dispatch(
          createGalleryPhotos({ galleryId: gallery._id, payload: payload })
        )
          .unwrap()
          .then((originalPromiseResult) => {
            console.log(originalPromiseResult.body.data);
            history.push("/");
          })
          .catch((rejectedValueOrSerializedError) => {
            console.log(rejectedValueOrSerializedError);
          });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    fetchGalleries();
    console.log(options);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        backgroundColor: "#FAFAFA",
      }}
    >
      <TitleBar title="Profile.TabBar.photos.New photo" comesFrom="specific" />

      <div
        style={{ width: "90%", margin: "auto",zIndex:49 }}
        className="searchCard mt-3 pt-1"
      >
        <div className="d-flex align-items-center mt-2 mb-3">
          <div>
            <img
              width={45}
              height={45}
              className="rounded-circle circle__profile border border-3 border-white ms-2"
              src={defaultPic}
              alt="user"
            />
          </div>
          <div style={{ lineHeight: 1 }} className="ms-2">
            <h6 style={{ fontSize: 14 }}>
              {user.firstName} {user.lastName}
            </h6>
          </div>
        </div>
        <FileInputButton
          styles={styles.image}
          handleFile={(file) => {
            setImage(file);
          }}
        />
        <div className="d-flex justify-content-center mt-3 mb-3">
          <input
            className="p-2 ps-3"
            style={styles.input}
            type="text"
            placeholder={t("Profile.TabBar.photos.New photo.photoTitle")}
            onChange={handleNameChange}
          />
        </div>
        <div className="ms-3 ps-1 mt-3 w-100">
          <textarea
            className="p-2 ps-3"
            style={styles.input}
            placeholder={t("Profile.TabBar.photos.New photo.photoDesc")}
            rows={10}
            onChange={handleDescriptionChange}
          />
        </div>
        <div className="d-flex mb-3 ms-3 ps-1 mt-3">
          <div>
            <img src={galleryIcon} alt="location" />
          </div>
          <div
            className="align-self-center ms-2"
            style={{ fontSize: "14px", fontWeight: 300 }}
          >
            {t("Profile.TabBar.photos.New photo.gallery")}
          </div>
        </div>
        <div style={{ width: "90%", margin: "auto" }} className="">
          <Select
            className="ps-2"
            styles={styles.dropdown}
            placeholder={t("Profile.TabBar.photos.New photo.selectGallery")}
            value={selectedOption}
            options={options}
            onChange={setGalleryValue}
          />
        </div>

        <div className="text-center" style={{ fontWeight: 500 }}>
          <Button
            onClick={sumbitPhoto}
            className="buttonLogin mt-5 pt-2 pb-2 ps-4 pe-4 mb-4"
            disabled={
              title != "" &&
              description != "" &&
              selectedOption != "" &&
              image != ""
                ? false
                : true
            }
          >
            {t("Profile.TabBar.photos.New photo.confirm")}
          </Button>
        </div>
      </div>

      {/**
      <Col className='mt-4'>
        <div style={{ display: "block", margin: "auto", width: "87%" }}>
          <h6>Title</h6>
        </div>
        <input
          type='text'
          xs={10}
          placeholder='New photo'
          name='title'
          className='border__card title__input p-3 pt-3 pb-3'
          onChange={handleNameChange}
        />
        <input
          type='file'
          id='file'
          ref={inputFile}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </Col>
      <div
        style={image && image.length !== 0 ? {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: 500,
          alignSelf: "center",
        } : {

        }}
      >
        <Col>
          {image && image.length !== 0 ? (
            <div
              className='text-center mt-4 p-4'
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignContent: "center",
                position: "relative",
              }}
            >
              <img
                src={URL.createObjectURL(image)}
                alt='galleryImage'
                width='100%'
              />
              <FileInputButton
                title='Change image'
                style={styles.change__image}
                class='text-white'
                handleFile={(file) => {
                  setImage(file);
                }}
              />
              <div className='text-center'>
                {title ? (
                  <Button
                    style={styles.edit__button}
                    variant='primary'
                    className='w-50 pt-3 pb-3 mt-5 fw-light'
                    onClick={sumbitPhoto}
                  >
                    Confirm
                  </Button>
                ) : (
                  <Button
                    style={styles.edit__button}
                    variant='primary'
                    className='w-50 pt-3 pb-3 mt-5 fw-light'
                    disabled
                  >
                    Confirm
                  </Button>
                )}
              </div>
              {/* <TabBar props='sticky' /> 
            </div>
          ) : (
            <Col className='text-center mt-4'>
              <Col className='text-center'>
                <FileInputButton
                  title='Upload photo'
                  style={styles.edit__button}
                  class='w-50 pt-3 pb-3 fw-light'
                  handleFile={(file) => {
                    setImage(file);
                  }}
                />
              </Col>
              <Button
                style={styles.edit__button}
                variant='primary'
                className='w-50 pt-3 pb-3 mt-5 fw-light'
                disabled
                onClick={sumbitPhoto}
              >
                Confirm
              </Button>
              {/* <TabBar props='absolute' /> 
            </Col>
          )}
        </Col>
      </div>
    */}

      <TabBar />
    </div>
  );
};

export default NewPhotoPage;
