
import { Card } from 'react-bootstrap'
import Colors from '../../../constants/Colors';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillLike } from 'react-icons/ai'
import { createPhotoLike, deletePhotoLike } from '../../../store/slices/PhotoSlice';
import { NotificationBody, sendNotification } from '../../../helpers/NotificationUtils';
import { useTranslation } from 'react-i18next';

const TextField = ({fieldsArray,styles,font,comesFrom}) => {

  const { t } = useTranslation();

    return (
        <div style={{ margin: "auto", width: "80%",fontSize:font===undefined ? "12px" : `${font}px`}} className={`mt-5 px-4 pt-3 text-start ${styles}`}>
        {fieldsArray.map((field) => (
          <div
            style={{ borderBottom: "1px solid #E8E9F3" }}
            className="d-flex justify-content-between"
          >
            <div style={{fontWeight:300}} className="col-7 mb-1 mt-1"> {t(`${comesFrom}.${field.info}`)}</div>
            <div style={{fontWeight:400}} className="col-5 mb-1 mt-1">
              {field.info === "Specialities" ? (
                field.value.map((single) => <div>{single}</div>)
              ) : (
                <div>{field.value}</div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
}

export default TextField;
