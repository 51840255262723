import { useSelector } from "react-redux";
import ProjectJobGrid from "../../components/Mobile/Jobs/ProjectJobGrid";
import ProfileMobile from "../../components/Mobile/Profile/Profile";
import Bio from "../../components/Mobile/Bio/BioPage";
import AlbumsGrid from "../../components/Mobile/Album/AlbumsGrid";
import ProfilePhotosGrid from "../../components/Mobile/Photos/ProfilePhotosGrid";
import TitleBar from "../../components/Mobile/TitleBar";
import TabBar from "../../components/Mobile/TabBar";
import { Link } from "react-router-dom";

const PersonalContentPage = ({ person, profileImage, add }) => {
  const tabActive = useSelector((state) => state.tabState.profileTabName);

  const _contentSwitch = (tabName) => {
    switch (tabName) {
      case "Photos":
        return <ProfilePhotosGrid person={person} add={add} />;
      case "Galleries":
        return <AlbumsGrid person={person} add={add} />;
      case "Bio":
        return <Bio person={person} add={add} />;
      case "Jobs and Projects":
        return <ProjectJobGrid person={person} add={add} private/>;
      default:
        return <></>;
    }
  };

  return (
    <div className='m-0 p-0' style={{ width: "100%" }}>
      {!add &&

        <TitleBar add={add} />}
      <ProfileMobile comesFrom={!add ? "card" : ""} person={person} profileImage={profileImage} />
      {_contentSwitch(tabActive)}
      <div /* style={{height:'100%'}} */>
        {
          !add &&
          <Link to="/">
            <TabBar/>
          </Link>
        }
      </div>

    </div>
  );
};

export default PersonalContentPage;
