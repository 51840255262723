import { useCallback, useEffect, useRef, useState } from "react";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";
import profile from "../../assets/testPicture.jpg";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../constants/Colors";
import { createUser, SET_USER } from "../../store/slices/UserSlice";
import { Link, useHistory } from "react-router-dom";
import { createPhoto } from "../../store/slices/PhotoSlice";
import AccountInfo from "./RegisterSteps/AccountInfo";
import PersonalInfo from "./RegisterSteps/PersonalInfo";
import PhotosUpload from "./RegisterSteps/PhotosUpload";
import PasswordCreate from "./RegisterSteps/PasswordCreate";
import TitleBar from "../../components/Mobile/TitleBar";

let styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        maxWidth: 750,
        justifyContent:'space-between'
    },
    title: {
        fontSize: 24,
        fontWeight: "bold",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    mainButton: {
        color: "white",
        borderRadius: 15,
        backgroundColor: Colors.primary,
        fontSize: 12,
        minWidth: 135,
        margin: 10,
        height: 70,
    },
    backToLogin: {
        textAlign: "center",
        //width: 90,
        textDecoration: "none",
        color: Colors.primary,
        alignSelf: "flex-end",
        /* position: 'absolute',
            bottom: 10,
            right: 20 */
    },
};

const steps = [
    {
        stepNumber: 1,
        stepName: "AccountInfo",
        title:"Create a new Account"
    },
    {
        stepNumber: 2,
        stepName: "PersonalInfo",
        title:"Create a new Account"
    },
    {
        stepNumber: 3,
        stepName: "PhotosUpload",
        title:"Upload some photos"
    },
    {
        stepNumber: 4,
        stepName: "PasswordCreate",
        title:"Create a password"
    },
];

const RegisterPage = (props) => {
    const [currentStep, setCurrentStep] = useState(1);
    let occupation = useRef("");
    let uploadedPhotos = useRef([]);

    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(()=>{
        dispatch(SET_USER("clear"))
    },[dispatch])

    const account = useSelector((state) => state.user.user);

    //Component only renders when the current register step is changed
    const ContentSwitch = useCallback(() => {
        const _nextStep = (keyword, data) => {
            if (keyword && keyword === "jobs") {
                /* data.occupation = "model"; */
                occupation.current = data.occupation;
                if(data.occupation === "Observer"){
                    setCurrentStep(steps.length)
                    return;
                }
            }
            if (keyword && keyword === "photos")
                uploadedPhotos.current = data["photos[]"];

            setCurrentStep(currentStep + 1);
        };

        console.log(currentStep)

        switch (currentStep) {
            case 1:
                return <AccountInfo buttonHandler={_nextStep} />;
            case 2:
                return <PersonalInfo buttonHandler={_nextStep} image={profile} />;
            case 3:
                return <PhotosUpload buttonHandler={_nextStep} />;
            case 4:
                return <PasswordCreate buttonHandler={_nextStep} />;
            default:
                return <></>;
        }
    }, [currentStep]);

    useEffect(() => {
        const saveImage = (userId, file, title) => {
            let data = {
                userId,
                file,
                title,
            };
            dispatch(createPhoto(data))
                .unwrap()
                .then((originalPromiseResult) => {
                    console.log(originalPromiseResult);
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                });
        };

        if (currentStep === steps.length + 1) {
            dispatch(createUser(account))
                .unwrap()
                .then((originalPromiseResult) => {
                    console.log(originalPromiseResult.body.data);
                    let userId = originalPromiseResult.body.data._id;
                    uploadedPhotos.current.forEach(async (item) => {
                        saveImage(userId, item.image, item.title);
                    });
                    history.replace("/login");
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                });
        }
    }, [currentStep, dispatch, account, history]);

    return (
        <div>
            <TitleBar title={currentStep!==4 ?"Registration": "Password creation"} comesFrom={currentStep < 3 ?"specific": ""}/>
            <div style={{ ...styles.container, zIndex: 1,background:" linear-gradient(180deg, #E8E9F3 0%, rgba(232, 233, 243, 0) 64.83%)" }}>
                <ContentSwitch />
                <div style={{ fontSize: 14 }} className="mt-4">
                    Want to sign in? Go
                    <Link style={styles.backToLogin} className="" to="/login">
                        {" "}
                        back to login
                    </Link>
                </div>
            </div>
            </div>
    );
};

export default RegisterPage;
