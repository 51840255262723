import PersonInfo from "../../components/Mobile/Profile/PersonInfo";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import noPictureUser from "../../assets/user.svg";
import BackGroundShapes from "../Mobile/Login-Register/BackGround";
import { getUserAvatar } from "../../helpers/user";

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
    //backgroundImage: `url(${background})`,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    overflow: "hidden",
  },
  backImage: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tab: {
    color: "black",
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    border: "none",
    fontSize: 9,
    boxShadow: "none",
    marginRight: 5,
  },
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginTop: 31,
  },
  actionButtons: {
    display: "flex",
    flexDirection: "row",
  },
  mainButton: {
    color: "white",
    borderRadius: 10,
    backgroundColor: "#9D76F0",
    fontSize: 18,
  },
};

const ProfileHeader = () => {
  const user = useSelector((state) => state.user.user);
  /* const [error, setError] = useState(null); */
  const [img, setImage] = useState("");

  useEffect(() => {
    setImage(getUserAvatar(user));
  }, [user]);

  return (
    <>
      (
      <div className="p-0 m-0 px-2" style={styles.container}>
        {/* <img src={background} style={styles.backImage} alt='background' /> */}
        <BackGroundShapes style={styles.backImage} hideShapes />
        <div style={styles.infoContainer} className="py-3">
          <img
            src={img}
            width={90}
            height={90}
            className="rounded-circle circle__profile border border-3 border-white "
            alt="Profile"
          />
        </div>
        <div className="m-0" style={styles.infoContainer}>
          <PersonInfo person={user} textAlign="left" />
        </div>
      </div>
      )
    </>
  );
};

export default ProfileHeader;
