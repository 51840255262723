import React, { useRef, useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import uploadIcon from "../../../assets/Upload IC.png";
import uploadIconWhite from "../../../assets/Upload IC white.png"

const FileInputButton = (props) => {
  const hiddenFileInput = useRef(null);
  const [flag,setFlag] =useState(false)
  const [image, setImage] = useState(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const fileToDataUri = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
      setImage(file);
    });

  const handleChange = (event) => {
    
    if (props?.multiple ?? false) {
      const files = event.target.files;
      props.handleFile(files);
    } else {
      const file = event.target.files[0];
      fileToDataUri(file)
        .then((data) => {
          props.handleFile(file, data);
        })
        .catch((error) => console.log(error));
    }
    
    const file = event.target.files[0];
    fileToDataUri(file);
  };
  useEffect(() => {
    if(props.initial && !flag){
      setImage(props.initial.file)
      console.log(props.initial.file);
      setFlag(true);
    }
    
  }, [image]);

  return (
    <>
      <div
        style={{
          backgroundSize: "100%",
          backgroundColor: "#E8E9F3",
          position: "relative",
          minHeight: "345px",
        }}
        className="w-100"
      >
        <img onClick={handleClick} style={props.styles} src={props.flag ? uploadIcon : uploadIconWhite} />
        <img style={{width:"100%", height: "auto"}} src={image && URL.createObjectURL(image)}/>
      </div>
      <input
        accept="image/*"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        onClick={(event) => {
          event.target.value = null;
        }}
        style={{ display: "none" }}
        multiple={props?.multiple ?? false}
      />
    </>
  );
};
export default FileInputButton;
