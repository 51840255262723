import TitleBar from "./components/Mobile/TitleBar";

import TabBar from "./components/Mobile/TabBar";
import PersonalContentPage from "./pages/TabPages/PersonalContentPage";
import JobSearchPage from "./pages/TabPages/JobSearchPage";
import UserSearchPage from "./pages/TabPages/UserSearchPage";
import ExplorePage from "./pages/TabPages/ExplorePage";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { retrieveUser, SET_USER } from "./store/slices/UserSlice";
import NotLoggedInPage from "./pages/NotLoggedInPage/NotLoggedInPage";
import DirectMessegesPage from "./pages/TabPages/DirectMessagesPage";
import ClipLoader from "react-spinners/ClipLoader";
import Colors from "./constants/Colors";
import { getAuthToken } from "./helpers/auth";
import { getUserAvatar } from "./helpers/user";


const AppMobile = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userId);
  const [person, setPerson] = useState({});
  const [img, setImage] = useState("");
  const [loading, setLoading] = useState(true);

  const token = getAuthToken();

  const fetchUser = useCallback(() => {
    if (userId !== "" && token) {
      setLoading(true)
      dispatch(retrieveUser(userId))
        .unwrap()
        .then((originalPromiseResult) => {
          const user = originalPromiseResult.body.data;
          setPerson(user);
          dispatch(SET_USER(user));
          setImage(getUserAvatar(user));
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        })
        .finally(()=>{
          setLoading(false)
        });
    } else setLoading(false);
  }, [dispatch, userId]);


  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const tabActive = useSelector((state) => state.tabState.mainTabName);

  const _contentSwitch = (tabName) => {
    switch (tabName) {
      case "search":
        return <UserSearchPage person={person} />;

      case "jobs":
        return <JobSearchPage person={person} />;

      case "home":
        return <ExplorePage />;

      case "send":
        return <DirectMessegesPage />;

      case "profile":
        return <PersonalContentPage add={props.add} person={person} profileImage={img} />;

      default:
        return <div></div>;
    }
  }
  if (loading)
    return (
      <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <ClipLoader color={Colors.primary} loading={true} size={50} css={{ marginTop: 20 }} />
      </div>
    )
  return (
    <div
      className='m-0 p-0'
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        backgroundColor:"#FAFAFA"
      }}
    >
  <TitleBar profile={{person}} title={tabActive}/>
      {(userId === "" || !token) && (tabActive === "profile" || tabActive === "send") ? (
        <div
          style={{
            height: "100%",
            background:
              "linear-gradient(180deg, #E8E9F3 0%, rgba(232, 233, 243, 0) 64.83%)",
          }}
        >
          <NotLoggedInPage />
        </div>
      ) : (
        <div style={{ display: "flex", height: "100%" }}>
          {_contentSwitch(tabActive)}
        </div>
      )}
        <TabBar />
    </div>
  );
};

export default AppMobile;
