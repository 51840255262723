import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createProfile = createAsyncThunk(
  "profile/createProfile",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.createProfile( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveProfiles = createAsyncThunk(
  "profile/retrieveProfiles",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfiles(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveProfile = createAsyncThunk(
  "profile/retrieveProfile",
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.retrieveProfile(profileId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteProfile = createAsyncThunk(
  "profile/deleteProfile",
  async (profileId, { rejectWithValue }) => {
    try {
      const response = await services.ProfileService.deleteProfile(profileId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (profileId,payload,{ rejectWithValue }) => {
    try {
      const response = await services.ProfileService.updateProfile(profileId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state:"",
  error: {},
};

const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    ACTION(state,action) {
    },
  },
  extraReducers: {
    /* ---- CREATE_PROFILE ---- */
    [String(createProfile.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createProfile.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createProfile.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_PROFILES ---- */
    [String(retrieveProfiles.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveProfiles.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveProfiles.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_PROFILE ---- */
    [String(retrieveProfile.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveProfile.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveProfile.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_PROFILE ---- */
    [String(updateProfile.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateProfile.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateProfile.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_PROFILE---- */
    [String(deleteProfile.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteProfile.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteProfile.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION} = ProfileSlice.actions;

export default ProfileSlice.reducer;
