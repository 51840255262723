import AzureGrid from "../../components/AzureGrid/AzureGrid";
import { useState, useEffect, useCallback } from "react";
import TitleBar from "../../components/Mobile/TitleBar";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import locationImage from "../../assets/Vector.png";
import Card from "../../components/Card/Card";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import TabBar from "../../components/Mobile/TabBar";
import { useDispatch, useSelector } from "react-redux";
import { retrieveJob, SET_JOB_ID, updateJob } from "../../store/slices/JobSlice";
import Colors from "../../constants/Colors";
import defaultPhoto from "../../assets/backgroundMobile.jpg";
import Navigation from "../../components/Navbar/Navbar";
import { getJobImage } from "../../helpers/job";

const styles = {
  main__grid: {
    borderRadius: 10,
    backgroundColor: Colors.secondary,
    width: "95%",
  },
  location__text: {
    color: "rgba(29, 13, 63, 0.6)",
    fontSize: 9,
  },
  field__text: {
    color: "#1D0D3F",
    fontSize: 12,
  },
  edit__button: {
    color: "white",
    borderRadius: "100px",
    fontSize: 12,
  },
};

const fieldData = [
  { field: "field 01", data: "Data for this field" },
  { field: "field 02", data: "Data for this field" },
  { field: "field 03", data: "Data for this field" },
  { field: "field 04", data: "Data for this field" },
];
/*
const data = {
  location: "Monza,Lombardia",
  title: "Shooting at twilight on a building rooftop",
  posted: "22/06/2021",
  description:
    "The description for this job will be written here, in detail, as inputted by the owner.This job will entail many thing and they will be explained in this section t is required to have personal equipment to participate to this job or the application will be discarded.It is required to have personal equipment to participate to this job or the application will be discarded.",
};
*/
const JobOverviewPage = ({ person, buttonLabel }) => {
  const [fetchedData, setFetchedData] = useState("");
  const apply = useSelector((state) => state.job.apply);
  //const userId = useSelector((state) => state.user.userId);
  const user = useSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const id = useParams();
  const [img, setImage] = useState("");

  const fetchJob = useCallback(() => {
    dispatch(retrieveJob(id.id))
      .unwrap()
      .then((originalPromiseResult) => {
        const job = originalPromiseResult.body.data 
        setImage(getJobImage(job));
        setFetchedData(job);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, [dispatch, id]);
  console.log(fetchedData)
  const applyJob = useCallback((fetchedData) => {
    dispatch(updateJob({jobId:id.id,data:[...(fetchedData.applicants),user]}))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data)
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, [dispatch, id]);

  useEffect(() => {
    fetchJob();
  }, [fetchJob, id]);

  const onSumbit = () => {
    if (apply && apply === "search") {
      history.push("/");
      //applyJob(fetchedData);
    } else {
      dispatch(SET_JOB_ID(fetchedData._id));
      history.push({
        pathname: `${window.location.href.substr(
          window.location.href.lastIndexOf("/") + 1
        )}/edit`,
      });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <TitleBar />

      <div style={{ width: "100%" }}>
        <ProfileHeader person={person} />
      </div>
      <AzureGrid style={{ maxWidth: 500 }}>
        <div
          className='location__text text-center pt-4'
          style={styles.location__text}
        >
          Job <img src={locationImage} alt='location' /> {fetchedData.city},
          {fetchedData.country}
        </div>
        <h3 className='text-center mt-2'>{fetchedData.title}</h3>
        <div className='text-center' style={styles.location__text}>
          Posted on {fetchedData.createdAt}
        </div>
        <Card data={fetchedData.description} />
        <div xs={12} className='text-center mt-4'>
          <img src={img} style={{ width: 255, height: "auto" }} alt='main' />
        </div>
        <div className='text-center row justify-content-center col-12 mt-3'>
          {fieldData.map((field, index) => {
            return (
              <div
                key={index}
                className='col-10 offset-1 d-flex justify-content-between mt-3 border-bottom border-primary'
                style={styles.field__text}
              >
                <div>{field.field}</div>
                <div>{field.data}</div>
              </div>
            );
          })}
        </div>
        <div className='d-flex justify-content-center mt-5 pb-4'>
          <Button
            style={styles.edit__button}
            variant='primary'
            className='w-50 pt-3 pb-3'
            onClick={onSumbit}
          >
            {apply && apply === "search" ? "Apply" : buttonLabel}
          </Button>
        </div>
      </AzureGrid>

      <TabBar />
    </div>
  );
};

export default JobOverviewPage;
