import { INPUT_UPDATE } from "../actions/register";

const initialState = {
  email: "",
  password: "",
  username: "",
  country: "",
  city: "",
  firstName: "",
  lastName: "",
  phone: "",
  "photos[]": [],
  profil: null,
  occupation: "",
};

const updateRegisterInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case INPUT_UPDATE:
        state = {
            ...action.data
        };
        break;
    default:
      break;
  }
  return state;
};
export default updateRegisterInfoReducer;
