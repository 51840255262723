import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import ExploreGrid from "../../components/Mobile/Explore/ExploreGrid"
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround"
import { retrievePhotos } from "../../store/slices/PhotoSlice"
import ClipLoader from "react-spinners/ClipLoader";



const styles = {
    search: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        alignItems: 'center',
        height: 100
    },
    title: {
        fontWeight: 600,
        fontSize: 30,
        color: 'white'
    },
    commentArea: {
        resize: "none",
        borderRadius: 10,
        outline: 'none',
        height: 40,
        backgroundColor: 'white',
        display: 'flex'
    },
    backImage: {
        position: 'absolute',
        top: -230,
        left: -150,
        zIndex: -1
    }
}


const ExplorePage = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const mountedRef = useRef(true);
    

    const dispatch = useDispatch();

    useEffect(
        () => {
            const fetchPhotos = () => {

                if (mountedRef.current)
                    setLoading(true);

                dispatch(retrievePhotos())
                    .unwrap()
                    .then((originalPromiseResult) => {
                        if (!mountedRef.current) return null;
                        setData(originalPromiseResult.body.data);
                    })
                    .catch((rejectedValueOrSerializedError) => {
                        console.log(rejectedValueOrSerializedError);
                    })
                    .finally(() => {
                        if (mountedRef.current)
                            setLoading(false);
                    });

            };
            fetchPhotos();
            return () => {
                mountedRef.current = false;
            }
        }, [dispatch]
    )
    return (
        <div className="m-0 p-0" style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <BackGroundShapes hideShapes style={{ position: 'absolute', height: '60vh', width: '100%' }}></BackGroundShapes>
            {<div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader color={'white'} loading={loading} size={50} css={{ marginTop: 20 }} />
            </div>}
            {(!loading && data.length!==0) && <ExploreGrid data={data} />}
        </div>
    )
}

export default ExplorePage;