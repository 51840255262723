import backPic from "../../../assets/userBack.jpg";
import Colors from "../../../constants/Colors";
import PersonInfo from "../Profile/PersonInfo";
import { useState, useEffect, useCallback } from "react";
import noPictureUser from "../../../assets/user.svg";
import { useHistory } from "react-router-dom";
import { SET_SELECTED_USER_ID } from "../../../store/slices/UserSlice";
import { useDispatch } from "react-redux";
import followers from "../../../assets/followers.png";
import comments from "../../../assets/Comments.png";
import likes from "../../../assets/Likes.png";
import { useTranslation } from "react-i18next";
import { getUserAvatar } from "../../../helpers/user";

const styles = {
  card: {
    borderRadius: "15px",
    overflow: "hidden",
    cursor: "pointer",
    border: "none",
    boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
    display: "flex",
    marginLeft: -5,
    height: 200,
    position: "relative",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  backImage: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "auto",
  },
  body: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: 135,
    width: "100%",
    backgroundColor: Colors.secondary,
  },
  backContainer: {
    display: "flex",
    height: 65,
    position: "absolute",
    width: "100%",
    overflow: "hidden",
    top: 0,
    left: 0,
  },
  profile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    backgroundColor: "transparent",
  },
};
const UserSearchCard = (props) => {
  /* const { firstName, lastName, country, city, date } = props.data; */
  const [image, setImage] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  console.log(props.userInfo);

  const handleClick = () => {
    console.log(props.userInfo._id)
    dispatch(SET_SELECTED_USER_ID(props.userInfo._id));
    history.push("/users/" + props.userInfo._id);
    
  } 

  useEffect(() => {
    setImage(getUserAvatar(props.userInfo));
  }, [props.userInfo]);

  /* const person = {
    name: "Lorenzo Piccinato",
    image: null,
    job: "Photographer",
    location: "Milano (Italy)",
    followers: 300,
    likes: 455,
    comments: 60,
  }; */

  return (
      <div
        style=
        {{
          backgroundColor: "white",
          width: "90%",
          maxWidth: "700px",
          margin: "auto",
        }}
        className='searchCard mb-2'
        onClick={handleClick} 
        >
        <div className="details ms-3 mt-3 d-flex justify-content-between mb-0"  >
          <div className="d-flex">
            <div>
              <img
                width={45}
                height={45}
                className="rounded-circle circle__profile border border-3 border-white"
                src={image}
                alt="user"
              />
            </div>
            <div style={{ lineHeight: 1 }} className="ms-2">
              <h6
                style={{ fontWeight: 400, fontSize: 14, color: "#101935" }}
                className="mt-1 pb-2 mb-0"
                style={{ fontSize: 14 }}
              >
                {props.userInfo.firstName} {props.userInfo.lastName}
              </h6>
              <p style={{ fontSize: 12, fontWeight: 300, color: "#101935" }}>
                {t(`User search.userSearchBox.job.${props.userInfo.occupation}`)} in Milan,Italy
                {/*{props.userInfo.city},{props.userInfo.country}*/}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ fontSize: 11, fontWeight: 300, color: "#101935" }}
          className="d-flex mb-2 ms-2 justify-content-center"
        >
          <div className="me-1">
            <img src={followers} />
          </div>
          <div className="align-self-center me-2">
            30 {t("User search.userSearchBox.followers")}{/*{props.userInfo.followers.length}*/}
          </div>
          <div className="me-1">
            <img src={likes} />
          </div>
          <div className="align-self-center me-2">
            455 {t("User search.userSearchBox.likes")}{/*{props.userInfo.followers.length}*/}
          </div>
          <div className="me-1">
            <img src={comments} />
          </div>
          <div className="align-self-center">
            60 {t("User search.userSearchBox.comments")}{/*{props.userInfo.followers.length}*/}
          </div>
        </div>
      </div>
  );
};

{
  /* 
      <div
        style={{ ...styles.card, ...props.style }}
        className='p-2'
        onClick={() => {
          dispatch(SET_SELECTED_USER_ID(props.userInfo._id));
          history.push("/users/" + props.userInfo._id);
        }}
      >
        <div style={styles.backContainer} className='p-0 m-0'>
          <img src={backPic} style={styles.backImage} alt='background' />
        </div>
        <div style={styles.body}></div>
        <div style={styles.profile} className='p-0 m-0'>
          <div style={{position:'relative'}}>
            {props.userInfo.status === "active" ? <div style={{ height: 20, width: 20, top: 5, right: 5, position: 'absolute', backgroundColor: 'black' }} className="rounded-circle border border-2 border-white " ></div> : <></>}
            <img
              src={image}
              width={90}
              height={90}
              className='circle__profile rounded-circle border border-3 border-white '
              alt='Profile'
            />
          </div>
          <PersonInfo
            person={props.userInfo}
            comesFrom='card'
            textAlign='center'
          />
        </div>
      </div>
      */
}

export default UserSearchCard;
