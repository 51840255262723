import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Colors from "../../../constants/Colors";
import AzureGrid from "../../AzureGrid/AzureGrid"

const styles = {
    field__text: {
        color: "#1D0D3F",
        fontSize: 14,
    }, mainButton: {
        color: 'white',
        borderRadius: 25,
        backgroundColor: Colors.primary,
        fontSize: 14,
        width: 165,
        height: 50
    }
};

const fieldData = [
    { field: "Validation", data: "Phone Number" },
    { field: "Field 02", data: "Data for this field" },
    { field: "Field 03", data: "Data for this field" },
    { field: "Field 04", data: "Data for this field" },
    { field: "Field 05", data: "Data for this field" },
];

const Bronze = props => {
    const history =useHistory()
    return (
        <AzureGrid>
            <div className="p-3">
                This is the next step over the minimum verification. To access this level you will need to confirm your identity by inputting your phone number.<br></br><br></br>
                As a bronze user you will be granted access to different features that still have to be discussed.
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button as="input" type="button" className="mt-3" value="Activate" style={styles.mainButton} onClick={() => history.push("/phone-verify")} />
                </div>
                {fieldData.map((field, index) => {
                    return (
                        <div
                            key={index}
                            className='d-flex justify-content-between my-4 border-bottom border-primary mx-3'
                            style={styles.field__text}
                        >
                            <div>{field.field}</div>
                            <div style={{ fontWeight: 'bold' }}>{field.data}</div>
                        </div>
                    );
                })}

            </div>

        </AzureGrid>
    )
}

export default Bronze;