import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createJob = createAsyncThunk(
  "job/createJob",
  async (data , { rejectWithValue }) => {
    try {
      /*
      let payload = { ...data };
      if (payload.imageUrl && payload.imageName) {
        let blob = await fetch(payload.imageUrl).then((r) => r.blob());
        payload.file = new File([blob], payload.imageName, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
      }
      delete payload.imageName;
      delete payload.imageUrl;*/
      const response = await services.JobService.createJob( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveJobs = createAsyncThunk(
  "job/retrieveJobs",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.JobService.retrieveJobs(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveJob = createAsyncThunk(
  "job/retrieveJob",
  async (jobId, { rejectWithValue }) => {
    try {
      const response = await services.JobService.retrieveJob(jobId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteJob = createAsyncThunk(
  "job/deleteJob",
  async (jobId, { rejectWithValue }) => {
    try {
      const response = await services.JobService.deleteJob(jobId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateJob = createAsyncThunk(
  "job/updateJob",
  async ({jobId,data},{ rejectWithValue }) => {
    try {
      let payload = { ...data };
      if (payload.imageUrl && payload.imageName) {
        let blob = await fetch(payload.imageUrl).then((r) => r.blob());
        payload.file = new File([blob], payload.imageName, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
      }
      delete payload.imageName;
      delete payload.imageUrl;
      const response = await services.JobService.updateJob(jobId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state:"",
  jobId:"",
  apply:"",
  error: {},
  specificJob:"",
  dropdown:false
};

const JobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    SET_JOB_ID(state,action) {
      const id  = action.payload;
      state.jobId=id;
    },
    SET_APPLY(state,action){
      state.apply=action.payload;
    },
    SET_DROPDOWN(state,action){
      state.dropdown=action.payload;
    },
    SET_SPECIFIC_JOB(state,action){
      state.specificJob=action.payload;
    }
  },
  extraReducers: {
    /* ---- CREATE_JOB ---- */
    [String(createJob.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createJob.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createJob.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_JOBS ---- */
    [String(retrieveJobs.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveJobs.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveJobs.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_JOB ---- */
    [String(retrieveJob.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveJob.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveJob.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_JOB ---- */
    [String(updateJob.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateJob.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateJob.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_JOB---- */
    [String(deleteJob.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteJob.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteJob.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {SET_JOB_ID,SET_APPLY,SET_DROPDOWN,SET_SPECIFIC_JOB} = JobSlice.actions;

export default JobSlice.reducer;
