export const getAuthToken = () => {
    let authToken = null;
    try {
      const storedValue = localStorage.getItem("TALENTS_POD_AUTH_TOKEN");
      authToken = storedValue != null ? JSON.parse(storedValue) : null;
    } catch (error) {
      console.log(error);
      authToken = null;
    }
  
    return authToken;
  };
  
  export const setAuthToken = (authToken) => {
    try {
      if (authToken) {
        localStorage.setItem(
          "TALENTS_POD_AUTH_TOKEN",
          JSON.stringify(authToken)
        );
      } else {
        localStorage.removeItem("TALENTS_POD_AUTH_TOKEN");
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  export const setUserId= (userId) => {
    try {
      if (userId) {
        localStorage.setItem(
          "TALENTS_POD_USER_ID",
          JSON.stringify(userId)
        );
      } else {
        localStorage.removeItem("TALENTS_POD_USER_ID");
      }
    } catch (error) {
      console.log(error);
    }
  };

  export const getUserId = () => {
    let userId = null;
    try {
      const storedValue = localStorage.getItem("TALENTS_POD_USER_ID");
      userId = storedValue != null ? JSON.parse(storedValue) : null;
    } catch (error) {
      console.log(error);
      userId = null;
    }
  
    return userId;
  };