import store from "../store";
import { createNotification } from "../store/slices/NotificationSlice";

export const NotificationBody = {
    LIKE:"liked your photo.",
    COMMENT: "left a comment on your photo.",
    MESSAGE: "sent you a message."
}

export const sendNotification = (payload,success,error) => {
    store.dispatch(createNotification(payload))
        .unwrap()
        .then((originalPromiseResult) => {
            success?.(originalPromiseResult.body.data)
        })
        .catch((rejectedValueOrSerializedError) => {
            error?.(rejectedValueOrSerializedError);
        })
}