

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom'
import noPictureUser from "../../../assets/user.svg"
import { retrieveUser } from '../../../store/slices/UserSlice';
import { GoPrimitiveDot } from 'react-icons/go'
import Colors from '../../../constants/Colors';
import { relativeTimeTranform } from '../../../helpers/time';
import { useTranslation } from 'react-i18next';
import { getUserAvatar } from '../../../helpers/user';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer'
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        fontSize: 18,
        fontWeight: 500,
    },
    lastMsg: {
        fontSize: 12,
        fontWeight: 300
    },
    timeAgo: {
        fontSize: 9,
        fontWeight: 300
    },
}

const DirectMessage = props => {
    const { user, lastMessage } = props;

    const [fullname, setFullName] = useState("loading...")
    const [image, setImage] = useState("")
    const [loadingPic, setLoadingPic] = useState(true)
    const { t } =useTranslation();

    const userId = useSelector(state => state.user.userId)
    const showUnread = lastMessage && !lastMessage.read && lastMessage.ownerId._id !== userId;

    const history = useHistory()

    const dispatch = useDispatch();
    const mountedRef = useRef(true)

    useEffect(() => {
        const fetchUser = (userId) => {
            if (mountedRef.current)
                setLoadingPic(true);

            dispatch(retrieveUser(userId))
                .unwrap()
                .then((originalPromiseResult) => {

                    if (!mountedRef.current) return null;
                    setFullName(`${originalPromiseResult.body.data.firstName} ${originalPromiseResult.body.data.lastName}`)
                    if (originalPromiseResult.body.data?.profile) {
                        setImage(getUserAvatar(originalPromiseResult.body.data));
                        if (mountedRef.current)
                            setLoadingPic(false);
                    }
                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                })
        }

        fetchUser(user?._id)
    }, [dispatch, user])

    
    return (
        <div style={styles.container} className="mt-4" onClick={() => history.push("/chat?userId=" + user?._id)}>
            {props.noInfo ?
                <img src={getUserAvatar(user)} width={50} height={50} alt="profile" className="rounded-circle circle__profile" style={{ marginRight: 16, marginBottom: props.noInfo ? 0 : 12 }} />
                :
                <img src={loadingPic ? noPictureUser : image} width={50} height={50} alt="profile" className="rounded-circle circle__profile" style={{ marginRight: 16, marginBottom: props.noInfo ? 0 : 12 }} />

            }
            <div style={{ display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between' }} className="border-bottom">
                {
                    props.noInfo ?
                        <div style={{ fontSize: 18, alignSelf: 'center', fontWeight: 'bold' }}>{user.firstName} {user.lastName}</div>
                        :
                        <>
                            <div style={styles.info}>
                                <div style={{ ...styles.name, fontWeight: showUnread ? 'bold' : 300 }}>{fullname} {showUnread && <GoPrimitiveDot style={{ heigh: 20, width: 20, color: Colors.primary }} />}</div>
                                <div style={{ ...styles.lastMsg, fontWeight: showUnread ? 'bold' : 300, color: showUnread ? 'black' : '#6c757d' }}>{lastMessage.ownerId._id === userId ? `${t("Messages.you")}: ` : ""}{lastMessage.body}</div>

                            </div>
                            <div style={{ ...styles.timeAgo, fontWeight: showUnread ? 'bold' : 300, color: showUnread ? 'black' : '#6c757d' }}>{relativeTimeTranform(new Date(lastMessage.updatedAt))}</div>
                        </>
                }
            </div>
        </div>
    )
}


export default DirectMessage;