
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround"
import Colors from "../../constants/Colors";
import { getUserAvatar } from "../../helpers/user";




const styles = {
    search: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        alignItems: 'center',
        height: 100
    },
    title: {
        fontWeight: 600,
        fontSize: 30,
        color: 'white',
        display: 'flex',
        alignItems: 'center'
    },
    levelContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: 190
    },
    mainButton: {
        color: 'white',
        borderRadius: 25,
        backgroundColor: Colors.primary,
        fontSize: 14,
        width: 165,
        height: 50,
        margin: 10,
    }
}


const CongratulationPage = props => {

    const user = useSelector(state => state.user.user);
    const history = useHistory()

    return (
        <div className="m-0 p-0" style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <BackGroundShapes hideShapes style={{ position: 'absolute', top: 0, height: '70%', width: '100%' }}></BackGroundShapes>
            <div className="p-4  m-0" style={styles.search}>
                <div style={styles.title} className="mt-3">
                    Congratulations!
                </div>
                <div style={styles.levelContainer} className="mx-4 mt-5">
                    <img src={getUserAvatar(user)} width={190} height={190} className="circle__profile rounded-circle border border-3 border-white" alt="Profile" />
                    <div style={{ height: 40, width: 40, top: 10, right: 10, position: 'absolute', backgroundColor: 'orange' }} className="rounded-circle border border-2 border-white " ></div>
                </div>
                <div style={{ fontSize: 21, textAlign: 'center' }} className="my-4">
                    You are now verified as a <span style={{ color: Colors.primary }}>{props.level}</span> user.
                </div>
                <div style={{ fontSize: 15, textAlign: 'center' }}>
                    To find out all the details of this new levels, check the levels page from your profile.<br></br><br></br>
                    To proceed to the next level, visit the same page and scroll to the desired level of verification.
                </div>
                <Button as="input" type="button" className="mt-4" value="Back to levels" style={styles.mainButton} onClick={() => history.goBack()} />
            </div>
        </div>
    )
}

export default CongratulationPage;