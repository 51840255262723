import { Button } from "react-bootstrap";
import { ReactComponent as CloseSvg } from "../../assets/navigationSvg/close.svg";
import Comment from "../../components/comments";
import OutCircledSvg from "../../components/OutcCrcledSvg";
import TitleBar from "../../components/Mobile/TitleBar";
import TabBar from "../../components/Mobile/TabBar";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPhotoComment,
  createPhotoLike,
  deletePhotoLike,
  retrievePhoto,
} from "../../store/slices/PhotoSlice";
import { retrieveUser } from "../../store/slices/UserSlice";
import ClipLoader from "react-spinners/ClipLoader";
import Colors from "../../constants/Colors";
import { AiFillLike, AiFillDislike } from "react-icons/ai";
import defaultUser from "../../assets/user.svg";
import {
  NotificationBody,
  sendNotification,
} from "../../helpers/NotificationUtils";
import UserPhotoInfo from "../../components/Mobile/Photos/UserPhotoInfo";
import likeFull from "../../assets/like1full.png";
import like from "../../assets/like1.png";
import halfLike from "../../assets/Broken heart IC.png";
import path from "../../assets/Path.png";
import { getPhotoSrc } from "../../helpers/photo";

const styles = {
  buttons: {
    backgroundColor: "#F2ECFF",
    borderRadius: 10,
    fontSize: 14,
    minWidth: 75,
    textAlign: "center",
    outline: "none",
    boxShadow: "none",
    border: "none",
  },
  like: {
    background: "#FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "10px",
    fontSize: "12px",
  },
  commentArea: {
    resize: "none",
    borderRadius: 10,
    outline: "none",
    width: "100%",
  },
  userNameText: {
    fontSize: 16,
    textAlign: "left",
  },
  reactions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  commentBox: {
    display: "flex",
    justifyContent: "center",
  },
};

const Header = (props) => {
  const [windowWidth, setWindowWidth] = useState(props.windowWidth);
  let history = useHistory();
  console.log(props);

  useEffect(() => {
    setWindowWidth(props.windowWidth);
  }, [props.windowWidth]);

  const user = {
    firstName: props.firstName,
    lastName: props.lastName,
    profile: props.profile,
  };

  return (
    <div className="mt-4 ms-1" style={styles.header}>
      <UserPhotoInfo user={user} title={props.title} date={props.date} />
    </div>
  );
};

const Reactions = (props) => {
  const [liked, setLiked] = useState(props.likeId ? true : false);
  const [disliked, setDisliked] = useState(false);
  const [reported, setReported] = useState(false);

  const [myLikeId, setMyLikeId] = useState(props.likeId);
  const [likes, setLikes] = useState(props.likes);
  const [dislikes, setDislikes] = useState(props.dislikes);

  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state.user.userId);

  const likePhoto = () => {
    if (!liked) {
      let data = {
        photoId: new URLSearchParams(window.location.search).get("photoId"),
        payload: {
          ownerId: props.ownerId,
          posted: new Date(),
        },
      };
      dispatch(createPhotoLike(data))
        .unwrap()
        .then((res) => {
          setMyLikeId(
            res.body.data.likes.find(
              (like) => like.ownerId === currentUserId
            )?._id
          );
          setLikes(likes + 1);
          setLiked(true);
          sendNotification({
            title: "Like",
            body: NotificationBody.LIKE,
            click_action: "/lightbox?photoId=" + res.body.data._id,
            type: "photo",
            status: "new",
          });
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    } else {
      let data = {
        photoId: new URLSearchParams(window.location.search).get("photoId"),
        likeId: myLikeId,
      };
      dispatch(deletePhotoLike(data))
        .unwrap()
        .then(() => {
          setLikes(likes - 1);
          setLiked(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    }
  };
  const dislikePhoto = () => {
    if (!disliked) {
      setDislikes(dislikes + 1);
      setDisliked(true);
    } else {
      setDislikes(dislikes - 1);
      setDisliked(false);
    }
  };

  return (
    <div className="p-1 my-3 " style={styles.reactions}>
      <div
        style={{
          ...styles.like,
          zIndex: 1,
        }}
        className="p-2 ps-3 pe-3 me-3"
        onClick={likePhoto}
      >
        <img
          className="ms-0 mr-1 mx-2"
          width={14}
          height={13}
          src={liked ? likeFull : like}
        />
        {likes}
      </div>
      <div
        style={{
          ...styles.like,
          zIndex: 1,
        }}
        className="p-2 ps-3 pe-3"
        onClick={dislikePhoto}
      >
        <img
          className="ms-0 mr-1 mx-2"
          width={14}
          height={13}
          src={disliked ? halfLike : halfLike}
        />
        {dislikes}
      </div>
    </div>
  );
};

const LightBoxMobile = ({ person }) => {
  const [photoId] = useState(
    new URLSearchParams(window.location.search).get("photoId")
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [comments, setComments] = useState([]);
  const [text, setText] = useState("");
  const [user, setUser] = useState({});
  const [date, setDate] = useState("");
  const [comment,setComment] = useState("");
  const mountedRef = useRef(true);
  const bottomRef = useRef(null);

  const dispatch = useDispatch();

  const handleChange = (e) =>{
    setText(e.target.value);
  }

  const currentUser = useSelector((state) => state.user.user);
  const [windowWidth, setWindowWidth] = useState(getWindowDimensions().width);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions().width);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [comments.length]);

  useEffect(() => {
    const fetchUser = (userId) => {
      dispatch(retrieveUser(userId))
        .unwrap()
        .then((originalPromiseResult) => {
          if (!mountedRef.current) return null;
          setUser(originalPromiseResult.body.data);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        })
        .finally(() => {
          if (mountedRef.current) setLoading(false);
        });
    };
    const fetchPhoto = () => {
      if (mountedRef.current) setLoading(true);

      dispatch(retrievePhoto(photoId))
        .unwrap()
        .then((originalPromiseResult) => {
          if (!mountedRef.current) return null;
          // console.log(originalPromiseResult.body.data)
          const match = originalPromiseResult.body.data.updatedAt.match(
            /(\d{4})-(\d{2})-(\d{1,2})T(\d{2}:\d{2}).*/
          );
          if (match)
            originalPromiseResult.body.data.updatedAt = `${match[3]}/${match[2]}/${match[1]} at ${match[4]}`;
          setDate(originalPromiseResult.body.data.createdAt);
          fetchUser(originalPromiseResult.body.data.ownerId?._id);
          setData(originalPromiseResult.body.data);
          setComments(originalPromiseResult.body.data.comments);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
          if (mountedRef.current) setLoading(false);
        });
    };
    if (photoId) fetchPhoto();

    return () => {
      mountedRef.current = false;
    };
  }, [dispatch, photoId]);

  if (!photoId)
    return (
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        Photo not found!
      </div>
    );

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ClipLoader
          color={Colors.primary}
          loading={true}
          size={50}
          css={{ marginTop: 20 }}
        />
      </div>
    );

  const sendComment = () => {
    if (!text) return;

    let date = new Date();
    const payload = {
      ownerId: currentUser._id,
      comment: text,
      posted: date,
    };
    dispatch(createPhotoComment({ photoId, payload }))
      .unwrap()
      .then((res) => {
        let array = comments;
        array.push({
          ownerId: currentUser,
          comment: text,
          posted: date,
        });
        setText("");
        setComments(array);
        sendNotification({
          title: "Comment",
          body: NotificationBody.COMMENT,
          click_action: "/lightbox?photoId=" + res.body.data._id,
          type: "photoComment",
          status: "new",
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const _enterHandler = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      sendComment();
    }
    
  };

  return (
    <div
      className="p-0 m-0 "
      style={{ width: "100%", backgroundColor: "#FAFAFA" }}
    >
      <TitleBar comesFrom="specific" title="Lightbox" />
      {windowWidth > 991 || (
        <Header
          windowWidth={windowWidth}
          title={data.title}
          image={user.profile}
          firstName={user.firstName}
          lastName={user.lastName}
          job={user.occupation}
          location={`${user.city}, ${user.country}`}
          status={user.status}
          date={date}
        />
      )}
      <div
        className="ms-3 mb-4"
        style={{ fontSize: "14px", width: "90%", fontWeight: 300 }}
      >
        {data.description}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: windowWidth > 991 ? "row" : "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            height: "100vh",
            flex: windowWidth > 991 ? 3 : 1,
          }}
        >
          <img
            className="w-100"
            src={getPhotoSrc(data)}
            style={{
              maxWidth: "100%",
              maxHeight: windowWidth > 991 ? "100vh" : 400,
            }}
            alt="Post Img"
          />
        </div>
        <Reactions
          likes={data?.likes?.length ?? 0}
          dislikes={0}
          ownerId={user._id}
          likeId={
            data.likes.find(
              (like) => like.ownerId === currentUser._id
            )?._id
          }
        />
        {comments.length !== 0 ? (
          comments.map((obj, index) => (
            <UserPhotoInfo
              user={obj.ownerId}
              title={obj.comment}
              date={obj.posted}
              place="end"
            />
          ))
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center" }}
            className="mt-3"
          >
            No comments
          </div>
        )}

        {/*<div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    {
                        windowWidth > 991 &&
                        <Header windowWidth={windowWidth} image={user.profile} name={`${user.firstName} ${user.lastName}`} job={user.occupation} location={`${user.city}, ${user.country}`} status={user.status} />
                    }
                    <div className="text-start text-muted px-3 pt-3" style={{ fontSize: 12 }}>{data.updatedAt}</div>
                    <div className="text-start px-3" style={{ fontSize: windowWidth > 991 ? 18 : 14 }}>
                        {data.title}
                    </div>
                   
                    <div style={styles.commentBox}>
                        <textarea style={styles.commentArea} value={text} onChange={event => setText(event.target.value)} onKeyDown={_enterHandler} className="mx-3" rows={3} placeholder="Leave a comment..."></textarea>
                    </div>
                    {comments.length !== 0 ? comments.map((obj, index) =>
                        <Comment key={index} owner={obj.ownerId} date={obj.posted} comment={obj.comment} />)
                        :
                        <div style={{ display: 'flex', justifyContent: 'center' }} className="mt-3">No comments</div>
                    }
                </div> */}
      </div>

      <div ref={bottomRef} style={{ marginTop: 70 }} />
      <div
        style={{
          minHeight: "100px",
          zIndex: 1000,
          position: "sticky",
          bottom: 0,
          background: "#FFFFFF",
          boxShadow: "0px -2px 6px rgba(0, 0, 0, 0.0762948)",
        }}
        className="pt-4 w-100"
      >
        <div
          className=" m-auto d-flex"
          style={{
            width: "80%",
            border: "1px solid #E8E9F3",
            boxSizing: "border-box",
            borderRadius: "16px",
          }}
        >
          <div className="vl ms-4 align-self-center"></div>
          <div
            style={{ color: "#1D0D3F", opacity: "60%" }}
            className="align-self-center pt-1 pb-1 ms-2 w-75"
          >
            <input style={{ border: "none" }} placeholder="Type something" onChange={handleChange} onKeyDown={_enterHandler} />
          </div>
          <div className="pt-1 pb-1 flex-shrink-1">
            <img src={path} alt="path" onClick={()=>text!="" && sendComment()}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LightBoxMobile;
