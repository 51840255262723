import swaggerProps from "../plugins/swagger-client";

export default class PhotoService {

  createPhoto(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.createPhoto(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrievePhotos(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.retrievePhotos(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrievePhoto(photoId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.retrievePhoto(
           {photoId} ,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deletePhoto(photoId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.deletePhoto(
          photoId ,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updatePhoto(photoId,info) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.updatePhoto(
          {photoId:photoId},
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createPhotoReport(photoId,info) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.createPhotoReport(
          {photoId:photoId},
          swaggerProps.buildRequest(info),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deletePhotoReport(photoId,reportId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.deletePhotoReport(
          {photoId:photoId,reportId:reportId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createPhotoCommentsLikes(photoId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.createPhotoCommentsLikes(
          {photoId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deletePhotoCommentsLikes(photoId,commentsId,likesId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.deletePhotoCommentsLikes(
          {photoId:photoId,commentsId:commentsId,likesId:likesId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createPhotoComment(photoId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.createPhotoComment(
          {photoId:photoId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updatePhotoComments(photoId,commentsId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.updatePhotoComments(
          {photoId:photoId,commentsId:commentsId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deletePhotoComments(photoId,commentsId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.deletePhotoComments(
          {photoId:photoId,commentsId:commentsId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createPhotoLike(photoId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.createPhotoLike(
          {photoId:photoId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deletePhotoLike(photoId,likeId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Photo.deletePhotoLike(
          {photoId:photoId,likeId:likeId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
