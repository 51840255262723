import { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import { VscLocation } from "react-icons/vsc";
import { Redirect } from "react-router-dom";
import Colors from "../../../constants/Colors";
import PeopleJoined from "./PeopleJoined";
import defaultPhoto from "../../../assets/backgroundMobile.jpg";
import { useDispatch } from "react-redux";
import { SET_APPLY, SET_SPECIFIC_JOB } from "../../../store/slices/JobSlice";
import noPictureUser from "../../../assets/user.svg";
import { retrieveUser } from "../../../store/slices/UserSlice";
import location from "../../../assets/Map pin IC.png";
import { useHistory } from "react-router";
import moment from "moment";
import defaultUser from "../../../assets/user.svg"
import { useTranslation } from "react-i18next";
import { getUserAvatar } from "../../../helpers/user";
import { getPhotoSrc } from "../../../helpers/photo";

const ProjectJobTile = (props) => {
  const [img, setImage] = useState("");
  const [userImg, setUserImage] = useState("");
  const [date, setDate] = useState("");
  const mountedRef = useRef(true);
  const dispatch = useDispatch();
  const [specificUser, setSpecificUser] = useState("");
  const history = useHistory();
  const { t } = useTranslation();

  const newDate = new Date();
  moment().format();

  console.log(props);

  const fetchUser = () => {
    console.log(props.projectInfo.ownerId);
    dispatch(retrieveUser(props.projectInfo.ownerId))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data);
        const user = originalPromiseResult.body.data;
        setSpecificUser(user);
        setUserImage(getUserAvatar(user))
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {
    fetchUser();
    setImage(getPhotoSrc(props.image))
    return () => {
      mountedRef.current = false;
    };
  }, [props.image, props]);

  const [clicked, setClicked] = useState(false);

  const { _id, country, city, title, createdAt } = props.projectInfo;

  useEffect(() => {
    const match = createdAt.match(/(\d{4})-(\d{2})-(\d{1,2})T(\d{2}:\d{2}).*/);
    if (match) setDate(`${match[3]}/${match[2]}/${match[1]}`);
  }, [createdAt]);

  let styles = {
    card: {
      borderRadius: "15px",
      overflow: "hidden",
      position: "relative",
      cursor: "pointer",
      backgroundColor:
        props.projectType === "Project" ? Colors.primary : Colors.secondary,
      border: "none",
      boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: -5,
      width: "100%",
    },
  };

  let textColor = props.projectType === "Project" ? "white" : "black";
  let mutedTextColor = props.projectType === "Project" ? "white" : "#999999";

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "90%",
        maxWidth: "700px",
        margin: "auto",
      }}
      className="searchCard mb-4"
    >
      <div className="ms-2 mt-2 d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div>
            <img
              width={45}
              height={45}
              className="rounded-circle circle__profile border border-3 border-white"
              src={userImg!="" ? userImg : defaultUser}
              alt="user"
            />
          </div>
          <div style={{ lineHeight: 1 }} className="ms-2">
            <h6 style={{ fontSize: 14, fontWeight: 400, color: "#101935" }}>
              {specificUser.firstName} {specificUser.lastName}
            </h6>
          </div>
        </div>
        <div className="me-3 mt-4 align-self-end">
          <p
            style={{
              fontSize: 9,
              color: "#101935",
              opacity: 0.5,
              fontWeight: 300,
            }}
            className=" mb-0 text-end"
          >
            Posted
          </p>
          <p
            style={{
              fontSize: 9,
              color: "#101935",
              opacity: 0.5,
              fontWeight: 300,
            }}
            className=""
          >
            {moment(props.projectInfo.createdAt).fromNow()}
          </p>
        </div>
      </div>
      <div className="mb-4">
        <img
          style={{ width: "100%" }}
          src={img}
          alt="Job"
          //onClick={() => history.push("/lightbox?photoId=" + props._id)}
        />
      </div>
      <h3
        style={{ fontSize: 28, fontWeight: 500 }}
        className="align-start ms-3 primaryColor"
      >
        {props.projectInfo.title}
      </h3>
      <div className="ms-3 mb-4 d-flex">
        <img className="align-self-center me-2" src={location} />
        <div style={{ fontWeight: 400, fontSize: 16 }} className="">
          
          {city},{country}
        </div>
      </div>
      <div style={{width:"95%"}}>
        <p style={{ fontSize: 14, fontWeight: 300 }} className="ms-3">
          {props.projectInfo.description}
        </p>
      </div>

      <div className="text-center mt-4 pt-2">
        <Button
          onClick={() => {
            dispatch(
              SET_SPECIFIC_JOB({
                userImg: userImg,
                firstName: specificUser.firstName,
                lastName: specificUser.lastName,
                img: img,
                title: props.projectInfo.title,
                city: city,
                province: "",
                country: country,
                description: props.projectInfo.description,
                createdAt: props.projectInfo.createdAt,
                ownerId:props.projectInfo.ownerId
              })
            );
            history.push(`/jobs/${props.projectInfo._id}`);
          }}
          className="buttonLogin ps-4 pe-4 mb-4"
        >
          {t("Jobs and projects.details")}
        </Button>
      </div>
    </div>
  );
};
/*
<Card style={{ ...styles.card, ...props.style }} className="p-3" onClick={() => { setClicked(true); props.comesFrom && props.comesFrom==="search" ? dispatch(SET_APPLY(props.comesFrom)) : dispatch(SET_APPLY("")) } }>
            {clicked ?
                <Redirect to={"/jobs/" + _id} /> : <></>
            }
            <div>
                <div style={{ fontSize: 12, color: mutedTextColor }} className="mx-0">
                    {props.projectType.charAt(0).toUpperCase() + props.projectType.slice(1)} <span style={{ width: 10 }} className="p-0 mx-1">
                        <VscLocation />
                    </span> {city}, {country}
                </div>
                <div style={{ fontSize: 15, fontWeight: 'bold', color: textColor }} className="my-1  mx-0">
                    {title}
                </div>
                <div style={{ fontSize: 12, color: textColor }} className="mt-1 mb-2  mx-0">
                    Posted on {date}
                </div>
                {
                    props.projectType === "Project" ?
                        <PeopleJoined image={props.image} />
                        :
                        <></>
                }
            </div>
            <div className="mx-2">
                <div >
                    {img ?
                        <img src={img} width={115} height={150} style={{ borderRadius: 10, objectFit: "cover" }} alt={_id} />
                        :
                        <></>
                    }
                </div>
            </div>
        </Card> */

export default ProjectJobTile;
