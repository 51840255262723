import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createChat = createAsyncThunk(
  "chat/createChat",
  async (data, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.createChat(data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const retrieveChats = createAsyncThunk(
  "chat/retrieveChats",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.retrieveChats(payload);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveChat = createAsyncThunk(
  "chat/retrieveChat",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.retrieveChat(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateChat = createAsyncThunk(
  "chat/updateChat",
  async (chatId, payload, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.updateChat(chatId, payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteChat = createAsyncThunk(
  "chat/deleteChat",
  async (chatId, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.deleteChat(chatId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createChatMessages = createAsyncThunk(
  "chat/createChatMessages",
  async ({chatId, payload}, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.createChatMessages(
        chatId,
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateChatMessages = createAsyncThunk(
  "chat/updateChatMessages",
  async ({chatId, messagesId, payload}, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.updateChatMessages(
        chatId,
        messagesId,
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteChatMessages = createAsyncThunk(
  "chat/deleteChatMessages",
  async (chatId, messageId, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.deleteChatMessages(
        chatId,
        messageId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createChatPeople = createAsyncThunk(
  "chat/createChatPeople",
  async (chatId, payload, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.createChatPeople(
        chatId,
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateChatPeople = createAsyncThunk(
  "chat/updateChatPeople",
  async (chatId, peopleId, payload, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.updateChatPeople(
        chatId,
        peopleId,
        payload
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteChatPeople = createAsyncThunk(
  "chat/deleteChatPeople",
  async (chatId, peopleId, { rejectWithValue }) => {
    try {
      const response = await services.ChatService.deleteChatPeople(
        chatId,
        peopleId
      );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  error: {},
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    ACTION(state, action) {},
  },
  extraReducers: {
    /* ---- CREATE_CHAT ---- */
    [String(createChat.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createChat.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createChat.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- GET_CHATS ---- */
    [String(retrieveChats.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveChats.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveChats.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_CHAT ---- */
    [String(retrieveChat.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveChat.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveChat.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- UPDATE_CHAT ---- */
    [String(updateChat.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateChat.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateChat.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- DELETE_CHAT ---- */
    [String(deleteChat.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteChat.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteChat.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- CREATE_CHAT_MESSAGES ---- */
    [String(createChatMessages.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createChatMessages.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createChatMessages.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- UPDATE_CHAT_MESSAGES ---- */
    [String(updateChatMessages.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateChatMessages.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateChatMessages.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- DELETE_CHAT_MESSAGES ---- */
    [String(deleteChatMessages.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteChatMessages.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteChatMessages.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- CREATE_CHAT_PEOPLE ---- */
    [String(createChatPeople.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createChatPeople.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createChatPeople.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- UPDATE_CHAT_PEOPLE ---- */
    [String(updateChatPeople.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateChatPeople.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateChatPeople.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- DELETE_CHAT_PEOPLE ---- */
    [String(deleteChatPeople.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteChatPeople.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteChatPeople.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { ACTION } = chatSlice.actions;

export default chatSlice.reducer;
