import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createComment = createAsyncThunk(
  "comment/createComment",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.CommentService.createComment( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveComments = createAsyncThunk(
  "comment/retrieveComments",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.CommentService.retrieveComments(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveComment = createAsyncThunk(
  "comment/retrieveComment",
  async (commentId, { rejectWithValue }) => {
    try {
      const response = await services.CommentService.retrieveComment(commentId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "comment/deleteComment",
  async (commentId, { rejectWithValue }) => {
    try {
      const response = await services.CommentService.deleteComment(commentId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateComment = createAsyncThunk(
  "comment/updateComment",
  async ({commentId,info},{ rejectWithValue }) => {
    try {
      const response = await services.CommentService.updateComment(commentId,info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  error: {},
};

const CommentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    ACTION(state,action) {},
  },
  extraReducers: {
    /* ---- CREATE_COMMENT ---- */
    [String(createComment.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createComment.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createComment.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_COMMENTS ---- */
    [String(retrieveComments.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveComments.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveComments.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_COMMENT ---- */
    [String(retrieveComment.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveComment.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveComment.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_COMMENT ---- */
    [String(updateComment.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateComment.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateComment.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_COMMENT ---- */
    [String(deleteComment.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteComment.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteComment.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION} = CommentSlice.actions;

export default CommentSlice.reducer;
