import { Col, Container, Row } from "react-bootstrap";
import Masonry from "react-masonry-css";
import "./gridStyle.scss";
/* import Post1 from '../../../assets/ExamplePosts/post1.jpg'
import Post2 from '../../../assets/ExamplePosts/post2.jpg'
import Post3 from '../../../assets/ExamplePosts/post3.jpg'
import Post4 from '../../../assets/ExamplePosts/post4.jpg'
import Post5 from '../../../assets/ExamplePosts/post5.jpg'
import Post6 from '../../../assets/ExamplePosts/post6.jpg'
import Post7 from '../../../assets/ExamplePosts/post7.jpg'
import Post8 from '../../../assets/ExamplePosts/post8.jpg'
import Post9 from '../../../assets/ExamplePosts/post9.jpg'
import Post10 from '../../../assets/ExamplePosts/post10.jpg' */
import ProfilePhotoTile from "./ProfilePhotoTile";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosAddCircle } from "react-icons/io";
import Colors from "../../../constants/Colors";
import { retrievePhotos } from "../../../store/slices/PhotoSlice";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import { RiErrorWarningLine } from "react-icons/ri";
import ExplorePhotoCard from "../Explore/ExplorePhotoCard";
import addCircle from "../../../assets/addCircle.png"

const styles = {
  warning: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: 10,
    fontSize: 12,
    maxWidth: 400,
  },
};
/* const breakpointColumnsObj = {
    default: 2,
    1100: 2,
    700: 1,
    500: 1
}; */
const breakpointColumnsObj = {
  default: 3,
  1024: 2,
  700: 1,
  500: 1,
};

/* const example_data = [{
    id: 1,
    name: "Portrait of",
    image: Post1,
    albumName: "Male Portrait"
}, {
    id: 2,
    name: "Portrait of",
    image: Post2,
    albumName: "Male Portrait"
}, {
    id: 3,
    name: "Portrait of",
    image: Post3,
    albumName: "Male Portrait"
}, {
    id: 4,
    name: "Portrait of",
    image: Post4,
    albumName: "Male Portrait"
}, {
    id: 5,
    name: "Portrait of",
    image: Post5,
    albumName: "Male Portrait"
}, {
    id: 6,
    name: "Portrait of",
    image: Post6,
    albumName: "Male Portrait"
}, {
    id: 7,
    name: "Portrait of",
    image: Post7,
    albumName: "Male Portrait"
}, {
    id: 8,
    name: "Portrait of",
    image: Post8,
    albumName: "Male Portrait"
}, {
    id: 9,
    name: "Portrait of",
    image: Post9,
    albumName: "Male Portrait"
}, {
    id: 10,
    name: "Portrait of",
    image: Post10,
    albumName: "Male Portrait"
}
]; */

const ProfilePhotosGrid = ({ person, add, comesFrom }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const mountedRef = useRef(true);

  const userId = useSelector((state) => state.user.userId);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const dispatch = useDispatch();

  const fetchUser = useCallback(() => {
    if (mountedRef.current) setLoading(true);

    const params = {
      userId: !add ? person._id : userId,
    };
    console.log(selectedUserId)
    dispatch(retrievePhotos(params))
      .unwrap()
      .then((originalPromiseResult) => {
        if (!mountedRef.current) return null;
        console.log(originalPromiseResult.body.data)
        if (comesFrom === "selectedUser") {
          setData(
            originalPromiseResult.body.data.filter((photo) => {
              return photo.ownerId._id === selectedUserId;
            })
          );
        } else {
          setData(
            originalPromiseResult.body.data.filter((photo) => {
              return photo.ownerId._id === userId;
            })
          );
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {
        if (mountedRef.current) setLoading(false);
      });
  }, [dispatch, userId, add, person._id, comesFrom, selectedUserId]);

  useEffect(() => {
    fetchUser();
    return () => {
      mountedRef.current = false;
    };
  }, [fetchUser]);
  return (
    <Container
      className="m-0 p-0 w-100"
      fluid
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ClipLoader
          color={Colors.primary}
          loading={loading}
          size={50}
          css={{ marginTop: 20 }}
        />
      </div>
      {!loading && selectedUserId === userId && data && data.length < 3 && (
        <div style={styles.warning} className="px-4 mt-4">
          <RiErrorWarningLine
            style={{ height: 35, width: 35, color: Colors.primary }}
            className="mb-1"
          />
          <span style={{ fontSize: 18 }}>
            Upload at{" "}
            <span style={{ color: Colors.primary }}>least three photos</span>{" "}
          </span>
          <br></br>
          and unlockall features of your profile. Placeholder text for some
          other explaination.
        </div>
      )}
      {!loading && data.length !== 0 && (
        <div className="justify-content-center mt-5">
          <Col md="auto" className="justify-content-center">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid2 w-100"
              columnClassName="my-masonry-grid_column2"
            >
              {data.map((item) => (
                <div
                  key={item._id}
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flex: 1,
                    overflow: "hidden",
                    boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
                  }}
                >
                  <ExplorePhotoCard
                    data={item}
                    height={"auto"}
                    width={"100%"}
                  />
                </div>
              ))}
            </Masonry>
          </Col>
        </div>
      )}
      <div className="px-3" style={{ textAlign: "right", overflow: "hidden" }}>
        {add && (
          <Link to="/newPhoto">
            <img
              src={addCircle}
              style={{
                color: Colors.primary,
                zIndex: 102,
                position: "fixed",
                bottom: window.innerWidth > 800 ? 60 : 80,
                right: window.innerWidth > 800 ? 60 : 20,
                backgroundColor: "white",
                borderRadius: 25,
                boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
              }}
              alt="AddCircle"
            />

          </Link>
        )}
      </div>
    </Container>
  );
};

export default ProfilePhotosGrid;
