import { Container } from "react-bootstrap";
import ProjectJobGrid from "../../components/Mobile/Jobs/ProjectJobGrid";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import { retrieveJobs } from "../../store/slices/JobSlice";
import ClipLoader from "react-spinners/ClipLoader";

const options = ["Monza", "Padova", "Rome", "Milano"];
const options1 = [
  "Model",
  "Photographer",
  "MUA",
  "Stylist",
  "Hair stylist",
  "Observer",
];

const styles = {
  search: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "hidden",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    width:"100%",
    alignItems: "center",
    minHeight: 210,
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
    color: "white",
  },
  commentArea: {
    resize: "none",
    borderRadius: 10,
    outline: "none",
    height: 40,
    backgroundColor: "white",
    display: "flex",
  },
  backImage: {
    position: "absolute",
    top: -230,
    left: -150,
    zIndex: -1,
  },
};

const JobSearchPage = (person) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const mountedRef = useRef(true);
  // eslint-disable-next-line no-unused-vars
  const [occupation, setOccupation] = useState("");
  const [city, setCity] = useState("");
  const dispatch = useDispatch();
  const dropdown = useSelector((state) => state.job.dropdown);
  const myId = useSelector((state) => state.user.userId);

  const fetchJobs = useCallback(
    (city) => {
      if (mountedRef.current) setLoading(true);

      dispatch(retrieveJobs({ type: "user" }))
        .unwrap()
        .then((originalPromiseResult) => {
          /*
        if (!mountedRef.current)
          return null;*/
          console.log(originalPromiseResult.body.data);
          setJobs(
            originalPromiseResult.body.data
              .filter((job) => job.ownerId !== myId)
              .filter((job) => {
                return job.city === city;
              })
          );
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        })
        .finally(() => {
          if (mountedRef.current) setLoading(false);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    fetchJobs(city);
    return () => {
      mountedRef.current = false;
    };
  }, [fetchJobs, city]);

  return (
    <Container className='m-0 p-0'>
      <BackGroundShapes
        hideShapes
        style={{ position: "absolute", height: "60vh", width: "100%" }}
      ></BackGroundShapes>
      <div className='pb-2' style={styles.search}>
        {/* <img src={background} style={styles.backImage} alt="background" /> */}
        {dropdown && (
          <div className='p-1 m-2 mt-0 pt-4 pb-4 dropdowns' style={{ width: "100%"}}>
          <Dropdown
              text='lookingFilter'
              current={occupation}
              options={options1}
              translate={true}
              comesFrom="Jobs and projects"
              passToParent={(value) => {
                setOccupation(value);
              }}
            />
            <Dropdown
              text='workingFilter'
              current={city}
              options={options}
              translate={false}
              comesFrom="Jobs and projects"
              passToParent={(value) => {
                setCity(value);
              }}
            />

            <Dropdown
              text='workingFilter'
              current={city}
              options={options}
              translate={false}
              comesFrom="Jobs and projects"
              passToParent={(value) => {
                setCity(value);
              }}
            />
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ClipLoader
          color={"white"}
          loading={loading}
          size={50}
          css={{ marginTop: 20 }}
        />
      </div>
      {!loading && jobs.length !== 0 && (
        <ProjectJobGrid comesFrom='search' data={jobs} />
      )}
    </Container>
  );
};

export default JobSearchPage;
