import swaggerProps from "../plugins/swagger-client";

export default class AccountService {
  createAccount(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Account.createAccount(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveAccounts(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Account.retrieveAccounts(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveAccount(accountId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Account.retrieveAccount(
          { accountId: accountId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteAccount(accountId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Account.deleteAccount(
          { accountId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateAccount(accountId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.updateAccount(
          { accountId: accountId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
