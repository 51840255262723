import { Container, Button } from "react-bootstrap";
import talentspod from "../../assets/Rectangle 28.png";
import { useHistory } from "react-router";
import userImage from "../../assets/NotLoggedIn.png";

const styles = {
  main__frame: {
    background:
      "linear-gradient(180deg, #E8E9F3 0%, rgba(232, 233, 243, 0) 64.83%)",
  },
  edit__button: {
    color: "white",
    borderRadius: "100px",
    fontSize: 12,
    maxWidth: "200px",
  },
};

const NotLoggedInPage = () => {
  const history = useHistory();

  return (
    <Container
      className='m-0 p-0 d-flex justify-content-center mt-5 pt-4'
      fluid
    >
      <div
        style={{ backgroundColor: "white", width: "90%", maxWidth: "700px" }}
        className='text-center notLoginGrid'
      >
        <div className='mt-5'>
          <img src={userImage} alt='UserIcon' />
        </div>
        <h2 style={{ color: "#6F79D8",fontSize:24,fontWeight:500}} className='mt-4'>
          You are not logged in.
        </h2>
        <p
          style={{ width: "70%", margin: "auto", fontSize: "14px",fontWeight:300 }}
          className='mt-4 text-center'
        >
          You need to <span style={{fontWeight:400}} className=''>register</span> or{" "}
          <span style={{fontWeight:400}}  className=''>login</span> to access this page.
        </p>
        <Button
          style={{fontSize:13}}
          className='mt-5 mb-5 buttonLogin'
          onClick={() => {
            history.replace("/login");
          }}
        >
          Go to Login
        </Button>
      </div>
    </Container>
  );
};

export default NotLoggedInPage;
