import { combineReducers, configureStore } from "@reduxjs/toolkit";
import updateRegisterInfoReducer from "./reducers/register";
import tabReducer from "./reducers/tabs";
import UserReducer from "./slices/UserSlice";
import CommentReducer from "./slices/CommentSlice";
import GalleryReducer from "./slices/GallerySlice";
import JobReducer from "./slices/JobSlice";
import PhotoReducer from "./slices/PhotoSlice";
import { persistStore, persistReducer } from "redux-persist";
import ChatReducer from "./slices/ChatSlice";
import ProfileReducer from "./slices/ProfileSlice";
import BadgeReducer from "./slices/BadgeSlice";
import storage from "redux-persist/lib/storage";
import EventReducer from './slices/EventSlice';
import AccountReducer from './slices/AccountSlice';
import TransactionReducer from './slices/TransactionSlice';
import NotificationReducer from './slices/NotificationSlice';

const storePersistKeys = {
  user: "zeronotary_console_user"
};


const reducer = combineReducers({
  user: persistReducer({ key: storePersistKeys.user, storage }, UserReducer),
  comment: CommentReducer,
  gallery: GalleryReducer,
  job: JobReducer,
  photo: PhotoReducer,
  chat: ChatReducer,
  profile:ProfileReducer,
  badge:BadgeReducer,
  event:EventReducer,
  account:AccountReducer,
  transaction:TransactionReducer,
  notification:NotificationReducer,
  tabState: tabReducer,
  registerInfo: updateRegisterInfoReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "store/reset") {
    state = undefined;
  }
  return reducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export const resetPersistor = () => {
  persistStore(store);
};

export default store;
