import { useState, useEffect, useCallback } from "react";
import { Card } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import Colors from "../../constants/Colors";
import { useDispatch } from "react-redux";
import { retrievePhoto } from "../../store/slices/PhotoSlice";
import { IoMdAlbums } from "react-icons/io";
import more from "../../assets/Gallery icon.png";
import {useHistory} from "react-router-dom";
import { getPhotoSrc } from "../../helpers/photo";

const AlbumCard = (props) => {
  const [image, setImage] = useState("");
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { data } = props;

  const fetchPhotos = useCallback(
    (data) => {
      dispatch(retrievePhoto(data[0].photoId._id))
        .unwrap()
        .then((originalPromiseResult) => {
          setImage(getPhotoSrc(originalPromiseResult.body.data));
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    },
    [dispatch]
  );

  useEffect(() => {
    fetchPhotos(data.photos);
  }, [data.photos, fetchPhotos]);

  const styles = {
    card: {
      width: props.width,
      borderRadius: "15px",
      overflow: "hidden",
      border: "none",
      position: "relative",
      cursor: "pointer",
    },
  };

  const [clicked, setClicked] = useState(false);

  return (
    <div className="w-100 galleriesGrid mb-4" onClick={()=>{
      history.push(`/gallery/${data._id}`);
    }}>
      <h3 className="p-3 mainColor">{data.title}</h3>
      <div style={{position: "relative" }}>
        <img tyle={{position:"relative",top:0,left:0}} className="w-100" src={image} alt="mainImage"/> 
        <img style={{position:"absolute",top:"20px",right:"20px",zIndex:1002}} src={more} alt="many photos"/>
      </div>
    </div>
  );
};

{
  /* 
    <Card
      style={styles.card}
      id='card'
      onClick={() => {
        setClicked(true);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {clicked ? <Redirect push to={'/gallery/' + data._id} /> : <></>}
      <IoMdAlbums style={{ position: "absolute", right: 15, top: 15, height: 30, width: 30, color: Colors.primary, opacity: 0.9, transform: 'rotate(180deg)',zIndex:100 }} />
      <Card.Img
        variant='top'
        src={image}
        height={props.height}
        width={props.width}
        alt={data.title}
        style={{transition:'transform 500ms ease', transform: hover ? 'scale(1.1)' : 'none' }}
      />
      <Card.Body style={{ backgroundColor: Colors.secondary }}>
        <Card.Title
          className='m-1'
          style={{ textAlign: "center", fontSize: props.fontSize }}
        >
          {data.title}
        </Card.Title>
      </Card.Body>
    </Card>*/
}

export default AlbumCard;
