import Colors from "../../constants/Colors";
import { useHistory } from "react-router";
import defaultImage from "../../assets/user.svg";
import { useDispatch, useSelector } from "react-redux";
import { switchMainTab } from "../../store/actions/tabs";
import { FiLogOut } from "react-icons/fi";
import talentspod from "../../assets/Logo.png";
import { LOGOUT_USER } from "../../store/slices/UserSlice";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import Logo from "../../assets/Logo1.png";
import { retrieveNotifications } from "../../store/slices/NotificationSlice";
import notifications from "../../assets/Notification IC.png";
import ExploreGrid from "./Explore/ExploreGrid";
import menuIcon from "../../assets/menuIcon.png";
import { SET_DROPDOWN } from "../../store/slices/JobSlice";
import backButton from "../../assets/backButton.png"
import { SET_DROPDOWN_USERS } from "../../store/slices/UserSlice";
import { useTranslation } from "react-i18next";
import { getUserAvatar } from "../../helpers/user";

const styles = {
  bar: {
    display: "flex",
    minHeight: 55,
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1000,
    backgroundColor: "white",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 3px 10px 5px rgba(0, 0, 0, 0.02)",
  },
  title: {
    fontSize: 12,
  },
  button: {
    display: "flex",
    textAlign: "center",
    backgroundColor: "transparent",
    outline: "none",
    boxShadow: "none",
    border: "none",
    justifyContent: "center",
    alignItems: "center",
  },
};

const TitleBar = (props) => {
  const [unread, setUnread] = useState(0);
  const history = useHistory();
  const [title, setTitle] = useState("");
  const dropdownJobs = useSelector((state) => state.job.dropdown);
  const dropdownUsers = useSelector((state) => state.user.dropdown);
  const [sideJobs,setSideJobs] = useState(false);
  const [sideUsers,setSideUsers] = useState(false);
  const { t } = useTranslation();

  const setTitleHeader = () => {
    switch (props.title) {
      case "home":
        setTitle("Explore");
        break;
      case "jobs":
        setTitle("Jobs and projects");
        break;
      case "search":
        setTitle("User search");
        break;
      case "send":
        setTitle("Messages");
        break;
      case "profile":
        setTitle("Profile");
        break;
      default:
        setTitle(props.title)
        break;
    }
  };

  const options = [
    {
      text: "Jobs and projects",
      tab: "jobs",
    },
    {
      text: "User search",
      tab: "search",
    },
    {
      text: "Explore",
      tab: "home",
    },
  ];
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  useEffect(() => {
    setTitleHeader();

    setSideJobs(dropdownJobs);
    setSideUsers(dropdownUsers);

    const fetchNotifications = () => {
      dispatch(retrieveNotifications({ type: "user" }))
        .unwrap()
        .then((originalPromiseResult) => {
          const notifications = originalPromiseResult.body.data.filter(
            (item) => item.status === "new"
          ).length;

          setUnread(notifications);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    };

    if (user._id) {
      fetchNotifications();
    }
  }, [dispatch, user, props.title,dropdownJobs,dropdownUsers]);
  return (
    <>
      <div className='d-flex d-sm-flex d-md-flex d-lg-none' style={styles.bar}>
        {/*  TalentsPod */}
        <div
          onClick={() => {
            history.replace("/");
            //dispatch(LOGOUT_USER());
          }}
          className='mx-3'
        >
          {/* <HiOutlineMenu
            style={{ height: 30, width: 30, color: Colors.primary }}
          /> */}
          {props.comesFrom === "specific" ? <img onClick={() => history.goBack()} src={backButton} alt="back"/>:<img src={Logo} alt='logo' /> }
        </div>
        <div  className="d-flex">
          <h5 style={{fontSize:16,fontWeight:500}} className='mt-2'>{t(`${title}.title`)}</h5>
          {title === "Jobs and projects" && props.comesFrom!== "specific" && (
            <img className={sideJobs ? "ms-3 side":"ms-3"} style={{alignSelf:"center"}} src={menuIcon} alt='menu' onClick={()=>{
              dispatch(SET_DROPDOWN(!dropdownJobs));
            }}/>
          )}
          {title === "User search" && (
            <img className={sideUsers ? "ms-3 side":"ms-3"} style={{alignSelf:"center"}} src={menuIcon} alt='menu' onClick={()=>{
              dispatch(SET_DROPDOWN_USERS(!dropdownUsers));
              
            }}/>
          )}
        </div>

        <div
          onClick={() => {
            history.push("/notifications");
          }}
          className='mx-2'
          style={{ position: "relative" }}
        >
          {unread > 0 && (
            <div
              style={{
                height: 20,
                width: 20,
                color: "white",
                backgroundColor: "red",
                position: "absolute",
                top: 0,
                right: -5,
                fontSize: 12,
                textAlign: "center",
              }}
              className='rounded-circle border border-1 border-white'
              id='send'
            >
              {unread}
            </div>
          )}
          <img className='me-2' src={notifications} alt='not' />
        </div>
      </div>
      <div
        className='d-none d-sm-none d-md-none d-lg-flex w-100 justify-content-around'
        style={{
          backgroundColor: "white",
          height: "55px",
          lineHeight: "55px",
          borderBottom: "0.5px solid rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className='d-flex justify-content-around w-50 mx-1'>
          <div
            onClick={() => {
              dispatch(switchMainTab("home"));
              history.push("/");
            }}
            style={{ color: "black", cursor: "pointer" }}
          >
            <img src={talentspod} alt='' height={45} />
          </div>
        </div>
        <div className='d-flex justify-content-around w-25'>
          <div
            onClick={() => {
              history.push("/notifications");
            }}
            className='me-4'
            style={{ position: "relative" }}
          >
            {unread > 0 && (
              <div
                style={{
                  height: 20,
                  width: 20,
                  color: "white",
                  backgroundColor: "red",
                  position: "absolute",
                  top: 0,
                  right: -5,
                  fontSize: 12,
                  textAlign: "center",
                }}
                className='rounded-circle border border-1 border-white'
                id='send'
              >
                {unread}
              </div>
            )}
            <IoMdNotificationsOutline
              style={{ height: 30, width: 30, color: Colors.primary }}
            />
          </div>
          <div className='user  '>
            <div
              className='d-flex'
              style={{ height: "100%", cursor: "pointer" }}
              onClick={() => {
                dispatch(switchMainTab("profile"));
                history.push("/");
              }}
            >
              <div style={{ lineHeight: "55px" }}>
                <img
                  className='circle__profile'
                  style={{
                    width: "35px",
                    height: "35px",
                    border: "2px solid #9D76F0",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  src={getUserAvatar(user)}
                  alt=''
                />
              </div>
              <div
                className='mx-2'
                style={{ color: Colors.primary, cursor: "pointer" }}
              >
                {user.firstName ? user.firstName : "Profile"}
              </div>
              {/* <div className="ms-2" style={{lineHeight:"55px"}}>
                    <img src={arrow} alt/>
                </div> */}
            </div>
          </div>

          <div
            className='mx-1'
            onClick={() => {
              dispatch(switchMainTab("send"));
              history.push("/");
            }}
            style={{ color: "black", cursor: "pointer" }}
          >
            Messages
          </div>
          <div
            className='mx-1'
            onClick={() => {
              dispatch(LOGOUT_USER());
              history.replace("/login");
            }}
            style={{ color: Colors.primary, cursor: "pointer" }}
          >
            Logout{" "}
            <FiLogOut
              style={{
                height: 20,
                width: 20,
                color: Colors.primary,
                marginLeft: 5,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleBar;
