import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";
import {  setAuthToken, setUserId } from "../../helpers/auth";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await services.UserService.loginUser(data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, { rejectWithValue }) => {
    try {
      let payload = { ...data };
      if (payload.imageUrl && payload.imageName) {
        let blob = await fetch(payload.imageUrl).then((r) => r.blob());
        payload.profile = new File([blob], payload.imageName, {
          lastModified: new Date().getTime(),
          type: blob.type,
        });
      }
      delete payload.imageName;
      delete payload.imageUrl;
      delete payload["photos"];
      delete payload["photos[]"];
      delete payload["comments"];
      delete payload["followers"];
      delete payload["likes"];
      delete payload["galleries"];
     // delete payload["_id"];
      const response = await services.UserService.createUser(payload);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveUsers = createAsyncThunk(
  "user/retrieveUsers",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.UserService.retrieveUsers(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveUser = createAsyncThunk(
  "user/retrieveUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await services.UserService.retrieveUser(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await services.UserService.deleteUser(userId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userId, info }, { rejectWithValue }) => {
    try {
      const response = await services.UserService.updateUser(userId, info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  user: {
    email: "",
    password: "",
    username: "",
    country: "",
    city: "",
    firstName: "",
    lastName: "",
    phone: "",
    profile: null,
    imageUrl: "",
    imageName: "",
    occupation: ""
  },
  userId: "",
  selectedUserId:"",
  dropdown:false,
  error: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    ACTION(state, action) { },
    SET_USER(state, action) {
      if(action.payload==="clear"){
        state.user={};
      }else{
        state.user = { ...state.user, ...action.payload };
      }
      
    },
    SET_USER_ID(state, action) {
      state.userId = action.payload;
    },
    SET_SELECTED_USER_ID(state,action){
      state.selectedUserId = action.payload;
    },
    SET_DROPDOWN_USERS(state,action){
      state.dropdown = action.payload;
    },
    LOGOUT_USER(state,action){
      localStorage.clear();
      setAuthToken("");
      setUserId("")
      state.userId=""
      state.user={}
      console.log(state)
    }
  },
  extraReducers: {
    /* ---- CREATE_USER ---- */
    [String(loginUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(loginUser.fulfilled)]: (state, action) => {
      setAuthToken(`Bearer ${action.payload.body.data.token}`);
      state.state = "success";
    },
    [String(loginUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
    /* ---- CREATE_USER ---- */
    [String(createUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USERS ---- */
    [String(retrieveUsers.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveUsers.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveUsers.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_USER ---- */
    [String(retrieveUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- UPDATE_USER ---- */
    [String(updateUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(updateUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(updateUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- DELETE_USER ---- */
    [String(deleteUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteUser.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { ACTION, SET_USER, SET_USER_ID,SET_SELECTED_USER_ID,LOGOUT_USER,SET_DROPDOWN_USERS } = userSlice.actions;

export default userSlice.reducer;
