import swaggerProps from "../plugins/swagger-client";

export default class JobService {

  createJob(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Job.createJob(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveJobs(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Job.retrieveJobs(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveJob(jobId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Job.retrieveJob(
          { jobId:jobId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteJob(jobId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Job.deleteJob(
          { jobId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateJob(jobId,form) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Job.updateJob(
          {jobId:jobId},
          swaggerProps.buildRequest(form),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
