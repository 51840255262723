import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createAccount = createAsyncThunk(
  "account/createAccount",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.AccountService.createAccount( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveAccounts = createAsyncThunk(
  "account/retrieveAccounts",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.AccountService.retrieveAccounts(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveAccount = createAsyncThunk(
  "account/retrieveAccount",
  async (badgeId, { rejectWithValue }) => {
    try {
      const response = await services.AccountService.retrieveAccount(badgeId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  "account/deleteAccount",
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await services.AccountService.deleteAccount(accountId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateAccount = createAsyncThunk(
  "account/updateAccount",
  async (accountId,payload,{ rejectWithValue }) => {
    try {
      const response = await services.AccountService.updateAccount(accountId,payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state:"",
  error: {},
};

const AccountSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    ACTION(state,action) {
    },
  },
  extraReducers: {
    /* ---- CREATE_ACCOUNT ---- */
    [String(createAccount.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createAccount.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createAccount.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_ACCOUNTS ---- */
    [String(retrieveAccounts.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveAccounts.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveAccounts.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_ACCOUNT ---- */
    [String(retrieveAccount.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveAccount.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveAccount.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_ACCOUNT ---- */
    [String(updateAccount.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateAccount.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateAccount.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_ACCOUNT---- */
    [String(deleteAccount.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteAccount.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteAccount.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
      
  },
});

export const {ACTION} = AccountSlice.actions;

export default AccountSlice.reducer;
