import swaggerProps from "../plugins/swagger-client";

export default class EventService {
  createEvent(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Event.createEvent(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveEvents(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Event.retrieveEvents(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveEvent(eventId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.retrieveEvent(
          { eventId: eventId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteEvent(eventId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.deleteEvent(
          { eventId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateEvent(eventId, payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Badge.updateEvent(
          { eventId: eventId },
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
