import swaggerProps from "../plugins/swagger-client";

export default class ChatService {

  createChat(payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.createChat(
          {},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveChats(params = {}) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.retrieveChats(
          params,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveChat(chatId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.retrieveChat(
           chatId ,
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteChat(chatId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.deleteChat(
          { chatId },
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateChat(chatId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.updateChat(
          {chatId:chatId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  
  createChatMessages(chatId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.createChatMessages(
          {chatId:chatId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateChatMessages(chatId,messagesId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.updateChatMessages(
          {chatId:chatId,messagesId:messagesId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  deleteChatMessages(chatId,messageId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.deleteChatMessages(
          {chatId:chatId,messageId:messageId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  createChatPeople(chatId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.createChatPeople(
          {chatId:chatId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  updateChatPeople(chatId,peopleId,payload) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.updateChatPeople(
          {chatId:chatId,peopleId:peopleId},
          swaggerProps.buildRequest(payload),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  deleteChatPeople(chatId,peopleId) {
    return swaggerProps.swagger
      .then((client) => {
        return client.apis.Chat.deleteChatPeople(
          {chatId:chatId,peopleId:peopleId},
          swaggerProps.buildRequest(),
          {}
        );
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
