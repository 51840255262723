import { Container } from "react-bootstrap"
import '../Photos/gridStyle.scss'
import Masonry from 'react-masonry-css'
/* import Pic1 from '../../../assets/ExampleProjectPics/pic1.jpg'
import Pic2 from '../../../assets/ExampleProjectPics/pic2.jpg'
import Pic3 from '../../../assets/ExampleProjectPics/pic3.jpg'
import Pic4 from '../../../assets/ExampleProjectPics/pic4.jpg'
import Pic5 from '../../../assets/ExampleProjectPics/pic5.jpg' */

import ProjectJobTile from "./ProjectJobTile"
import { IoIosAddCircle } from 'react-icons/io'
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { retrieveJobs } from "../../../store/slices/JobSlice"
import Colors from "../../../constants/Colors"
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom"
import addCircle from "../../../assets/addCircle.png"


const breakpointColumnsObj = {
    default: 3,
    1024: 2,
    700: 1,
    500: 1
};


/* const example_data = [{
    id: 1,
    title: "Black and white photoshoot with light effects",
    type: "Job",
    city: "Milano",
    region: "Lombardia",
    date: "22/06/2021",
    image: Pic1
}, {
    id: 2,
    title: "Building and shooting for cultural festival",
    type: "Project",
    city: "Monza",
    region: "Lombardia",
    date: "10/05/2021",
    image: Pic2
}, {
    id: 3,
    title: "Natural-light shooting for personal portofolio",
    type: "Job",
    city: "Pavia",
    region: "Lombardia",
    date: "20/06/2021",
    image: ""
}, {
    id: 4,
    title: "Team for shooting abroad Copenhagen",
    type: "Project",
    city: "Milano",
    region: "Lombardia",
    date: "12/06/2021",
    image: Pic3
}, {
    id: 5,
    title: "Shooting for personal fashion brand",
    type: "Job",
    city: "Legnano",
    region: "Lombardia",
    date: "09/06/2021",
    image: Pic4
}, {
    id: 6,
    title: "Looking for model with face tattoos for shooting",
    type: "Job",
    city: "Legnano",
    region: "Lombardia",
    date: "09/06/2021",
    image: Pic5
}
] */

const ProjectJobGrid = props => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])
    const mountedRef = useRef(true);

    const userId = useSelector((state) => state.user.userId);
    const dispatch = useDispatch();
    const selectedUserId = useSelector((state) => state.user.selectedUserId);
    const history = useHistory();

    const fetchUser = useCallback(() => {

        if (mountedRef.current) {
            setLoading(true);

            dispatch(retrieveJobs({type:"user"}))
                .unwrap()
                .then((originalPromiseResult) => {

                    if (!mountedRef.current) return null;
                    if (props.comesFrom === "selectedUser") {
                        setData(originalPromiseResult.body.data.filter((job) => {
                            return job.ownerId === selectedUserId;
                        }));
                    } else {
                        setData(originalPromiseResult.body.data.filter((job) => {
                            return job.ownerId === userId;
                        }));
                    }

                })
                .catch((rejectedValueOrSerializedError) => {
                    console.log(rejectedValueOrSerializedError);
                })
                .finally(() => {
                    if (mountedRef.current)
                        setLoading(false);
                });

            /* dispatch(retrieveJobs(params))
                 .unwrap()
                 .then((originalPromiseResult) => {
                     if (!mountedRef.current) return null;
                     setData(originalPromiseResult.body.data);
                     console.log(data);
                 })
                 .catch((rejectedValueOrSerializedError) => {
                     console.log(rejectedValueOrSerializedError);
                 })
                 .finally(() => {
                     if (mountedRef.current)
                         setLoading(false);
                 });*/
        }
    }, [dispatch, userId, props.add, props.comesFrom, selectedUserId, props.person?._id]);

    useEffect(
        () => {
            if (props.comesFrom !== "search") {
                fetchUser();
            } else {
                setLoading(false);
            }

            return () => {
                mountedRef.current = false;
            }
        }, [fetchUser, props.comesFrom]
    )


    return (
        <Container className="m-0 p-0 mt-5" fluid /* style={{ position: 'relative' }} */>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ClipLoader color={Colors.primary} loading={loading} size={50} css={{ marginTop: 20 }} />
            </div>
            {!loading && (data.length || props.data) !== 0 &&
                <div className="justify-content-center">
                    <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="my-masonry-grid2"
                        columnClassName="my-masonry-grid_column2"
                    >
                        {props.comesFrom === "search" ?
                            props.data.map((item) =>
                                <ProjectJobTile comesFrom={props.comesFrom} key={item._id} projectInfo={item} image={item.file} projectType={item.type} /* style={{width:'100%'}} */ />
                            )
                            : data.map((item) =>
                                <ProjectJobTile key={item._id} projectInfo={item} image={item.file} projectType={item.type} /* style={{width:'100%'}} */ />
                            )
                        }
                    </Masonry>
                </div>
            }
            {
                props?.private
                    ?
                    <div className="px-3" style={{ textAlign: 'right' }}>
                        {props.add &&
                            <img
                            src={addCircle}
                            style={{
                              color: Colors.primary,
                              zIndex: 102,
                              position: "fixed",
                              bottom: window.innerWidth > 800 ? 60 : 80,
                              right: window.innerWidth > 800 ? 60 : 20,
                              backgroundColor: "white",
                              borderRadius: 25,
                              boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
                            }}
                            onClick={() => history.push("/jobs/new/edit")}
                            alt="AddCircle"
                          />
                        }
                    </div>
                    :
                    <></>
            }
        </Container>
    )
}

export default ProjectJobGrid;