import Colors from "../../../constants/Colors";


const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        backgroundImage:"#FAFAFA",
        overflow: 'hidden',
        position: 'relative',
        width:'100%'

    },
    backCircle: {
        position: 'absolute',
        width: 278,
        height: 278,
        left: -76,
        top: 200,
        zIndex: 0,
        backgroundColor: Colors.secondary,
        boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
        opacity: 0.5
    },
    backCircleSmall: {
        position: 'absolute',
        width: 90,
        height: 90,
        left: "17%",
        top: 80,
        zIndex: 0,
        backgroundColor: Colors.secondary,
        boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
        opacity: 0.5
    },
    backSquare: {
        position: 'absolute',
        width: 200,
        height: 150,
        right: -90,
        top: 160,
        zIndex: 0,
        backgroundColor: Colors.secondary,
        boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
        borderRadius: 10,
        transform: 'rotate(-6.98deg)',
        opacity: 0.5
    }
}

const BackGroundShapes = ({ children, hideShapes,style }) => {
    return (
        <div style={{...styles.container,...style}} className="p-0 m-0">
            {hideShapes ||
                <>
                    <div style={styles.backCircle} className="circle__profile rounded-circle"></div>
                    <div style={styles.backCircleSmall} className="circle__profile rounded-circle"></div>
                    <div style={styles.backSquare}></div>
                </>
            }
            {children}
        </div>
    )
}

export default BackGroundShapes;