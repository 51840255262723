import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";

export const createGallery = createAsyncThunk(
  "gallery/createGallery",
  async (data , { rejectWithValue }) => {
    try {
      const response = await services.GalleryService.createGallery( data );

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveGalleries = createAsyncThunk(
  "gallery/retrieveGalleries",
  async (params, { rejectWithValue }) => {
    try {
      const response = await services.GalleryService.retrieveGalleries(params);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const retrieveGallery = createAsyncThunk(
  "gallery/retrieveGallery",
  async (galleryId, { rejectWithValue }) => {
    try {
      const response = await services.GalleryService.retrieveGallery(galleryId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGallery = createAsyncThunk(
  "gallery/deleteGallery",
  async (galleryId, { rejectWithValue }) => {
    try {
      const response = await services.GalleryService.deleteGallery(galleryId);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateGallery = createAsyncThunk(
  "gallery/updateGallery",
  async ({galleryId,info},{ rejectWithValue }) => {
    try {
      const response = await services.GalleryService.updateGallery(galleryId,info);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createGalleryPhotos = createAsyncThunk(
  "gallery/createGalleryPhotos",
  async (props , { rejectWithValue }) => {
    console.log(props)
    try {
      const response = await services.GalleryService.createGalleryPhotos(props.galleryId,props.payload);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteGalleryPhotos = createAsyncThunk(
  "gallery/deleteGalleryPhotos",
  async (galleryId,photosId , { rejectWithValue }) => {
    try {
      const response = await services.GalleryService.deleteGalleryPhotos( galleryId,photosId );
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  galleryName:"",
  photos:[],
  comesFrom:"",
  id:0,
  error: {},
};

const GallerySlice = createSlice({
  name: "gallery",
  initialState,
  reducers: {
    ACTION(state,action) {},
    SET_GALLERY_NAME(state,action){
      state.galleryName=action.payload;
    },
    SET_PHOTOS(state,action){
      const array={...action.payload,photoId:state.id++}
      state.photos.push(array);
    },
    SET_COMESFROM(state,action){
      state.comesFrom=action.payload;
    },
    DELETE_PHOTOS(state,action){
      state.photos=action.payload;
    }
  },
  extraReducers: {
    /* ---- CREATE_GALLERY ---- */
    [String(createGallery.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createGallery.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createGallery.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_GALLERIES ---- */
    [String(retrieveGalleries.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(retrieveGalleries.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(retrieveGalleries.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },

    /* ---- GET_GALLERY ---- */
    [String(retrieveGallery.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(retrieveGallery.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(retrieveGallery.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

      /* ---- UPDATE_GALLERY ---- */
    [String(updateGallery.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(updateGallery.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(updateGallery.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },

       /* ---- DELETE_GALLERY ---- */
    [String(deleteGallery.pending)]: (state, action) => {
        state.state = "loading";
      },
      [String(deleteGallery.fulfilled)]: (state, action) => {
        state.state = "success";
      },
      [String(deleteGallery.rejected)]: (state, action) => {
        state.state = "error";
        state.error = action.payload;
      },
        /* ---- CREATE_GALLERY_PHOTOS ---- */
    [String(createGalleryPhotos.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(createGalleryPhotos.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(createGalleryPhotos.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
     /* ---- DELETE_GALLERY_PHOTOS ---- */
     [String(deleteGalleryPhotos.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(deleteGalleryPhotos.fulfilled)]: (state, action) => {
      state.state = "success";
    },
    [String(deleteGalleryPhotos.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
      
  },
});

export const {ACTION,SET_GALLERY_NAME,SET_PHOTOS,SET_COMESFROM,DELETE_PHOTOS} = GallerySlice.actions;

export default GallerySlice.reducer;
