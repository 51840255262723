import UserService from "./UserService";
import CommentService from "./CommentService";
import GalleryService from "./GalleryService";
import JobService from "./JobService";
import PhotoService from "./PhotoService";
import ChatService from "./ChatService";
import BadgeService from "./BadgeService";
import EventService from "./EventService";
import ProfileService from "./ProfileService";
import AccountService from "./AccountService";
import TransactionService from "./TransactionService";
import NotificationService from "./NotificationService";



const services = {
    UserService:new UserService(),
    CommentService:new CommentService(),
    GalleryService:new GalleryService(),
    JobService:new JobService(),
    PhotoService:new PhotoService(),
    ChatService: new ChatService(),
    BadgeService: new BadgeService(),
    EventService: new EventService(),
    ProfileService: new ProfileService(),
    AccountService: new AccountService(),
    TransactionService: new TransactionService(),
    NotificationService: new NotificationService(),
};

export default services;
