import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import TitleBar from "../../components/Mobile/TitleBar"
import Colors from "../../constants/Colors"
import { useState } from "react"
import Navigation from "../../components/Navbar/Navbar"



const styles = {
    container: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        maxWidth: 500
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        fontSize: 24
    },
    text: {
        fontSize: 15,
        fontWeight: 400,
    }, mainButton: {
        color: 'white',
        borderRadius: 25,
        backgroundColor: Colors.primary,
        fontSize: 14,
        width: 165,
        height: 50,
        margin: 10,
    },
    input: {
        border: "2px solid #F2ECFF",
        fontSize: 18,
        outline: 'none',
        width: '100%',
        height: 60,
        boxShadow: '0px 3px 12px rgba(157, 118, 240, 0.1)',
        borderRadius: 10
    },
}


const ResetPassword = () => {

    const [password, setPassword] = useState("");
    const [done, setDone] = useState(false);

    const history = useHistory()
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            
                    <TitleBar />
            
            <div style={styles.title} className="mt-4 mx-4">
                {/* <IoArrowBackCircleOutline style={{ height: 30, width: 30, color: Colors.primary, marginRight: 10 }} onClick={() => history.goBack()} /> */}
                Password Recovery
            </div>
            <div className="m-0 px-4" style={styles.container}>
                {
                    !done ?
                        <>
                            <div style={styles.text} className="my-4">
                                Please enter your new password in the fields below.
                                Password must contain at least 8 characters, one letter, one number and a special character.
                            </div>
                            <input type="password" placeholder="Your new password" value={password} onChange={event => setPassword(event.target.value)} style={styles.input} className="p-3 mt-1" />

                            <Button as="input" type="button" className="mt-4" value="Reset password" style={styles.mainButton} onClick={() => setDone(true)} />
                        </>
                        :
                        <>
                            <div style={styles.text} className="my-4">
                                Your password has been reset.Please go back to login to access your account.
                            </div>

                            <Button as="input" type="button" className="mt-5" value="Back to Login" style={styles.mainButton} onClick={() => history.replace("/login")} />
                        </>
                }
            </div>
        </div>
    )
}

export default ResetPassword;