import { useCallback, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Colors from "../../../constants/Colors";
import { retrieveUser } from "../../../store/slices/UserSlice";
import noPictureUser from "../../../assets/user.svg";
import { AiFillLike } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import {
  createPhotoLike,
  deletePhotoLike,
} from "../../../store/slices/PhotoSlice";
import {
  NotificationBody,
  sendNotification,
} from "../../../helpers/NotificationUtils";
import like from "../../../assets/like1.png";
import likeFull from "../../../assets/like1full.png";
import UserPhotoInfo from "../Photos/UserPhotoInfo";
import { getPhotoSrc } from "../../../helpers/photo";

const styles = {
  card: {
    borderRadius: "5px",
    overflow: "hidden",
    border: "none",
    position: "relative",
    cursor: "pointer",
    marginLeft: -10,
    boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
  },
  like: {
    position: "absolute",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: 12,
    minWidth: 55,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
  },
  imageContainer: {
    position: "absolute",
    bottom: 15,
    left: 15,
  },
};
const ExplorePhotoCard = (props) => {
  const [profile, setProfile] = useState("");
  const [user, setUser] = useState({});
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [hover, setHover] = useState(false);
  const [myLikeId, setMyLikeId] = useState("");
  const mountedRef = useRef(true);

  const currentUserId = useSelector((state) => state.user.userId);

  const { data } = props;

  const dispatch = useDispatch();

  const fetchUser = useCallback(() => {
    dispatch(retrieveUser(data.ownerId?._id))
      .unwrap()
      .then((originalPromiseResult) => {
        if (!mountedRef.current) return null;

        setUser(originalPromiseResult.body.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, [dispatch, data.ownerId]);

  useEffect(() => {
    fetchUser();
    return () => {
      mountedRef.current = false;
    };
  }, [fetchUser]);

  useEffect(() => {
    const item = data.likes.find(
      (like) => like.ownerId === currentUserId
    );
    setLiked(item ? true : false);
    setLikes(data.likes.length);
    setMyLikeId(item?._id);
  }, [data, currentUserId]);

  const history = useHistory();

  const likePhoto = () => {
    if (!liked) {
      let req = {
        photoId: data._id,
        payload: {
          ownerId: currentUserId,
          posted: new Date(),
        },
      };
      dispatch(createPhotoLike(req))
        .unwrap()
        .then((res) => {
          setMyLikeId(
            res.body.data.likes.filter(
              (like) => like.ownerId === currentUserId
            )?.[0]?._id
          );
          setLikes(likes + 1);
          setLiked(true);
          sendNotification({
            title: "Like",
            body: NotificationBody.LIKE,
            click_action: "/lightbox?photoId=" + res.body.data._id,
            type: "photo",
            status: "new",
          });
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    } else {
      let req = {
        photoId: data._id,
        likeId: myLikeId,
      };
      dispatch(deletePhotoLike(req))
        .unwrap()
        .then(() => {
          setLikes(likes - 1);
          setLiked(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.log(rejectedValueOrSerializedError);
        });
    }
  };
  return (
    <div className="w-100 mb-0 mt-0">
      <UserPhotoInfo styles="mt-2" user={user} title={data.title} date={data.createdAt}/>
      <div className="" style={{ position: "relative" }}>
        <div
          style={{
            ...styles.like,
            zIndex: 1,
          }}
          className="py-1 px-2 mb-3"
          onClick={likePhoto}
        >
          <img className="ms-0 mr-1 mx-2" src={liked ? likeFull : like} />
          {likes}
        </div>
        <div className="w-100">
          <img
            style={{ width: "100%" }}
            src={getPhotoSrc(data)}
            alt={props.data.name}
            onClick={() => history.push("/lightbox?photoId=" + data._id)}
          />
        </div>
      </div>
    </div>
  );
};

export default ExplorePhotoCard;
