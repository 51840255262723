import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import BackGroundShapes from "../../components/Mobile/Login-Register/BackGround";
import Colors from "../../constants/Colors";
import { SET_USER_ID } from "../../store/slices/UserSlice";
import { loginUser } from "../../store/slices/AuthSlice";
import Logo from "../../assets/Logo.png";
import { FaRegEye } from "react-icons/fa";
import logoIcon from "../../assets/Logo 2.png";
import textLogo from "../../assets/TalentsPod.png";
import emailIcon from "../../assets/Mail IC.png";
import passwordIcon from "../../assets/password.png";
import english from "../../assets/united-kingdom.svg";
import italian from "../../assets/italy.svg";
import greek from "../../assets/greece.svg";
import {changeLanguage} from "../../helpers/locale";
import { useTranslation } from "react-i18next";

const jwt = require("jsonwebtoken");

let styles = {
  container: {
    width: "100%",
    maxWidth: 750,
    background:
      "linear-gradient(180deg, #E8E9F3 0%, rgba(232, 233, 243, 0) 64.83%)",
  },
  form: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "90%",
    backgroundColor: "white",
    margin:"auto",
    zIndex: 1,
    position: "relative",
    border: "1px solid #E8E9F3",
    boxSizing: "border-box",
    borderRadius: "2px"
  },
  labels: {
    fontSize: 14,
    fontWeight: 300,
  },
  input: {
    background: "#FAFAFA",
    border: "1px solid #E8E9F3",
    boxSizing: " border-box",
    borderRadius: "3px",
    opacity: 0.4,
    width: "95%",
    margin:"auto"
  },
  forgotPass: {
    textAlign: "right",
    width: 150,
    fontSize:11,
    textDecoration: "none",
    color: Colors.primary,
    alignSelf: "flex-end",
    /* position: 'absolute',
        bottom: 10,
        right: 20 */
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "white",
  },
  mainButton: {
    color: "white",
    fontSize: 14,
    minWidth: 270,
    background: "#6F79D8",
    borderRadius: "100px",
    minHeight: 50,
    margin: 10,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
  activeLang:{
    border:"1px solid black"
  }
};

const LoginPage = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();
  const [active,setActiveLang] = useState('en');
  const { t } = useTranslation();

  const _handleChange = (event) => {
    switch (event.target.id) {
      case "email":
        setEmail(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;

      default:
        break;
    }
  };

  const dispatch = useDispatch();
  const _login = useCallback(() => {
    let data = {
      email: email,
      password: password,
      username: "someone",
      pin: "12345",
    };
    dispatch(loginUser(data))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult.body.data);
        setInvalid(false);
        dispatch(
          SET_USER_ID(
            jwt.decode(
              originalPromiseResult.body.data.token,
              "weriu34sdmkd326842lfjewrmekoifrqwei"
            ).user.id
          )
        );
        history.push("/");
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setInvalid(true);
      });
  }, [email, password, dispatch, history]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        _login();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [_login]);

  const changeLanguageAction = (e) =>{
    console.log(e)
    changeLanguage(e.target.id);
    setActiveLang(e.target.id);
  }

  return (
    <div className="" style={{ ...styles.container, zIndex: 1 }}>
      <div className="d-flex justify-content-end">
        <div className="mt-1 me-2">
          <img onClick={changeLanguageAction} style={{width:30,height:30}} src={english} id="en"/>
        </div>
        <div className="mt-1 me-2" >
          <img onClick={changeLanguageAction} id="it" style={{width:30,height:30}} src={italian}/>
        </div>
        <div className=" mt-1 me-2">
          <img onClick={changeLanguageAction} id="gr" style={{width:30,height:30}} src={greek}/>
        </div>
      </div>
      <div className="text-center mt-5 pt-5">
        <img className="" src={logoIcon} alt="icon" />
      </div>
      <div className="text-center mt-4">
        <img className="" src={textLogo} alt="textIcon" />
      </div>

      <div style={styles.form} className="mt-5 p-3">
        {invalid ? (
          <div style={{ ...styles.labels, color: "red", marginBottom: 6 }}>
            Invalid username or password
          </div>
        ) : (
          <></>
        )}
        <div style={styles.labels} className="d-flex ms-2">
          <img className="align-self-center me-2" src={emailIcon} alt="emailIcon"/>
          <div>
              Email
          </div>
        </div>
        <input
          type="email"
          placeholder="username@provider.com"
          style={styles.input}
          id="email"
          className="p-2 ps-3 mb-3 mt-2"
          onKeyUp={_handleChange}
        />
        <div style={styles.labels} className="d-flex ms-2">
          <img className="align-self-center me-2" src={passwordIcon} alt="passwordIcon"/>
          <div>
              {t("Login.password")}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            position: "relative",
          }}
        >
          <input
            type={showPass ? "text" : "password"}
            id="password"
            placeholder="******"
            style={styles.input}
            className="p-2 ps-3 mb-3 mt-2"
            onKeyUp={_handleChange}
          />
          <Button
            className="p-0"
            style={{
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              boxShadow: "none",
              position: "absolute",
              right: 20,
              top:16
            }}
            onClick={() => setShowPass(!showPass)}
          >
            <FaRegEye
              style={{ height: 17, width: 17, color: Colors.primary }}
            />
          </Button>
        </div>

        <Link style={styles.forgotPass} className="pt-0 pe-2" to="/forgot-password">
        {t("Login.forgot")}
        </Link>
      </div>

      <div className="my-5" style={styles.buttonContainer}>
        <Button
          as="input"
          className="p-2"
          type="button"
          value={t("Login.login")}
          style={styles.mainButton}
          onClick={_login}
        />
            <p className="mb-1 mt-1" style={{   width: "50%",textAlign:" center", borderBottom: "1px solid #E8E9F3",lineHeight: "0.1em"}}><span style={{backgroundColor:"#ffffff",fontSize:12}} className="ps-3 pe-3 ">{t("Login.or")}</span></p>
        <Button
          as="input"
          className="p-2"
          type="button"
          value={t("Login.register")}
          style={{...styles.mainButton,backgroundColor:"#E8E9F3",border:"none",color:"#6F79D8"}}
          onClick={() => {
            window.location.href = "/register";
          }}
        />
      </div>
    </div>
  );
};

export default LoginPage;
