import '../Photos/gridStyle.scss'
import Masonry from 'react-masonry-css'
/* import Pic1 from '../../../assets/ExampleProjectPics/pic1.jpg'
import Pic2 from '../../../assets/ExampleProjectPics/pic2.jpg'
import Pic3 from '../../../assets/ExampleProjectPics/pic3.jpg'
import Pic4 from '../../../assets/ExampleProjectPics/pic4.jpg'
import Pic5 from '../../../assets/ExampleProjectPics/pic5.jpg' */

import UserSearchCard from "./UserSearchCard"



const breakpointColumnsObj = {
    default: 3,
    1024:2,
    700: 1,
    500: 1
};


/* const example_data = [{
    id: 1,
    title: "Black and white photoshoot with light effects",
    type: "Job",
    city: "Milano",
    region: "Lombardia",
    date: "22/06/2021",
    image: Pic1
}, {
    id: 2,
    title: "Building and shooting for cultural festival",
    type: "Project",
    city: "Monza",
    region: "Lombardia",
    date: "10/05/2021",
    image: Pic2
}, {
    id: 3,
    title: "Natural-light shooting for personal portofolio",
    type: "Job",
    city: "Pavia",
    region: "Lombardia",
    date: "20/06/2021",
    image: Pic2
}, {
    id: 4,
    title: "Team for shooting abroad Copenhagen",
    type: "Project",
    city: "Milano",
    region: "Lombardia",
    date: "12/06/2021",
    image: Pic3
}, {
    id: 5,
    title: "Shooting for personal fashion brand",
    type: "Job",
    city: "Legnano",
    region: "Lombardia",
    date: "09/06/2021",
    image: Pic4
}, {
    id: 6,
    title: "Looking for model with face tattoos for shooting",
    type: "Job",
    city: "Legnano",
    region: "Lombardia",
    date: "09/06/2021",
    image: Pic5
}
] */

const UserSearchGrid = props => {

    const { data } = props;

    return (

        <div className="justify-content-center mt-4">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid2"
                columnClassName="my-masonry-grid_column2"
            >
                {
                    data.map((item, index) =>
                        <UserSearchCard key={index} userInfo={item} /* style={{maxWidth:400}} *//>
                    )
                }
            </Masonry>
        </div>

    )
}

export default UserSearchGrid;