/* eslint-disable no-underscore-dangle */

/* TBD: use .env file to set the env variables */

// API domain
const {
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH
} = window._env_;

console.log(
  "ENV:",
  REACT_APP_API_PROTOCOL,
  REACT_APP_API_HOST,
  REACT_APP_API_PORT,
  REACT_APP_API_BASEPATH
);

// API domain
const api = {
  protocol: REACT_APP_API_PROTOCOL,
  host: REACT_APP_API_HOST,
  port: REACT_APP_API_PORT,
  basePath: REACT_APP_API_BASEPATH,
};


export { api };

  