import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import services from "../../services";
import { setAuthToken } from "../../helpers/auth";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await services.UserService.loginUser(data);

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

let initialState = {
  state: "",
  userId: "",
  error: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    ACTION(state, action) { },
    SET_USER_ID(state, action) {
      state.userId = action.payload;
    },
  },
  extraReducers: {
    /* ---- LOGIN_USER ---- */
    [String(loginUser.pending)]: (state, action) => {
      state.state = "loading";
    },
    [String(loginUser.fulfilled)]: (state, action) => {
      setAuthToken(`Bearer ${action.payload.body.data.token}`);
      state.state = "success";
    },
    [String(loginUser.rejected)]: (state, action) => {
      state.state = "error";
      state.error = action.payload;
    },
  },
});

export const { ACTION, SET_USER_ID } = authSlice.actions;

export default authSlice.reducer;
